import {
    linkAccountWithMono,
    linkAccountWithOkraV2,
} from "../../util/operations/account";
import { toastDefaults } from "../../util/constants";
// import Okra from "okra-js";

export const handleConnectWithMono = async (
    code,
    toast,
    setOnboardingStep,
    //router
    setShowBackup
) => {
    setOnboardingStep("LOADING");
    const { status, message } = await linkAccountWithMono(code);

    toast({
        ...toastDefaults,
        status: status ? "success" : "error",
        desciption: message,
        title: status ? "Success" : "Error",
    });

    if (status) {
        return setOnboardingStep(6);
        // return router.replace("/dashboard");
    }
    if (!status) {
        return setShowBackup(true);
        // return setDrawer({
        //     isOpen: true,
        //     modalId: "ACCOUNT_LINKING_ERROR",
        //     data: { okraConnect },
        // });
    }

    return setOnboardingStep(3);
};

export const handleConnectWithOkra = async (
    okraData,
    toast,
    actionType,
    actions
) => {
    switch (actionType) {
        case "SELECT_BANK":
            actions?.setBankIsLoading(true);
            break;
        default:
            actions?.setOnboardingStep("LOADING");
    }
    const { status, message, data } = await linkAccountWithOkraV2(okraData);

    toast({
        ...toastDefaults,
        status: status ? "success" : "error",
        desciption: message,
        title: status ? "Success" : "Error",
    });

    if (status) {
        (await actionType) === "SELECT_BANK"
            ? actions?.addAccountToState(data.data)
            : actions?.setOnboardingStep(6);
    }
    actionType === "SELECT_BANK" && (await actions?.setBankIsLoading(false));
};
