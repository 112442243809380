import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Box, HStack } from "@chakra-ui/layout";
import { Text, Image } from "@chakra-ui/react";
import SavingsActiveIcon from "../../assets/svgs/yello-savings-icon.svg";
import SavingsIcon from "../../assets/svgs/savings-icon.svg";
import SettingsActiveIcon from "../../assets/svgs/settings-active-icon.svg";
import SettingsIcon from "../../assets/svgs/settings-icon.svg";

import DashboardMiddleBtn from "../../assets/svgs/modal-plus-button.svg";
import { useHistory } from "react-router-dom";

//deleted drawer props
const DashboardBottomNav = ({ setDrawer }) => {
  const [activePath, setActivePath] = useState("home");
  const history = useHistory();

  const handleMenuClick = (menuItem) => {
    setActivePath(menuItem);
    if (menuItem === "home") {
      return history.push("/dashboard");
    }
    return history.push("/settings");
  };

  useEffect(() => {
    if (history?.location.pathname === "/settings") {
      return setActivePath("settings");
    } else {
      return setActivePath("home");
    }
  }, [history?.location.pathname]);

  useEffect(() => {
    return function cleanup() {
      setDrawer({
        isOpen: false,
      });
    }; // eslint-disable-next-line 
  }, []);

  return (
    <>
      <Box
        py="0.5rem"
        bg="brand.primary"
        borderTopRightRadius="25"
        borderTopLeftRadius="25"
        position="fixed"
        bottom="0"
        w={["100%", "600px", "500px"]}
        display={{ base: "block", md: "none", lg: "none" }}
      >
        <HStack>
          <Box
            cursor="pointer"
            onClick={() => handleMenuClick("home")}
            _active={{
              transform: "translateY(.2rem)",
            }}
            textAlign="center"
            flex="1"
          >
            <Image
              m="auto"
              display="block"
              src={activePath === "home" ? SavingsActiveIcon : SavingsIcon}
            />
            <Text
              fontSize="0.8rem"
              color={activePath === "home" ? "brand.yellow" : "brand.white"}
            >
              {" "}
              Saving
            </Text>
          </Box>
          <Box cursor="pointer" textAlign="center" flex="1">
            <Image
              transform="scale(0.8)"
              onClick={() =>
                setDrawer({
                  isOpen: true,
                  modalId: "APP_MENU",
                  isDark: true,
                  showBitMoji: false,
                })
              }
              _active={{
                transform: "translateY(.2rem)",
              }}
              m="auto"
              display="block"
              src={DashboardMiddleBtn}
            />
          </Box>
          <Box
            cursor="pointer"
            onClick={() => handleMenuClick("settings")}
            _active={{
              transform: "translateY(.2rem)",
            }}
            textAlign="center"
            flex="1"
          >
            <Image
              m="auto"
              display="block"
              src={
                activePath === "settings" ? SettingsActiveIcon : SettingsIcon
              }
            />
            <Text
              fontSize="0.8rem"
              color={activePath === "settings" ? "brand.yellow" : "brand.white"}
            >
              {" "}
              Settings
            </Text>
          </Box>
        </HStack>
      </Box>
    </>
  );
};

DashboardBottomNav.propTypes = {
  setDrawer: PropTypes.func
}

export default DashboardBottomNav;
