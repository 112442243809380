import {
    formatMoney,
    unFormatMoney,
    renderNaira,
    padWithdots,
} from "../../util/helper-functions";
import {
    initiateWithdraw,
    verifyWithdraw,
} from "../../util/operations/withdraw";

import { toastDefaults } from "../../util/constants";

export const handleWithdraw = async ({
    formData,
    setIsLoading,
    setDrawer,
    router,
    toast,
    flexBalance,
}) => {
    toast.closeAll();
    setIsLoading(true);

    const amount = Number(unFormatMoney(formData.amount));

    if (amount < 100) {
        setIsLoading(false);
        return toast({
            ...toastDefaults,
            status: "error",
            description: "You can't withdraw less than ₦100",
            title: "Error",
        });
    }

    const selectedAccount = JSON.parse(
        sessionStorage.getItem("selectedAccount")
    );

    if (amount > flexBalance / 100) {
        setIsLoading(false);
        return toast({
            ...toastDefaults,
            status: "error",
            description:
                "Your withdrawal request amount is more than you Cash Reserve Balance",
            title: "Error",
        });
    }

    const { accountId, bankName, accountName, accountNumber } = selectedAccount;

    const { status, data } = await initiateWithdraw({
        amount,
        accountId,
    });

    if (status) {
        await verifyWithdraw({
            reference: data?.reference,
        });

        // if (verification.status) {
        router.push("/dashboard");

        setIsLoading(false);
        return setDrawer({
            isOpen: true,
            modalId: "SUCCESS_COMPONENT",
            isDark: false,
            showBitMoji: false,
            data: {
                type: "WITHDRAW",
                name: accountName,
                bankName,
                accountNumber,
                amount: formatMoney(renderNaira(amount * 100)),
            },
        });
        // }
    }

    setIsLoading(false);

    return setDrawer({
        isOpen: true,
        modalId: "ERROR_500",
        isDark: false,
        showBitMoji: false,
    });
};

export const formatAccounts = (accounts) => {
    return accounts.map((account) => {
        return {
            accountId: account.account_id,
            bankName: `${account.bank_name} ${" "} (${padWithdots(
                account.account_number
            )})`,
        };
    });
};
