/* eslint-disable no-useless-escape */
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";

// var SI_SYMBOL = ["", "k", "M", "B", "T"];

// function abbreviateMoney(amount) {
//     // what tier? (determines SI symbol)
//     var tier = (Math.log10(Math.abs(amount)) / 3) | 0;

//     // if zero, we don't need a suffix
//     if (tier == 0) return amount;

//     // get suffix and determine scale
//     var suffix = SI_SYMBOL[tier];
//     var scale = Math.pow(10, tier * 3);

//     // scale the number
//     var scaled = amount / scale;

//     // format number and add suffix
//     return scaled.toFixed(1) + suffix;
// }

// Function to format monetary values with commas
export function formatMoney(x) {
    if (x) {
        // if (x > 99999 && !dontAbbrv) {
        //     return abbreviateMoney(x);
        // }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return 0;
}

export function renderNaira(x) {
    if (x) {
        const y = x / 100;
        return y.toFixed(2);
    }
    return 0;
}

export function unFormatMoney(x) {
    if (x) {
        return x.replace(/\,/g, "");
    }
    return 0;
}

//  Returns days left for mandate to complete
export const renderMandateTimeLeft = (maturitydate) => {
    if (!maturitydate) return;
    const timeLeft = formatDistanceToNowStrict(new Date(maturitydate), {
        unit: "day",
    });

    if (timeLeft.split(" ").includes("0")) {
        return "Cycle complete";
    }
    return `${timeLeft} left`;
};

export const padWithdots = (str) => {
    if (!str) return "";
    return `**${str.slice(-4)}`;
};

export const getSavingsType = (type) => {
    switch (type) {
        case "says":
            return "Spend";
        case "saye":
            return "Earn";
        case "sayes":
            return "Earn & Spend";
        case "employee-perk":
            return "Salary";
        default:
            return " ";
    }
};

export const getMandateTransactionType = (type) => {
    switch (type) {
        case "says":
            return "Debit";
        case "saye":
            return "Credit";
        default:
            return " ";
    }
};

export const getUserSaveLimit = (userType) => {
    const userDescription =
        userType || JSON.parse(sessionStorage.getItem("userDescription"));
    if (userDescription?.includes("business owner")) {
        return 60;
    } else if (userDescription?.includes("working class")) {
        return 40;
    }
    return 20;
};

function convertTo12Hrs(time) {
    const [sHours, minutes] = time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? "AM" : "PM";
    const hours = +sHours % 12 || 12;

    return `${hours}:${minutes} ${period}`;
}

export const renderMandateHistoryDateTime = (dateTime) => {
    // 11:45 PM, 24-11-2019
    const dateLocalString = dateTime.toLocaleString();
    const dayMonthYear = dateLocalString.split(",")[0];

    return `${convertTo12Hrs(dateLocalString.split(",")[1])}, ${dayMonthYear}`;
};

export const renderStashHistoryDate = (dateTime) => {
    // 24-11-2019
    return dateTime.toLocaleString().split(",")[0];
};

export const checkUserVerified = (user) => {
    const { oldUser, emailVerified } = user || {};
    return (oldUser && !emailVerified) || emailVerified;
};

export const getAuth0ErrorMessage = (error) => {
    let message;
    if (error.code === "invalid_password") {
        message = `Your password must be: ${error.policy}`;
    } else if (typeof error.message === "string") {
        message = error.message;
    } else if (typeof error.description === "string") {
        message = error.description;
    } else if (typeof error.original === "string") {
        message = error.original;
    } else if (error.original && typeof error.original.message === "string") {
        message = error.original.message;
    } else {
        message = error.code; // Use error.code if no better option
    }

    return message;
};

export function convertDollarToNaira(x, exchangeRate) {
    if (x && exchangeRate) {
        return Math.floor(x * exchangeRate);
    }
    return 0;
}
