import { toastDefaults } from "../../util/constants";
import { setPassCode } from "../../util/operations/user";

export const handleSetPassccode = async (
  passCode,
  setOnboardingStep,
  toast
) => {
  setOnboardingStep("Loading");
  const { status, message } = await setPassCode(passCode);

  toast({
    ...toastDefaults,
    status: status ? "success" : "error",
    desciption: message,
    title: status ? "Success" : "Error",
  });

  if (status) {
    return setOnboardingStep(2);
  }
  return setOnboardingStep(1);
};
