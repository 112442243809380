import React, { lazy, Suspense, useState } from "react";
import { Switch, Route } from "../../util/router";
import PageNavigator from "../../components/PageNavigator";
import { requireAuth, useAuth } from "../../util/auth";
import PageLoader from "../../components/PageLoader";
import VerifyStashCreation from "../../stash-pages/verify";
import TopNavigation from "../../components/TopNavigation";
import { Box } from "@chakra-ui/layout";
import PropTypes from "prop-types";
const AddStash = lazy(() => import("../../stash-pages/add-stash"));
const StashHistory = lazy(() => import("../../stash-pages/stash-history"));
const NotFoundPage = lazy(() => import("../not-found"));

const StashPage = ({ setDrawer }) => {
    const [pageHeader, setPageHeader] = useState({
        title: "",
        isEditable: false,
    });
    const { user } = useAuth();

    return (
        <Box
            overflowY="scroll"
            h={{ md: "full" }}
            w={["100%", "600px", "500px"]}
            paddingTop={{ md: "0" }}
        >
            <TopNavigation
                pageHeader={pageHeader}
                hasPageNavigator
                userName={user?.name}
            />

            <PageNavigator pageHeader={pageHeader} support>
                <Suspense fallback={<PageLoader />}>
                    <Switch>
                        <Route
                            exact
                            path={"/stash/add"}
                            render={(routeProps) => (
                                <AddStash
                                    setDrawer={setDrawer}
                                    setPageHeader={setPageHeader}
                                    {...routeProps}
                                />
                            )}
                        />

                        <Route
                            exact
                            path={"/stash/verify"} 
                            render={(routeProps) => (
                                <VerifyStashCreation
                                    setDrawer={setDrawer}
                                    setPageHeader={setPageHeader}
                                    {...routeProps}
                                />
                            )}
                        />

                        <Route
                            path={"/stash"}
                            exact
                            render={(routeProps) => (
                                <StashHistory
                                    setPageHeader={setPageHeader}
                                    {...routeProps}
                                />
                            )}
                        />

                        <Route
                            path="*"
                            render={(rProps) => (
                                <NotFoundPage
                                    {...rProps}
                                    setDrawer={setDrawer}
                                />
                            )}
                        />
                    </Switch>
                </Suspense>
            </PageNavigator>
        </Box>
    );
};

StashPage.propTypes = {
    setDrawer: PropTypes.func,
};

export default requireAuth(StashPage);
