import { apiRequest } from "../apiRequest";

export const createMandate = (mandateData) =>
    apiRequest("/mandates/create", "POST", mandateData);

export const editMandate = (mandateData) => {
    return apiRequest(
        `/mandates/edit/${mandateData?.mandateId}`,
        "PATCH",
        mandateData
    );
};

export const fetchUserMandates = () => {
    return apiRequest("/mandates/fetch?sortBy=createdAt&sortType=desc", "GET");
};

export const breakFlow = (mandateData) => {
    return apiRequest(
        `/mandates/break/${mandateData?.mandateId}`,
        "PATCH",
        mandateData
    );
};

export const fetchMandateDetails = (mandateId) => {
    return apiRequest(`/mandates/fetch/${mandateId}`, "GET");
};

export const getSingleMandateReserveHistory = (mandateId) =>
    apiRequest(
        `/accounting/user/mandate-reserve/history/${mandateId}?sortBy=createdAt&sortType=desc`,
        "GET"
    );

export const deleteMandate = (mandateData) => {
    return apiRequest(
        `/mandates/delete/${mandateData?.mandateId}`,
        "DELETE",
        mandateData
    );
};

export const toggleMandate = (mandateData) =>
    apiRequest(
        `/mandates/toggle/${mandateData?.mandateId}`,
        "PATCH",
        mandateData
    );

export const toggleExemption = (mandateData) =>
    apiRequest(
        `/mandates/exemption/toggle/${mandateData?.mandateId}`,
        "POST",
        mandateData
    );

// TODO -  Swap with toggle exemption and delete
export const addExemption = (exemptionData) => {
    return apiRequest(`/mandate/exempt-transaction`, "POST", exemptionData);
};

// TODO -  Swap with toggle exemption and delete
export const removeExemption = (exemptionData) => {
    return apiRequest(`/mandate/remove-exemption`, "POST", exemptionData);
};

export const celebrateMandate = (mandateData) => {
    return apiRequest(
        `/mandates/mature/${mandateData?.mandateId}`,
        "PATCH",
        mandateData
    );
};

export const initiateMandateActivation = (data) => {
    return apiRequest(`/mandates/activate`, "POST", data);
};

export const verifyMandateActivation = (data) => {
    return apiRequest(`/payment/verify/${data?.ref}`, "POST", data);
};

export const withdraw = (mandateData) => apiRequest(`/mandates/withdraw/${mandateData?.mandateId}`, 'POST', {
    amount: mandateData?.amount
});
