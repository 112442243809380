import { initiateReauth } from "../../../util/operations/account";
import { markNotificationAsRead } from "../../../util/operations/notifications";

export const handleAnnouncementClick = async (
  announcement,
  monoConnect,
  okraConnect,
  readUserAnnouncement,
  announcements
) => {
  const { data, _id } = announcement;

  if (data?.mono_id) {
    const response = await initiateReauth(data.mono_id);
    if (response.status) {
      await monoConnect.reauthorise(response?.data?.token);
    }
  }

  const { status } = await markNotificationAsRead(_id);

  if (status) {
    await readUserAnnouncement(_id, announcements);
  }
  return;
};
