/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect, useContext, createContext } from "react";
import { apiRequest } from "./apiRequest";
// import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import PageLoader from "../components/PageLoader";
import { TEST_AUTH_CONTENT } from "./constants";
import PropTypes from "prop-types";
import AUTH0 from "./auth0";
import { getAuth0ErrorMessage, checkUserVerified } from "./helper-functions";
import {
    resendVerificationEmail,
    initiateResetPassword,
    finalizeResetPassword,
} from "./operations/user";

const authContext = createContext();

// Context Provider component that wraps your app and makes auth object
// available to any child component that calls the useAuth() hook.
export function ProvideAuth({ children, test }) {
    const auth = useProvideAuth();

    return (
        <authContext.Provider value={test ? TEST_AUTH_CONTENT : auth}>
            {children}
        </authContext.Provider>
    );
}

ProvideAuth.propTypes = {
    test: PropTypes.bool,
    children: PropTypes.element,
};

// Hook that enables any component to subscribe to auth state
export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [user, setUser] = useState(null);
    // const toast = useToast();

    const updateUserStat = (value, key) => {
        return setUser({
            ...user,
            basicStats: {
                ...user.basicStats,
                [key]: Number(user.basicStats[key] + value),
                totalStashed: Number(user.basicStats.totalStashed + value),
            },
        });
    };
    const removeUncelebratedMandateFromState = (mandateId) => {
        return setUser({
            ...user,
            uncelebratedMandates: user.uncelebratedMandates.filter(
                (mandate) => mandate.mandateId !== mandateId
            ),
        });
    };

    const updateUserPersona = (value) => {
        return setUser({
            ...user,
            personas: value,
        });
    };

    // Combine this function with the one above it later
    const updateUserSavingLimit = (value) => {
        return setUser({
            ...user,
            saving_limit: value,
        });
    };

    const updateUserData = (value, key) => {
        return setUser({
            ...user,
            [key]: value,
        });
    };

    const handleAuthStateChange = async () => {
        const response = await apiRequest(
            "/auth/profile/fetch",
            "GET",
            {},
            {},
            true
        );

        if (response && response.status) {
            return setUser(response.data);
        }

        return setUser(false);
    };

    const register = async (registerData) => {
        const response = await apiRequest(
            "/auth/sign-up",
            "POST",
            {
                ...registerData,
            },
            {},
            true
        );
        if (response.status) {
            const loginResponse = await AUTH0.extended.login({
                ...registerData,
                username: registerData.email,
            });
            const { accessToken } = loginResponse;
            await resendVerificationEmail(accessToken);
        }

        return response;
    };

    const login = async (loginData) => {
        try {
            const response = await apiRequest(
                "/auth/login",
                "POST",
                {
                    email: loginData.email,
                    password: loginData.password,
                },
                {},
                true
            );
            const { token } = response.data;
            sessionStorage.setItem("token", token);
            handleAuthStateChange();

            return {
                status: true,
                message: "Welcome back",
            };
        } catch (error) {
            const message = getAuth0ErrorMessage(error);
            return {
                status: false,
                message,
            };
        }
    };

    const googleAuth = async (userData, authType) => {
        const { googleId, email } = userData;
        if (authType === "register") {
            const registerResponse = await register({
                ...userData,
                password: `${email}${googleId}`,
            });
            if (!registerResponse.status) {
                return registerResponse;
            }
        }

        const loginResponse_1 = await login({
            ...userData,
            password: `${email}${googleId}`,
        });

        if (loginResponse_1?.status) {
            return loginResponse_1;
        }
        const loginResponse_2 = await login({
            ...userData,
            password: `${googleId}`,
        });

        return loginResponse_2;
    };

    const requestReset = async ({ email }) => {
        try {
            await initiateResetPassword({
                email,
            });
            return {
                status: true,
                message: "We've just sent you an email to reset your password.",
            };
        } catch (error) {
            return {
                status: false,
                message: error.message,
            };
        }
    };

    const finalizeEmailReset = async ({ email }) => {
        try {
            await finalizeResetPassword({
                email,
            });
            return {
                status: true,
                message: "We've just sent you an email to reset your password.",
            };
        } catch (error) {
            return {
                status: false,
                message: error.message,
            };
        }
    };

    const logout = async () => {
        sessionStorage.removeItem("token");

        return window.location.reload();
    };

    const verifyReferralCode = async (code) => {
        const response = await apiRequest(
            `/referral/referrer?referralCode=${code}`
        );
        if (response && response.status) {
            return {
                status: "verified",
            };
        } else {
            return {
                status: response.message,
            };
        }
    };

    useEffect(() => {
        handleAuthStateChange();
    }, []);

    return {
        user,
        register,
        login,
        logout,
        googleAuth,
        updateUserStat,
        updateUserPersona,
        updateUserSavingLimit,
        requestReset,
        finalizeEmailReset,
        updateUserData,
        verifyReferralCode,
        removeUncelebratedMandateFromState,
    };
}

// A Higher Order Component for requiring authentication

export const requireAuth = (Component) => {
    return (props) => {
        // Get authenticated user
        const auth = useAuth();
        const history = useHistory();

        useEffect(() => {
            // Redirect if not signed in
            if (auth.user === false) {
                history.replace("/");
            }
            const userVerified = checkUserVerified(auth.user);
            if (auth?.user && !userVerified) {
                history.push("/verify-email");
            }
            // eslint-disable-next-line
        }, [auth]);

        // Show loading indicator
        // We're either loading (user is null) or we're about to redirect (user is false)
        if (!auth?.user && process.env.NODE_ENV !== "test") {
            return <PageLoader />;
        }

        // Render component now that we have user
        return <Component {...props} />;
    };
};
