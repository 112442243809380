import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    HStack,
    Image,
    Center,
    Button,
    Text,
    NumberInput,
    NumberInputField,
    useToast,
    Spinner,
} from "@chakra-ui/react";
import { Context as StatsContext } from "../../contexts/statisticsContext";

import { useForm } from "react-hook-form";
import MoneyWithWings from "../../assets/svgs/dollar-bills-with-wings.svg";
import BankEmoji from "../../assets/svgs/bank-building.svg";
import { Context as AccountsContext } from "../../contexts/accountsContext";
import { handleWithdraw } from "./helper";
import { useHistory } from "react-router-dom";
import PageNavigator from "../../components/PageNavigator";
import { requireAuth, useAuth } from "../../util/auth";
import { formatMoney } from "../../util/helper-functions";
import linkAccountWithProviders from "../../components/LinkAcountWithProviders";
import TopNavigation from "../../components/TopNavigation";
import PropTypes from "prop-types";

const Withdraw = ({ setDrawer, monoConnect }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [addBankIsLoading] = useState(false);
    const [selectedBankName, setSelectedbankName] = useState();

    const {
        user: { ...user },
    } = useAuth();

    const router = useHistory();

    const selectedAccount = sessionStorage.getItem("selectedAccount");

    const toast = useToast();
    const {
        state: { accounts },
        getUserAccounts,
    } = useContext(AccountsContext);
    const {
        state: { userReserve },

        fetchUserReserve,
    } = useContext(StatsContext);

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = useForm();

    useEffect(() => {
        if (selectedAccount) {
            const account = JSON.parse(selectedAccount);

            setSelectedbankName(account.bankName);
        }
    }, [selectedAccount]);

    const amountValue = watch("amount");

    useEffect(() => {
        getUserAccounts(accounts, toast);
        fetchUserReserve(userReserve);
        // eslint-disable-next-line
    }, []);

    const cashReserveBalance = userReserve?.cashReserve?.balance;

    const submit = async (formData) => {
        return handleWithdraw({
            formData,
            accounts,
            setIsLoading,
            setDrawer,
            router,
            toast,
            flexBalance: cashReserveBalance,
        });
    };

    return (
        <>
            <Box
                overflowY="scroll"
                w={["100%", "600px", "500px"]}
                h={{ md: "full" }}
                paddingTop={{ md: "0" }}
            >
                <TopNavigation
                    pageHeader={{ title: "Withdraw" }}
                    hasPageNavigator
                    userName={user?.name}
                />
                <PageNavigator pageHeader={{ title: "Withdraw" }} support>
                    <form onSubmit={handleSubmit(submit)}>
                        <Box px="3">
                            <HStack>
                                <Text fontSize="lg">Amount</Text>
                                <Image src={MoneyWithWings} />
                            </HStack>
                            <HStack w="100%">
                                <NumberInput
                                    w="full"
                                    mt={2}
                                    onChange={(valueString) =>
                                        setValue(
                                            "amount",
                                            `${formatMoney(valueString, true)}`
                                        )
                                    }
                                    value={amountValue}
                                    borderColor="brand.primary"
                                    color="brand.primary"
                                    borderWidth="thin"
                                    backgroundColor="brand.lightYellow"
                                    placeholder="Amount to withdraw"
                                    outline="none"
                                    _focus={{
                                        outline: "none",
                                    }}
                                >
                                    <NumberInputField
                                        outline="none"
                                        _focus={{
                                            outline: "none",
                                        }}
                                        {...register("amount", {
                                            required: "Please enter an amount",
                                        })}
                                    />
                                </NumberInput>
                            </HStack>
                            <HStack justifyContent="space-between" mt={5}>
                                <HStack spacing="3" display="flex">
                                    <Text
                                        color={
                                            errors?.account_id
                                                ? "brand.error"
                                                : "inherit"
                                        }
                                        fontSize="lg"
                                    >
                                        {errors?.account_id
                                            ? "Select a bank"
                                            : "To"}
                                    </Text>
                                    <Image src={BankEmoji} />
                                </HStack>
                                {addBankIsLoading ? (
                                    <Spinner color="brand.primary" size="sm" />
                                ) : (
                                    <Text
                                        onClick={() => monoConnect.open()}
                                        color="brand.primary"
                                        fontSize="0.9rem"
                                    >
                                        Add Bank
                                    </Text>
                                )}
                            </HStack>

                            <HStack>
                                <Box
                                    w="100%"
                                    mt={2}
                                    py="2"
                                    px="4"
                                    color="brand.primary"
                                    fontWeight="300"
                                    borderColor="brand.primary"
                                    borderWidth="thin"
                                    backgroundColor="brand.lightYellow"
                                    borderRadius="8"
                                    onClick={() =>
                                        setDrawer({
                                            isOpen: true,
                                            modalId: "SELECT_ACCOUNT",
                                            isDark: false,
                                            showBitMoji: false,
                                        })
                                    }
                                >
                                    <Text>
                                        {selectedBankName ||
                                            "Click to select bank"}
                                    </Text>
                                </Box>
                            </HStack>
                        </Box>
                        <Box>
                            <Center
                                py="5"
                                position="absolute"
                                margin="0"
                                bottom={{ base: "1", md: "10" }}
                                transform="translateX(-50%)"
                                left="50%"
                            >
                                <Button
                                    variant="solid"
                                    type="submit"
                                    isLoading={isLoading}
                                    loadingText="Hang on..."
                                    _hover={{
                                        background: "brand.primary",
                                        shadow: "md",
                                    }}
                                    _active={{
                                        shadow: "md",
                                        transform: "translateY(.5rem)",
                                    }}
                                >
                                    Continue
                                </Button>
                            </Center>
                        </Box>
                    </form>
                </PageNavigator>
            </Box>
        </>
    );
};

Withdraw.propTypes = {
    setDrawer: PropTypes.func,
    monoConnect: PropTypes.object,
};

export default requireAuth(linkAccountWithProviders(Withdraw));
