import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Stack,
    FormControl,
    Input,
    Button,
    useToast,
    Image,
    HStack,
    FormErrorMessage,
    Text,
    Checkbox,
    Box,
    InputRightElement,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { useAuth } from "../../util/auth";

import {
    authButtonTexts,
    afterAuthAction,
    validateEmail,
    validatePhoneNumber,
    sanitizePhoneNumber,
    validatePassword,
} from "./helper";
import { useRouter } from "../../util/router";
import { toastDefaults } from "../../util/constants";
import NameIcon from "../../assets/svgs/name-input-icon.svg";
import PasswordIcon from "../../assets/svgs/input-password-icon.svg";
import EmailIcon from "../../assets/svgs/input-email-icon.svg";
import RefIcon from "../../assets/svgs/ref-input-icon.svg";
import PhoneIcon from "../../assets/svgs/phone-icon.svg";
import { FaEye } from "react-icons/fa";

const AuthForm = ({
    authType,
    // setDrawer
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [addRefCode, setAddRefCode] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const auth = useAuth();
    const router = useRouter();
    const toast = useToast();

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
        setValue,
    } = useForm();

    const authActions = {
        REGISTER: (formData) => {
            return auth.register(formData);
        },
        LOGIN: (formData) => {
            return auth.login(formData);
        },
        "FORGOT-PASSWORD": (formData) => {
            return auth.requestReset(formData); 
        },
        "VERIFY-REFERRAL-CODE": (code) => {
            return auth.verifyReferralCode(code);
        },
    };

    const campaign_name = sessionStorage.getItem("campaign_name");
    const campaign_partner = sessionStorage.getItem("campaign_partner");

    const submit = async (formData) => {
        setIsLoading(true);
        // if (addRefCode) {
        //     const response = await authActions["VERIFY-REFERRAL-CODE"](
        //         formData.referredBy
        //     );
        //     const { status } = response;
        //     if (status !== "verified") {
        //         await toast({
        //             ...toastDefaults,
        //             description: status,
        //             title: "Error",
        //             status: "error",
        //         });
        //         setIsLoading(false);
        //         return setAddRefCode(false);
        //     }
        // }
        const { email, phone_number } = formData;

        const { message, data, status } = await authActions[
            authType.toUpperCase()
        ]({
            ...formData,
            email: email.trim().toLowerCase(),
            phoneNumber: phone_number
                ? await sanitizePhoneNumber(phone_number)
                : "",
            campaign_name,
            campaign_partner,
        });
        setIsLoading(false);
        await toast.closeAll();

        await toast({
            ...toastDefaults,
            description: message,
            title: status ? "Great" : "Error",
            status: status ? "success" : "error",
        });
        if (authType === "login" && status && data?.data?.migrant) {
            sessionStorage.setItem("migrated", true);
        }
        status && sessionStorage.removeItem("campaign_name");
        status && sessionStorage.removeItem("campaign_partner");
        status && sessionStorage.removeItem("refCode");
        status && router.push(afterAuthAction[authType.toUpperCase()]);
    };

    useEffect(() => {
        clearErrors();
    }, [authType, clearErrors]);

    const refCode = sessionStorage.getItem("refCode");

    useEffect(() => {
        if (refCode) {
            setAddRefCode(true);
            setValue("referredBy", refCode);
        }
        // eslint-disable-next-line
    }, [refCode]);

    return (
        <>
            <Stack
                p="5"
                px={{ base: "5", md: "10" }}
                paddingTop={{ base: "0", md: "5" }}
            >
                <form onSubmit={handleSubmit(submit)}>
                    {["register"].includes(authType) && (
                        <HStack height="70px">
                            <Image fleex="1" src={NameIcon} />
                            <FormControl
                                isInvalid={errors?.name?.message}
                                flex="1"
                                marginBottom="4"
                            >
                                <Input
                                    {...register("name", {
                                        required: "This field is required",
                                    })}
                                    placeholder="Name"
                                    border="none"
                                    borderRadius="0px"
                                    borderBottom="1px"
                                    borderBottomColor={
                                        errors?.name
                                            ? "brand.error"
                                            : "brand.darkGrey"
                                    }
                                    name="name"
                                    type="text"
                                    _invalid={{
                                        border: "none",
                                        borderBottom: "1px",
                                        borderBottomColor: "brand.error",
                                    }}
                                    _focus={{
                                        outline: "none",
                                        borderColor: "brand.darkGrey",
                                    }}
                                />
                                <FormErrorMessage
                                    fontSize="0.75rem"
                                    color="brand.error"
                                >
                                    {errors?.name?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </HStack>
                    )}

                    {["register", "login", "forgot-password"].includes(
                        authType
                    ) && (
                        <HStack height="70px">
                            <Image fleex="1" src={EmailIcon} />
                            <FormControl
                                isInvalid={errors?.email?.message}
                                marginBottom="4"
                            >
                                <Input
                                    {...register("email", {
                                        required: "This field is required",
                                        validate: (val) => {
                                            //eslint-disable-next-line
                                            if (validateEmail(val)) {
                                                return true;
                                            }
                                            return "Email is invalid";
                                        },
                                    })}
                                    placeholder="Email"
                                    border="none"
                                    borderRadius="0px"
                                    borderBottom="1px"
                                    borderBottomColor={
                                        errors?.email
                                            ? "brand.error"
                                            : "brand.darkGrey"
                                    }
                                    name="email"
                                    type="text"
                                    _focus={{
                                        outline: "none",
                                        borderColor: "brand.darkGrey",
                                    }}
                                    _invalid={{
                                        border: "none",
                                        borderBottom: "1px",
                                        borderBottomColor: "brand.error",
                                    }}
                                />
                                <FormErrorMessage
                                    fontSize="0.75rem"
                                    color="brand.error"
                                >
                                    {errors?.email?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </HStack>
                    )}

                    {["register"].includes(authType) && (
                        <HStack height="70px">
                            <Image fleex="1" src={PhoneIcon} />
                            <FormControl
                                isInvalid={errors?.phone_number?.message}
                                marginBottom="4"
                            >
                                <Input
                                    {...register("phone_number", {
                                        required: false,
                                        validate: (val) => {
                                            if (val) {
                                                //eslint-disable-next-line
                                                return validatePhoneNumber(val)
                                                    ? true
                                                    : "Phone Number is invalid";
                                            } else {
                                                return true;
                                            }
                                        },
                                    })}
                                    placeholder="Phone Number"
                                    border="none"
                                    borderRadius="0px"
                                    borderBottom="1px"
                                    borderBottomColor={
                                        errors?.phone_number
                                            ? "brand.error"
                                            : "brand.darkGrey"
                                    }
                                    name="phone_number"
                                    type="tel"
                                    _focus={{
                                        outline: "none",
                                        borderColor: "brand.darkGrey",
                                    }}
                                    _invalid={{
                                        border: "none",
                                        borderBottom: "1px",
                                        borderBottomColor: "brand.error",
                                    }}
                                />
                                <FormErrorMessage
                                    fontSize="0.75rem"
                                    color="brand.error"
                                >
                                    {errors?.phone_number?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </HStack>
                    )}
                    {["register", "login"].includes(authType) && (
                        <HStack
                            height={
                                errors?.password?.message ? "100px" : "80px"
                            }
                        >
                            <Image fleex="1" src={PasswordIcon} />
                            <FormControl
                                isInvalid={errors?.password?.message}
                                marginBottom="4"
                            >
                                <Input
                                    {...register("password", {
                                        required: "This field is required",
                                        validate: (val) =>
                                            authType === "register"
                                                ? validatePassword(val)
                                                : true,
                                    })}
                                    placeholder="Password"
                                    border="none"
                                    borderRadius="0px"
                                    borderBottom="1px"
                                    borderBottomColor={
                                        errors?.password
                                            ? "brand.error"
                                            : "brand.darkGrey"
                                    }
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    _focus={{
                                        outline: "none",
                                        borderColor: "brand.darkGrey",
                                    }}
                                    _invalid={{
                                        border: "none",
                                        borderBottom: "1px",
                                        borderBottomColor: "brand.error",
                                    }}
                                />{" "}
                                <InputRightElement width="4.5rem">
                                    <Box
                                        cursor="pointer"
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                        color={
                                            showPassword
                                                ? "brand.primary"
                                                : "brand.textGrey"
                                        }
                                        mt="3"
                                    >
                                        {" "}
                                        <FaEye />
                                    </Box>
                                </InputRightElement>
                                <FormErrorMessage
                                    fontSize="0.75rem"
                                    color="brand.error"
                                >
                                    {errors?.password?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </HStack>
                    )}
                    {["register"].includes(authType) && (
                        <HStack w="full">
                            <HStack px="1rem" flex="1">
                                <Checkbox
                                    isChecked={addRefCode}
                                    onChange={({ target: { checked } }) =>
                                        setAddRefCode(checked)
                                    }
                                    size="lg"
                                    colorScheme="brandPrimaryTheme"
                                />
                                <Text
                                    ml="1rem"
                                    fontWeight="700"
                                    color="brand.textLightGey"
                                >
                                    Referral code
                                </Text>
                            </HStack>
                        </HStack>
                    )}

                    {["register"].includes(authType) && addRefCode && (
                        <HStack height="70px">
                            <Image fleex="1" src={RefIcon} />
                            <FormControl marginBottom="4">
                                <Input
                                    {...register("referredBy", {
                                        required: false,
                                    })}
                                    placeholder="Referral Code"
                                    border="none"
                                    borderRadius="0px"
                                    borderBottom="1px"
                                    borderBottomColor={
                                        errors?.referredBy
                                            ? "brand.error"
                                            : "brand.darkGrey"
                                    }
                                    name="referredBy"
                                    maxLength="5"
                                    type="text"
                                    _focus={{
                                        outline: "none",
                                        borderColor: "brand.darkGrey",
                                    }}
                                    _invalid={{
                                        border: "none",
                                        borderBottom: "1px",
                                        borderBottomColor: "brand.error",
                                    }}
                                />
                                <FormErrorMessage
                                    fontSize="0.75rem"
                                    color="brand.error"
                                >
                                    {errors?.referredBy?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </HStack>
                    )}

                    <Stack py="5" spacing={6}>
                        <Button
                            h="3.2rem"
                            m="auto"
                            color="brand.white"
                            bg="brand.primary"
                            variant="solid"
                            width="full"
                            _hover={{
                                background: "brand.primary",
                                shadow: "md",
                            }}
                            _active={{
                                shadow: "md",
                                transform: "translateY(.5rem)",
                            }}
                            borderRadius="12px"
                            shadow="sm"
                            isLoading={isLoading}
                            loadingText="Hang on.."
                            type="submit"
                        >
                            <Text>
                                {" "}
                                {/*eslint-disable-next-line*/}
                                {authButtonTexts[authType.toUpperCase()]}{" "}
                            </Text>
                        </Button>
                    </Stack>
                </form>
            </Stack>
        </>
    );
};

export default AuthForm;

AuthForm.propTypes = {
    authType: PropTypes.string,
    setDrawer: PropTypes.func,
};
