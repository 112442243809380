import axios from "axios";
import { toastDefaults } from "./constants";
import { createStandaloneToast } from "@chakra-ui/react";

const apiBaseUrl = process.env.REACT_APP_API_BASEURL;
const toast = createStandaloneToast();

const logRequests = () => {
    return (
        process.env.NODE_ENV === "development" &&
        (axios.interceptors.request.use((request) => {
            return request;
        }),
        axios.interceptors.response.use((response) => {
            return response;
        }))
    );
};

logRequests();

export async function apiRequest(
    path,
    method = "GET",
    data,
    params,
    shouldNotReload,
    tempToken
) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    const accessToken = await sessionStorage.getItem("token");

    try {
        const response = await axios({
            url: `${apiBaseUrl}${path}`,
            method,
            params: {
                ...params,
            },
            cancelToken: source.token,
            data,
            headers: {
                Authorization: `Bearer ${accessToken || tempToken}`,
            },
        });

        const {
            data: { message },
        } = response;
        return {
            status: true,
            data: response.data.data || response.data,
            message,
        };
    } catch (err) {
        const errMessage =
            err.response?.data?.message ||
            err.message ||
            "Session Expired, please login again.";
        if (
            err.response?.status === 401 &&
            !shouldNotReload &&
            path !== "/users/login"
        ) {
            toast({
                ...toastDefaults,
                status: "error",
                desciption: errMessage,
                title: "Error",
            });
            return window.location.reload();
        }
        return {
            status: false,
            message: errMessage,
            err,
            code: err.response?.status,
        };
    }
}
