import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Text, SimpleGrid, VStack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import { Button, Center } from "@chakra-ui/react";
import { handleSubmission } from "./helper";
import UserDescription from "./UserDescription";
import { userDescriptions } from "../../util/constants";
import { toastDefaults } from "../../util/constants";

const SetUserDescription = ({ setOnboardingStep }) => {
    const toast = useToast();
    const [selectedUserTypes, setSelectedUserType] = useState([]);

    const submit = () => {
        if (selectedUserTypes.length == 0) {
            return toast({
                ...toastDefaults,
                status: "error",
                desciption: "No User Persona has been selected",
                title: "Error",
            });
        }
        handleSubmission(selectedUserTypes, setOnboardingStep, toast);
    };

    return (
        <>
            <VStack
                spacing={{ base: "5", md: "10" }}
                margin="auto"
                py={{ base: "2", md: "0" }}
                overflow="hidden"
            >
                <Box mx="auto" w="70%">
                    <Text
                        color="brand.primary"
                        mb={{ base: "3", md: "2" }}
                        fontSize="1.6rem"
                        textAlign="center"
                        fontWeight="700"
                    >
                        Tell myStash about yourself.
                    </Text>
                    <Text
                        color="brand.dark"
                        textAlign="center"
                        fontSize="0.8rem"
                    >
                        {" "}
                        Select which best describes you
                    </Text>
                </Box>
                <Box w="full">
                    <SimpleGrid
                        columns={{ base: 2, md: 4 }}
                        w="80%"
                        m="auto"
                        spacing={2}
                        gridAutoRows="max-content"
                    >
                        {userDescriptions.map((desc, i) => (
                            <UserDescription
                                selectedUserTypes={selectedUserTypes}
                                setSelectedUserType={setSelectedUserType}
                                description={desc}
                                key={desc.title}
                                i={i}
                            />
                        ))}
                        <Box
                            textAlign="center"
                            onClick={() =>
                                setSelectedUserType(
                                    userDescriptions.map((type) => type.title)
                                )
                            }
                            flex="1"
                        >
                            <Box
                                borderColor="brand.primary"
                                cursor="pointer"
                                border={
                                    selectedUserTypes.length === 3
                                        ? "2px"
                                        : "0px"
                                }
                                width="100%"
                                h={{ base: "80%", md: "70" }}
                                p="auto"
                                borderRadius="3px"
                                marginBottom="3"
                                bg="brand.lightPrimaryButton"
                                transition="ease-in-out"
                                transitionDuration="0.1s"
                            >
                                <Center h="full" w="full">
                                    <Text
                                        fontSize={{ md: "0.8rem" }}
                                        color="brand.primary"
                                    >
                                        Select all
                                    </Text>
                                </Center>
                            </Box>
                            {/* <Text fontSize="1rem" textTransform="capitalize">
                Select all
              </Text> */}
                        </Box>
                    </SimpleGrid>
                    <Text
                        fontSize={{ base: "1rem", md: "0.8rem" }}
                        w="80%"
                        m="auto"
                        mt="5"
                        textAlign="center"
                    >
                        Let’s understand your financial goals better
                    </Text>
                </Box>
                <Button
                    w="60%"
                    h="3.2rem"
                    m="auto"
                    onClick={() => submit()}
                    color="brand.white"
                    bg="brand.primary"
                    variant="solid"
                    width="full"
                    _hover={{
                        background: "brand.primary",
                        shadow: "md",
                    }}
                    shadow="sm"
                    _active={{
                        shadow: "md",
                        transform: "translateY(.5rem)",
                    }}
                >
                    <Text>Continue</Text>
                </Button>
            </VStack>
        </>
    );
};

SetUserDescription.propTypes = {
    setOnboardingStep: PropTypes.func,
};

export default SetUserDescription;
