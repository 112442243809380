import React from 'react'
import { useEditableControls, Text, Image } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import EDIT_ICON from "../../../assets/images/edit.png";

export function EditableControls({ smaller }) {
  const {
    isEditing,

    getEditButtonProps,
  } = useEditableControls();

  return isEditing ? null : (
    <Text
      color="brand.primary"
      fontSize="0.7rem"
      cursor="pointer"
      d="inline-block"
      {...getEditButtonProps()}
    >
      <Image height={smaller ? "15px" : "20px"} w="auto" src={EDIT_ICON} />
    </Text>
  );
}

EditableControls.propTypes = {
  smaller: PropTypes.bool,
  
};