import React, { useEffect, useState } from "react";
import { Box, Image } from "@chakra-ui/react";
import SupportIcon from "../../assets/svgs/support-icon.svg";
import PageNavigator from "../../components/PageNavigator";
import { requireAuth, useAuth } from "../../util/auth";
import FAQList from "../../components/FAQList";
import { SUPPORT_WHATSAPP_CONTACT } from "../../util/constants";
import queryString from "query-string";
import TopNavigation from "./../../components/TopNavigation";
import PropTypes from "prop-types";
import { useFaq } from "../../util/hooks/useFAq";

const FAQPage = (props) => {
    const {
        location: { search },
    } = props;
    const [topFaqCategory, setTopFaqCategory] = useState("all");
    const { user } = useAuth();
    const faqQuestions = useFaq();

    useEffect(() => {
        const parsedQueryString = queryString.parse(search);
        const { type } = parsedQueryString;
        return setTopFaqCategory(type);
    }, [search]);

    return (
        <>
            <Box
                h={{ md: "full" }}
                overflowY="scroll"
                w={["100%", "600px", "500px"]}
                paddingTop={{ md: "0" }}
                css={{
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
            >
                <TopNavigation hasPageNavigator userName={user?.name} />
                <PageNavigator pageHeader={{ title: "FAQ" }}>
                    <Box
                        w="100%"
                        h="90vh"
                        overflowY="scroll"
                        position="relative"
                        css={{
                            "&::-webkit-scrollbar": {
                                display: "none",
                            },
                        }}
                    >
                        <FAQList
                            faqQuestions={faqQuestions}
                            topFaqCategory={topFaqCategory}
                        />
                    </Box>
                </PageNavigator>
            </Box>
            <Image
                onClick={() =>
                    window.open(`http://wa.me/${SUPPORT_WHATSAPP_CONTACT}`)
                }
                position="absolute"
                bottom="2rem"
                right="0rem"
                cursor="pointer"
                src={SupportIcon}
            />
        </>
    );
};

FAQPage.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
    }),
};

export default requireAuth(FAQPage);
