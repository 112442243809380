import createDataContext from "./createContext";
import { fetchUserCards } from "../util/operations/cards";
import { toastError } from "./helper";

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_CARDS_SUCCESS":
            return {
                ...state,
                cards: action.payload,
            };
        case "ADD_NEW_ACCOUNTS_SUCCESS":
            return {
                ...state,
                cards: [...state.cards, action.payload],
            };

        default:
            return state;
    }
};

const addCardToState = (dispatch) => (card) => {
    return dispatch({
        type: "ADD_NEW_ACCOUNTS_SUCCESS",
        payload: card,
    });
};

const getUserCards = (dispatch) => async () => {
    const { status, data, message } = await fetchUserCards();

    if (!status) return toastError(message);

    data &&
        (await dispatch({
            type: "FETCH_CARDS_SUCCESS",
            payload: data,
        }));
};

export const { Context, Provider } = createDataContext(
    reducer,
    {
        getUserCards,
        addCardToState,
    },
    {
        cards: [],
    }
);
