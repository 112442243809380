import React from "react";
import PropTypes from "prop-types";
import { Flex, Box, Text } from "@chakra-ui/react";

const CardComponent = ({ card, selectedCard, setSelectedCard }) => {
    const preselectedCard = JSON.parse(
        sessionStorage.getItem("selectedCard") || "{}"
    );
    return (
        <Flex
            cursor="pointer"
            onClick={() => {
                setSelectedCard(card);
                sessionStorage.setItem("selectedCard", selectedCard);
            }}
            px="5"
        >
            <Box
                w="15px"
                h="15px"
                borderRadius="100%"
                border="3px solid"
                borderColor={
                    selectedCard?.cardId === card?.cardId ||
                    preselectedCard?.cardId === card.cardId
                        ? "brand.flexBg"
                        : "transparent"
                }
                bg={
                    selectedCard?.cardId === card?.cardId ||
                    preselectedCard?.cardId === card.cardId
                        ? "brand.primary"
                        : "brand.flexBg"
                }
            />
            {/* <Spacer /> */}

            <Box pl="5">
                <Text textAlign="left" mb={2}>
                    {card?.cardType} ****** {card?.last4}
                </Text>
                <Text mb={2}>
                    {" "}
                    Exp: {card?.expMonth}/{card?.expYear}{" "}
                </Text>
            </Box>
        </Flex>
    );
};

CardComponent.propTypes = {
    card: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            cardId: PropTypes.string.isRequired,
            cardType: PropTypes.string,
        }),
    ]),
    selectedCard: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            cardId: PropTypes.string.isRequired,
        }),
    ]),
    setSelectedCard: PropTypes.func,
};

export default CardComponent;
