import FlexBalanceIcon from "../../assets/svgs/flex-balance-icon.svg";
import FlowBalanceIcon from "../../assets/svgs/flow-balance-icon.svg";

export const balanceType = [
  {
    title: "Money Moves",
    key: "flowBalance",
    link: "/mandates",
    themeColor: "lightPrimary",
    icon: FlowBalanceIcon,
    headingColor: "primary",
  },
  {
    title: "Cash Reserve",
    key: "flexBalance",
    themeColor: "flexBg",
    link: "/stash",
    icon: FlexBalanceIcon,
    headingColor: "dark",
  },
];
