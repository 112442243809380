import React, { useState, useEffect } from "react";
import { Flex, Box } from "@chakra-ui/react";
import Announcement from "../Announcement";
import { handleAnnouncementClick } from "./helper";
import linkAccountWithProviders from "../../LinkAcountWithProviders";
import PropTypes from "prop-types";

const SLIDES_INTERVAL_TIME = 3500;
const ANIMATION_DIRECTION = "right";

const AnnouncementSlider = ({
    announcements,
    monoConnect,
    okraConnect,
    readUserAnnouncement,
}) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slidesCount, setSlidesCount] = useState(announcements.length);

    useEffect(() => {
        setSlidesCount(announcements.length);
    }, [announcements]);

    const prevSlide = (count) => {
        setCurrentSlide((s) => (s === 0 ? count - 1 : s - 1));
    };
    const nextSlide = (count) => {
        setCurrentSlide((s) => (s === count - 1 ? 0 : s + 1));
    };
    const carouselStyle = {
        transition: "all .5s",
        ml: `-${currentSlide * 100}%`,
    };

    useEffect(() => {
        const automatedSlide = setInterval(() => {
            ANIMATION_DIRECTION.toLowerCase() === "left"
                ? prevSlide(slidesCount)
                : nextSlide(slidesCount);
        }, SLIDES_INTERVAL_TIME);
        return () => clearInterval(automatedSlide);
        // eslint-disable-next-line
    }, []);

    return (
        <Flex
            w="full"
            bg="brand.lightYellow"
            h="full"
            alignItems="center"
            justifyContent="center"
            px="3"
            py="3"
        >
            <Flex
                position="absolute"
                top="0"
                left="0"
                w="full"
                overflow="hidden"
            >
                <Flex w="full" {...carouselStyle}>
                    {announcements.map((announcement) => (
                        <Box
                            onClick={() =>
                                handleAnnouncementClick(
                                    announcement,
                                    monoConnect,
                                    okraConnect,
                                    readUserAnnouncement,
                                    announcements
                                )
                            }
                            w="100%"
                            h="10vh"
                            key={`slide-${announcement._id}`}
                            flex="none"
                        >
                            <Announcement announcement={announcement} />
                        </Box>
                    ))}
                </Flex>
            </Flex>
        </Flex>
    );
};

AnnouncementSlider.propTypes = {
    announcements: PropTypes.array,
    monoConnect: PropTypes.object,
    okraConnect: PropTypes.func,
    readUserAnnouncement: PropTypes.func,
};

export default linkAccountWithProviders(AnnouncementSlider);
