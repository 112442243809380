import React, { useState, useContext, useEffect } from "react";
import {
    Box,
    HStack,
    Image,
    Center,
    Button,
    Text,
    NumberInput,
    NumberInputField,
    useToast,
    VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import MoneyWithWings from "../../assets/svgs/dollar-bills-with-wings.svg";
import { handleWithdraw } from "./helper";
import { useHistory } from "react-router-dom";
import { formatMoney, convertDollarToNaira } from "../../util/helper-functions";
import PropTypes from "prop-types";
import { useRouter } from "../../util/router";
import { Context as MandatesContext } from "../../contexts/mandatesContext";
import PageLoader from "../../components/PageLoader";
import useExchangeRateData from "../../util/hooks/useExchangeRateData";

const WithdrawPerk = ({ setDrawer }) => {
    const [isLoading, setIsLoading] = useState(false);
    const router = useHistory();
    const { query } = useRouter();
    const { rate } = useExchangeRateData();
    const {
        state: { mandateDetails, userMandateDetailsLoading, mandates },
        getMandateDetails,
        editMandateInState,
    } = useContext(MandatesContext);

    useEffect(() => {
        getMandateDetails(query?.mandateId, mandateDetails, toast);
        // eslint-disable-next-line
    }, [query?.mandateId]);

    const mandate = mandateDetails[query?.mandateId];

    console.log("MANDATE IS MATURE===>", mandate?.matured)


    const toast = useToast();

    const {
        register,
        handleSubmit,
        // formState: { errors },
        watch,
        // setValue,
    } = useForm();

    const dollarToBewithdrawn = watch("amount");

    const submit = async (formData) => {
        return handleWithdraw({
            formData,
            setIsLoading,
            router,
            toast,
            setDrawer,
            mandate,
            editMandateInState,
            mandates,
        });
    };
    if (userMandateDetailsLoading || isLoading) {
        return <PageLoader />;
    }

    return (
        <>
            <VStack w="90%" mx="auto">
                <Box px="3" w="full">
                    <Text fontWeight={"600"} fontSize={"1.3rem"}>
                        Move to Cash Reserve{" "}
                    </Text>
                    <HStack>
                        <Text fontWeight={"300"}>Exchange rate </Text>
                        <Text color="brand.primary">₦{rate?.buy}/$1</Text>
                    </HStack>
                </Box>

                <Box py="5" w="full">
                    <form onSubmit={handleSubmit(submit)}>
                        <Box px="3">
                            <HStack justifyContent={"space-between"}>
                                <HStack>
                                    <Text fontSize="lg">Amount</Text>
                                    <Image src={MoneyWithWings} />
                                </HStack>
                                <HStack>
                                    <Text>Naira:</Text>
                                    <Text color="brand.primary">
                                        ₦
                                        {formatMoney(
                                        convertDollarToNaira(
                                            Number(dollarToBewithdrawn),
                                            rate?.buy
                                        )
                                    )}
                                    </Text>
                                </HStack>
                            </HStack>
                            <HStack w="100%">
                                <NumberInput
                                    w="full"
                                    mt={2}
                                    // onChange={(valueString) =>
                                    //     setValue(
                                    //         "amount",
                                    //         `${formatMoney(valueString, true)}`
                                    //     )
                                    // }
                                    value={dollarToBewithdrawn}
                                    borderColor="brand.primary"
                                    color="brand.primary"
                                    borderWidth="thin"
                                    backgroundColor="brand.lightYellow"
                                    placeholder="Amount to withdraw"
                                    outline="none"
                                    _focus={{
                                        outline: "none",
                                    }}
                                >
                                    <NumberInputField
                                        outline="none"
                                        _focus={{
                                            outline: "none",
                                        }}
                                        {...register("amount", {
                                            required: "Please enter an amount",
                                        })}
                                    />
                                </NumberInput>
                            </HStack>
                        </Box>
                        <Box>
                            <Center
                                py="5"
                                position="absolute"
                                margin="0"
                                bottom={{ base: "1", md: "10" }}
                                transform="translateX(-50%)"
                                left="50%"
                            >
                                <Button
                                    variant="solid"
                                    type="submit"
                                    isLoading={isLoading}
                                    loadingText="Hang on..."
                                    _hover={{
                                        background: "brand.primary",
                                        shadow: "md",
                                    }}
                                    _active={{
                                        shadow: "md",
                                        transform: "translateY(.5rem)",
                                    }}
                                >
                                    Done
                                </Button>
                            </Center>
                        </Box>
                    </form>
                </Box>
            </VStack>
        </>
    );
};

WithdrawPerk.propTypes = {
    setDrawer: PropTypes.func,
};

export default WithdrawPerk;
