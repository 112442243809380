import React from "react";
import {
    linkAccountWithMono,
    linkAccountWithOkraV2,
} from "../../util/operations/account";
import { toastDefaults } from "../../util/constants";
import TryAnotherOBP from "../../components/TryAnotherOBP";

export const handleConnectWithMono = async (
    code,
    toast,
    setBankIsLoading,
    addAccountToState,
    setDrawer,
    okraConnect
) => {
    setBankIsLoading(true);

    const { status, message, data } = await linkAccountWithMono(code);

    if (status) {
        await addAccountToState(data);
    }

    if (!status) {
        return setDrawer({
            isOpen: true,
            modalId: "ACCOUNT_LINKING_ERROR",
            data: { okraConnect },
        });
    }

    toast({
        ...toastDefaults,
        status: status ? "success" : "error",
        desciption: message,
        title: status ? "Success" : "Error",
    });
    setBankIsLoading(false);
};

export const handleConnectWithOkra = async (
    okraData,
    toast,
    actionType,
    actions
) => {
    const { status, message, data } = await linkAccountWithOkraV2(okraData);
    switch (actionType) {
        case "SELECT_BANK":
            actions?.setBankIsLoading(true);
            break;
        default:
            actions?.setOnboardingStep("LOADING");
    }

    toast({
        ...toastDefaults,
        status: status ? "success" : "error",
        desciption: message,
        title: status ? "Success" : "Error",
    });

    if (status) {
        (await actionType) === "SELECT_BANK"
            ? actions?.addAccountToState(data.data)
            : actions?.setOnboardingStep(6);
    }
    actionType === "SELECT_BANK" && (await actions?.setBankIsLoading(false));
};

export const renderDrawerChild = ({ modalId, data }, setDrawer) => {
    switch (modalId) {
        case "ACCOUNT_LINKING_ERROR":
            return <TryAnotherOBP setDrawer={setDrawer} data={data} />;
        default:
            return <> </>;
    }
};
