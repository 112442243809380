import React, { lazy, Suspense, useState } from "react";
import { Switch, Route } from "../../util/router";
import { requireAuth, useAuth } from "../../util/auth";
import DashboardBottomNav from "../../components/DashboardBottomNav";
import PageNavigator from "../../components/PageNavigator";
import TopNavigation from "../../components/TopNavigation";
import { Box } from "@chakra-ui/react";
import PropTypes from 'prop-types'
import PageLoader from "../../components/PageLoader";

const NotFoundPage = lazy(() => import("../not-found"));
const MainMenu = lazy(() => import("../../settings-pages/main-menu"));
const EditProfilePage = lazy(() => import("../../settings-pages/edit-profile"));
const EditBankDetails = lazy(() =>
  import("../../settings-pages/edit-bank-details")
);
const AccountType = lazy(() => import("../../settings-pages/account-type"));
const AmbassadorPage = lazy(() => import("../../settings-pages/ambassador"));
// const SavingLimit = lazy(() => import("../../settings-pages/saving-limit"));

const SettingsPage = ({ setDrawer }) => {
  const { user } = useAuth();
  const [pageHeader, setPageHeader] = useState({
    title: "",
    isEditable: false,
  });

  const [showBottomNav, setShowBottomNav] = useState(true);

  return (
    <Box w={["100%", "600px", "500px"]} h={{ md: "full" }} paddingTop={{ md: "0" }}>
      <TopNavigation hasPageNavigator pageHeader={pageHeader} userName={user?.name} />

      <PageNavigator support pageHeader={pageHeader}>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route
              exact
              path={"/settings/account-type"}
              render={(routeProps) => (
                <AccountType
                  {...routeProps}
                  setShowBottomNav={setShowBottomNav}
                  setPageHeader={setPageHeader}
                />
              )}
            />
            {user?.ambassador && (
              <Route
                exact
                path={"/settings/ambassador"}
                render={(routeProps) => (
                  <AmbassadorPage
                    {...routeProps}
                    setShowBottomNav={setShowBottomNav}
                    setPageHeader={setPageHeader}
                  />
                )}
              />
            )}

            {/* <Route
              exact
              path={"/settings/change-percentage-limit"}
              render={(routeProps) => (
                <SavingLimit
                  {...routeProps}
                  setShowBottomNav={setShowBottomNav}
                  setPageHeader={setPageHeader}
                />
              )}
            /> */}

            {/* 
            <Route
              exact
              path={"/settings/edit-passcode"}
              render={(routeProps) => <> Edit Passcode </>}
            /> */}

            <Route
              exact
              path={"/settings/edit-profile"}
              render={(routeProps) => (
                <EditProfilePage
                  {...routeProps}
                  setShowBottomNav={setShowBottomNav}
                  setPageHeader={setPageHeader}
                />
              )}
            />

            <Route
              exact
              path={"/settings/bank-details"}
              render={(routeProps) => (
                <EditBankDetails
                  setShowBottomNav={setShowBottomNav}
                  setPageHeader={setPageHeader}
                  setDrawer={setDrawer}
                  {...routeProps}
                />
              )}
            />

            <Route
              path={"/settings"}
              exact
              render={(routeProps) => (
                <MainMenu
                  setShowBottomNav={setShowBottomNav}
                  {...routeProps}
                  setPageHeader={setPageHeader}
                />
              )}
            />

            <Route
              path="*"
              render={(routeProps) => (
                <NotFoundPage {...routeProps} setDrawer={setDrawer} />
              )}
            />
          </Switch>
        </Suspense>
      </PageNavigator>
      {showBottomNav ? <DashboardBottomNav setDrawer={setDrawer} /> : null}
    </Box>
  );
};

SettingsPage.propTypes = {
  setDrawer: PropTypes.func
}
export default requireAuth(SettingsPage);
