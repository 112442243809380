import React from "react";
import { Box, Text, HStack, Image } from "@chakra-ui/react";
import AnnoucementIcon from "../../../assets/svgs/pad-speaker.svg";
import PropTypes from "prop-types";
const Announcement = ({ announcement }) => {
    return (
        <HStack px="3">
            <Box p="5" flex="1">
                <Image src={AnnoucementIcon} />
            </Box>
            <Box textAlign="right" flex="4">
                <Text
                    fontSize="0.85rem"
                    display="block"
                    color="brand.textLightGey"
                >
                    {announcement?.message}
                </Text>
                <Text fontSize="0.85rem" display="block" color="brand.primary">
                    {announcement?.title}
                </Text>
            </Box>
        </HStack>
    );
};

Announcement.propTypes = {
    announcement: PropTypes.shape({
        title: PropTypes.string,
        message: PropTypes.string,
    }),
};

export default Announcement;
