import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  PinInput,
  PinInputField,
  HStack,
  VStack,
  useToast,
  Text,
  Button,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { handleSetPassccode } from "./helper";
import { FaArrowRight } from "react-icons/fa";

const SetPasscode = ({ setOnboardingStep }) => {
  const [passCode, setPassCode] = useState(null);

  const toast = useToast();

  const { register, handleSubmit } = useForm();

  const submit = () => {
    return handleSetPassccode(passCode, setOnboardingStep, toast);
  };

  return (
    <>
      <VStack width="50%" spacing="100" margin="auto" py="200">
        <Text textAlign="center" fontSize="120%">
          Create Password
        </Text>
        <HStack>
          <PinInput onComplete={(code) => setPassCode(code)} size="lg" mask>
            <PinInputField
              border="none"
              borderBottom="1px"
              borderRadius="none"
              _focus={{
                outline: "none",
              }}
              borderColor="brand.primary"
              {...register("one", {
                required: "This field is required",
              })}
            />
            <PinInputField
              border="none"
              borderBottom="1px"
              borderRadius="none"
              _focus={{
                outline: "none",
              }}
              borderColor="brand.primary"
              {...register("two", {
                required: "This field is required",
              })}
            />
            <PinInputField
              border="none"
              borderBottom="1px"
              borderRadius="none"
              _focus={{
                outline: "none",
              }}
              borderColor="brand.primary"
              {...register("three", {
                required: "This field is required",
              })}
            />
            <PinInputField
              border="none"
              borderBottom="1px"
              borderRadius="none"
              _focus={{
                outline: "none",
              }}
              borderColor="brand.primary"
              {...register("four", {
                required: "This field is required",
              })}
            />
          </PinInput>
        </HStack>
        <Button
          onClick={handleSubmit(submit)}
          color="brand.white"
          bg="brand.primary"
          variant="solid"
          width="full"
          _hover={{
            background: "brand.primary",
            shadow: "md",
          }}
          shadow="sm"
          _active={{
            shadow: "md",
            transform: "translateY(.5rem)",
          }}
        >
          <Text mr="5">Next</Text> <FaArrowRight />
        </Button>
      </VStack>
    </>
  );
};

SetPasscode.propTypes = {
  setOnboardingStep: PropTypes.func,
};

export default SetPasscode;
