import React, { useState } from "react";
import { Box, Text, Image, Skeleton } from "@chakra-ui/react";
import PropTypes from "prop-types";
import DeleteNotificationIcon from "../../assets/svgs/delete-notification-icon.svg";

const Notification = ({
  notification,
  readUserNotification,
  notifications,
  handleMarkAsRead,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Skeleton
        startColor="brand.primary"
        endColor="brand.secondary"
        borderRadius="10px"
        h="auto"
        w="100%"
        isLoaded={!isLoading}
      >
        <Box
          py="5"
          px={{ base: "3", md: "5" }}
          bg="brand.lightYellow"
          w="100%"
          position="relative"
        >
          <Image
            cursor="pointer"
            position="absolute"
            top="1rem"
            right="3rem"
            src={DeleteNotificationIcon}
            onClick={() =>
              handleMarkAsRead(
                notification._id,
                setIsLoading,
                notifications,
                readUserNotification
              )
            }
          />
          <Text
            cursor="pointer"
            onClick={() => setShowDetails(!showDetails)}
            fontWeight="600"
            marginBottom="0.5rem"
          >
            New Notification
          </Text>
          {showDetails ? (
            <>
              <Text mb="4">{notification.message}</Text>
              <Text fontWeight="600" fontSize="0.6rem">
                {" "}
                MyStash team
              </Text>
            </>
          ) : null}
        </Box>
      </Skeleton>
    </>
  );
};

Notification.propTypes = {
  readUserNotification: PropTypes.func,
  notifications: PropTypes.shape({
    noOfUnread: PropTypes.number,
    unread: PropTypes.array,
  }),
  notification: PropTypes.shape({
    _id: PropTypes.string,
    uid: PropTypes.string,
    message: PropTypes.string,
    reference: PropTypes.string,
    read: PropTypes.bool,
  }),
  handleMarkAsRead: PropTypes.func
};

export default Notification;
