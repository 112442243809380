import StudentDescription from "../assets/svgs/student.svg";
import BusinessClassDescription from "../assets/svgs/business-owner.svg";
import WorkingClassDescription from "../assets/svgs/working-class.svg";

// import StudentWBG from "../assets/svgs/student-wbg.svg";
// import BusinessClassWBG from "../assets/svgs/business-class-wbg.svg";
// import WorkingClassWBG from "../assets/svgs/working-class-wbg.svg";

import Student from "../assets/images/student.png";
import BusinessClass from "../assets/images/business-class.png";
import WorkingClass from "../assets/images/worker.png";

import SAYE_ICON from "../assets/svgs/saye-icon.svg";
import SAYS_ICON from "../assets/svgs/says-icon.svg";
import SAYES_ICON from "../assets/svgs/sayse-icon.svg";
import SSA_ICONIMP from "../assets/svgs/ssa.png";

export const SSA_ICON = SSA_ICONIMP;
export const SUPPORT_WHATSAPP_CONTACT = "2348131462292";
export const APP_BASEURL = "http://app.mystash.ng";

export const toastDefaults = {
    position: "top",
    duration: 3000,
    isClosable: true,
};

export const userDescriptions = [
    {
        title: "student",
        name: "student",
        icon: StudentDescription,
        iconWBG: Student,
        id: 1,
        savingLimit: "20%",
    },
    {
        title: "worker",
        name: "working",
        icon: WorkingClassDescription,
        iconWBG: BusinessClass,
        id: 2,
        savingLimit: "40%",
    },
    {
        title: "sme_owner",
        name: "business",
        icon: BusinessClassDescription,
        iconWBG: WorkingClass,
        id: 3,
        savingLimit: "80%",
    },
];

export const stashTypes = [
    {
        title: "Earn",
        subtitle:
            "Automatically save with ease every time you earn on your bank account.",
        key: "saye",
        icon: SAYE_ICON,
        slug: "save-as-you-earn",
    },
    {
        title: "Spend",
        subtitle:
            "Automatically save with ease every time you spend from your bank account.",
        key: "says",
        icon: SAYS_ICON,
        slug: "save-as-you-spend",
    },
    {
        title: "Earn & Spend",
        subtitle:
            "Automatically save with ease every time you earn or spend from your bank account.",
        key: "sayes",
        icon: SAYES_ICON,
        slug: "save-as-you-earn-and-spend",
    },
    // {
    //     title: "Intend",
    //     subtitle:
    //         "Save specific amounts on a daily, weekly, or monthly.",
    //     key: "fixed-amount",
    //     icon: SSA_ICON,
    //     slug: "save-as-you-intend",
    // },
];
export const stashAmount = [
    { title: "2%", key: "2" },
    { title: "5%", key: "5" },
    { title: "7%", key: "7" },
    { title: "10%", key: "10" },
];

export const mandateDurations = [
    {
        title: "3 months (5% interest)",
        key: 3,
    },
    {
        title: "6 months (7% interest)",
        key: 6,
    },
    {
        title: "12 months (9% interest)",
        key: 12,
    },
];

export const TEST_AUTH_CONTENT = {
    user: {
        ambassador: true,
        basicStats: {
            linkedAccounts: 5,
            linkedCards: 2,
            activeMandates: 25,
            mandates: 0,
            stashes: 64,
        },
        disabled: false,
        email: "review@gmail.com",
        house_address: "Ola House",
        institution: "OAU",
        linkedAccount: true,
        linkedCard: true,
        migrated: false,
        name: "Review User",
        personas: ["sme_owner", "student"],
        phone_number: "09098898878",
        referral_code: "GFQHM",
        role: "user",
        saving_limit: 80,
        setMandate: true,
        setPassCode: false,
        uid: "g3irrbjizu4u2021072195635",
        verified: false,
        __v: 5,
        _id: "60f7ef532075ee0015d46e88",
    },
};
