import React, { useContext, useEffect } from "react";
import {
    Box,
    HStack,
    Text,
    Image,
    VStack,
    useToast,
    Center,
    Spinner,
} from "@chakra-ui/react";
import { Context as MandatesContext } from "../../contexts/mandatesContext";
import { Context as NotificationsContext } from "../../contexts/notificationsContext";
import { Context as StatsContext } from "../../contexts/statisticsContext";
import { requireAuth, useAuth } from "../../util/auth";
import DashboardBottomNav from "../../components/DashboardBottomNav";
import TopNav from "../../components/TopNav";
import TopNavigation from "../../components/TopNavigation";
import UserBalanceSection from "../../components/UserBalanceSection";
import AnnouncementSection from "../../components/AnnoucementSection";
import MandatesSection from "../../components/MandatesSection";
import HelpIcon from "../../assets/svgs/faq-icon.svg";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

const DashboardPage = ({ setDrawer, drawer }) => {
    const history = useHistory();

    const {
        state: { mandates, userMandatesLoading },
        getUserMandates,
    } = useContext(MandatesContext);

    const {
        state: { statLoading, userReserve },

        fetchUserReserve,
    } = useContext(StatsContext);

    const {
        state: { announcements, announcementsLoading },
        getAnnouncements,
        readUserAnnouncement,
    } = useContext(NotificationsContext);

    const {
        user,
        // : { uncelebratedMandates, ...user },
    } = useAuth();

    const toast = useToast();

    useEffect(() => {
        getUserMandates(mandates, toast);
        getAnnouncements(announcements, toast);

        fetchUserReserve(userReserve);
        // eslint-disable-next-line
    }, []);

    const cashReserveBalance = userReserve?.cashReserve?.balance;
    const mandateBalance = userReserve?.mandateReserve?.total;

    return (
        <>
            <Box
                paddingBottom={{ base: "10", md: "20" }}
                paddingTop={{ base: "20", md: "0" }}
                position="relative"
            >
                <TopNavigation userName={user?.name} />
                <VStack spacing={{ base: "5" }}>
                    <TopNav userName={user?.name} />
                    <UserBalanceSection
                        statLoading={statLoading}
                        drawer={drawer}
                        setDrawer={setDrawer}
                        totalStash={mandateBalance + cashReserveBalance}
                        totalFlexBalance={cashReserveBalance}
                        totalFlowBalance={mandateBalance}
                    />
                    <AnnouncementSection
                        announcements={announcements}
                        announcementsLoading={announcementsLoading}
                        readUserAnnouncement={readUserAnnouncement}
                    />
                    <HStack marginTop="5" width="90%">
                        <Text flex="1" color="brand.primary">
                            {" "}
                            Money moves ({mandates?.length}):
                        </Text>
                        <motion.div
                            animate={{
                                transform: [
                                    "scale(0.7, 0.7)",
                                    "scale(1, 1)",
                                    "scale(0.7, 0.7)",
                                ],
                            }}
                            transition={{
                                duration: 2,
                                ease: "easeInOut",
                                loop: Infinity,
                                repeatDelay: 0.5,
                            }}
                        >
                            <Image
                                animation=""
                                cursor="pointer"
                                onClick={() =>
                                    history.push("/help?type=dashboard")
                                }
                                src={HelpIcon}
                            />
                        </motion.div>
                    </HStack>
                    {userMandatesLoading ? (
                        <Center py="10">
                            <Spinner color="brand.primary" size="lg" />
                        </Center>
                    ) : (
                        <MandatesSection
                            usersMandates={mandates}
                            setDrawer={setDrawer}
                        />
                    )}
                </VStack>
            </Box>
            <DashboardBottomNav drawer={drawer} setDrawer={setDrawer} />
        </>
    );
};

DashboardPage.propTypes = {
    setDrawer: PropTypes.func,
    drawer: PropTypes.object,
};

export default requireAuth(DashboardPage);
