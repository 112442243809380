import { createStandaloneToast } from "@chakra-ui/react";
import { toastDefaults } from "../util/constants";

const toast = createStandaloneToast();

export const toastSuccess = (message) => {
    toast({
      ...toastDefaults,
      title: "Success",
      description: message,
      status: "success",
    });
  }
  
export const toastError = (message) => {
    toast({
      ...toastDefaults,
      status: "error",
      title: "Error",
      description: message,
    });
  }