import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AuthForm from "../../components/AuthForm";
import AuthFormHeader from "../../components/AuthFormHeader";
import AuthFooter from "../../components/AuthFooter";
import NotFoundPage from "../not-found";
import { useAuth } from "../../util/auth";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
// import { Text, VStack } from "@chakra-ui/react";
// import MaintenanceImage from "../../assets/svgs/maintenance.svg";
// import Logo from "../../assets/svgs/logo.png";

const AuthPage = (props) => {
    const router = useHistory();

    const {
        location: { search },
        match: {
            params: { authType },
        },
    } = props;

    const auth = useAuth();

    useEffect(() => {
        auth?.user && router.push("/onboarding");
        // eslint-disable-next-line
    }, [auth]);

    // close drawers
    useEffect(() => {
        props.setDrawer({
            isOpen: false,
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (search) {
            const parsedQueryString = queryString.parse(search);
            const { ref, campaign_partner, campaign_name } = parsedQueryString;
            ref && sessionStorage.setItem("refCode", ref);
            campaign_partner &&
                sessionStorage.setItem("campaign_partner", campaign_partner);
            campaign_name &&
                sessionStorage.setItem("campaign_name", campaign_name);
        }
        // eslint-disable-next-line
    }, [search]);

    return (
        <>
            {["", "register", "login", "forgot-password"].includes(authType) ? (
                <>
                    <AuthFormHeader authType={authType || "login"} />
                    <AuthForm authType={authType || "login"} />
                    <AuthFooter authType={authType || "login"} />

                    {/* <VStack w="80%" mx="auto" py="20" flex="1" spacing={5}>
                        <img
                            style={{
                                width: "80%",
                            }}
                            src={MaintenanceImage}
                            alt="under maintenance"
                        />
                        <img
                            style={{
                                width: "10%",
                            }}
                            src={Logo}
                            alt="under maintenance"
                        />
                        <Text
                            textAlign={"center"}
                            fontSize="lg"
                            fontWeight={"extrabold"}
                        >
                            Platform is under scheduled maintenance till 12 AM,
                            please bear with us.
                        </Text>
                    </VStack> */}
                </>
            ) : (
                <NotFoundPage {...props} />
            )}
        </>
    );
};

AuthPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            params: PropTypes.string,
            authType: PropTypes.string,
        }),
    }),
    location: PropTypes.shape({
        search: PropTypes.string,
    }),
    setDrawer: PropTypes.func,
};

export default AuthPage;
