import { apiRequest } from "../apiRequest";

export const initiateWithdraw = (data) => {
    return apiRequest("/cashReserve/withdraw/initiate", "POST", data);
};

export const initiateWithdrawy = (data) => {
    return apiRequest("/cashReserve/withdraw/initiatey", "POST", data);
};

export const verifyWithdraw = (data) => {
    return apiRequest(`/payment/verify/${data.reference}`, "POST");
};
