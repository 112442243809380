import createDataContext from "./createContext";
import { fetchUnreadNotifications } from "../util/operations/notifications";
import { toastDefaults } from "../util/constants";

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_NOTIFICATIONS_SUCCESS":
            return {
                ...state,
                notifications: action.payload,
                notificationsLoading: false,
            };
        case "FETCH_NOTIFICATIONS_LOADING":
            return {
                ...state,
                notificationsLoading: true,
            };

        case "FETCH_ANNOUNCEMENTS_LOADING":
            return {
                ...state,
                announcementsLoading: true,
            };

        case "FETCH_ANNOUNCEMENTS_SUCCESS":
            return {
                ...state,
                announcementsLoading: false,
                announcements: action.payload,
            };

        default:
            return state;
    }
};

const readUserNotification =
    (dispatch) => async (notificationId, notifications) => {
        const unreadNotifications = await notifications.unread.filter(
            (not) => not._id !== notificationId
        );

        dispatch({
            type: "FETCH_NOTIFICATIONS_SUCCESS",
            payload: {
                noOfUnread: unreadNotifications.length,
                unread: unreadNotifications,
            },
        });
    };

const readUserAnnouncement = (dispatch) => (id, announcements) => {
    const updatedAnnouncements = announcements.filter((not) => not._id !== id);

    dispatch({
        type: "FETCH_ANNOUNCEMENTS_SUCCESS",
        payload: updatedAnnouncements,
    });
};

const getUserNotifications = (dispatch) => async (notifications, toast) => {
    if (notifications && notifications.length > 0) {
        return true;
    }
    dispatch({
        type: "FETCH_ANNOUNCEMENTS_LOADING",
    });
    const { status, data, message } = await fetchUnreadNotifications();

    !status &&
        (await toast({
            ...toastDefaults,
            status: "error",
            title: "Error",
            description: message,
        }));

    if (data) {
        const announcements = data?.data?.filter(
            (a) => a.type === "announcement"
        );
        const notifications = data?.data?.filter(
            (a) => a.type !== "announcement"
        );
        await dispatch({
            type: "FETCH_NOTIFICATIONS_SUCCESS",
            payload: notifications,
        });
        await dispatch({
            type: "FETCH_ANNOUNCEMENTS_SUCCESS",
            payload: announcements,
        });
    }
};

export const { Context, Provider } = createDataContext(
    reducer,
    {
        getUserNotifications,
        readUserNotification,
        getAnnouncements: getUserNotifications,
        readUserAnnouncement,
    },
    {
        notifications: [],
    }
);
