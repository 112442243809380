/* eslint-disable  */
import React, { useEffect } from "react";
import { Box } from "@chakra-ui/layout";

function NotFoundPage({ setDrawer, ...props }) {
  useEffect(() => {
    setDrawer({
      isOpen: true,
      modalId: "ERROR_404",
      isDark: false,
      showBitMoji: false,
      data: { location: props.location.pathname },
    });
  }, []);
  return (
    <Box p="20" w="full" textAlign="center">
      {/* The page <Code>{props.location.pathname}</Code> could not be found. */}
    </Box>
  );
}

export default NotFoundPage;
