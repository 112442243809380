import React, { useEffect } from "react";
import { HStack, VStack } from "@chakra-ui/layout";
import { Box, Text, Image, Flex } from "@chakra-ui/react";
import DashboardMiddleBtn from "../../assets/svgs/modal-plus-button.svg";
import DollarBills from "../../assets/svgs/dollar-bills.svg";
import { balanceType } from "./helper";
import { useHistory } from "react-router-dom";
import { formatMoney, renderNaira } from "../../util/helper-functions";
import PropTypes from "prop-types";

const UserBalanceSection = ({
    setDrawer,
    //drawer,
    //stashBalance,
    totalStash,
    totalFlexBalance,
    totalFlowBalance,
    interest,
    // percentageInterest,
}) => {
    useEffect(() => {
        return function cleanup() {
            setDrawer({
                isOpen: false,
            });
        }; // eslint-disable-next-line
    }, []);

    const router = useHistory();

    return (
        <>
            <VStack width="100%" spacing="10">
                <Flex
                    px="6"
                    mb={{ base: "0", md: "0" }}
                    mt={{ base: "0", md: "8" }}
                    textAlign="center"
                    w="full"
                    justifyContent="space-between"
                >
                    <Box flex={{ base: "1", md: "2" }}>
                        <VStack w="full">
                            <Box w="full">
                                <Text
                                    textAlign={{ base: "center", md: "left" }}
                                >
                                    {" "}
                                    Total Balance
                                </Text>
                                <Text
                                    fontSize="2.3rem"
                                    fontWeight="600"
                                    textAlign={{ base: "center", md: "left" }}
                                >
                                    ₦ {formatMoney(renderNaira(totalStash))}
                                </Text>
                                <Flex
                                    fontStyle="italic"
                                    justifyContent={{
                                        base: "center",
                                        md: "left",
                                    }}
                                    alignContent="baseline"
                                >
                                    <Text
                                        fontSize="0.6rem"
                                        textAlign="left"
                                        mt={{ base: "auto", md: "0.45rem" }}
                                        mb="auto"
                                    >
                                        Your money grows like grass
                                    </Text>
                                    <Image
                                        height={{ base: "1rem", md: "1.4rem" }}
                                        src={DollarBills}
                                    />
                                </Flex>
                            </Box>
                        </VStack>
                    </Box>

                    <Box
                        display={{ base: "none", md: "flex" }}
                        flex={{ md: "1" }}
                        cursor="pointer"
                        textAlign="center"
                    >
                        <Image
                            onClick={() =>
                                setDrawer({
                                    isOpen: true,
                                    modalId: "APP_MENU",
                                    isDark: true,
                                    showBitMoji: false,
                                })
                            }
                            _active={{
                                transform: "translateY(.2rem)",
                            }}
                            m="auto"
                            display="block"
                            shadow="xl"
                            borderRadius="full"
                            src={DashboardMiddleBtn}
                        />
                    </Box>
                </Flex>

                <HStack alignItems="stretch" width="100%" spacing="5" px="5%">
                    {balanceType.map((type) => (
                        <Box
                            onClick={() => router.push(type.link)}
                            cursor="pointer"
                            borderRadius="10px"
                            p="3"
                            py="5"
                            flex="1"
                            bg={`brand.${type.themeColor}`}
                            position="relative"
                            key={type.title}
                        >
                            <Image
                                position="absolute"
                                right="15px"
                                top="10px"
                                src={type.icon}
                            />
                            <Text
                                opacity={
                                    type.key === "flexBalance" ? "0.5" : "1"
                                }
                                fontSize="0.75rem"
                                color={`brand.${type.headingColor}`}
                            >
                                {type.title}
                            </Text>
                            <Text fontSize="1.25rem" color="brand.dark">
                                ₦
                                {type.key === "flexBalance"
                                    ? formatMoney(renderNaira(totalFlexBalance))
                                    : formatMoney(
                                          renderNaira(totalFlowBalance)
                                      )}
                            </Text>
                            {type.key === "flowBalance" ? (
                                <Text
                                    fontStyle="italic"
                                    fontSize="0.7rem"
                                    color="brand.primary"
                                >
                                    {" "}
                                    +₦
                                    {Math.floor((interest / 100) * 10) / 10 ||
                                        0}
                                    {/* (
                                    {Math.floor(percentageInterest * 10) / 10 ||
                                        0}
                                    %){" "} */}
                                </Text>
                            ) : null}
                        </Box>
                    ))}
                </HStack>
            </VStack>
        </>
    );
};

UserBalanceSection.propTypes = {
    totalStash: PropTypes.number,
    interest: PropTypes.number.isRequired,
    // percentageInterest: PropTypes.number.isRequired,
    totalFlexBalance: PropTypes.number,
    totalFlowBalance: PropTypes.number.isRequired,
    setDrawer: PropTypes.func,
};

export default UserBalanceSection;
