import { useState, useEffect } from "react";
import { fetchExchangeRates } from "../operations/account";

const useExchangeRateData = () => {
    const [rate, setRate] = useState({});

    const fetchRates = async () => {
        const response = await fetchExchangeRates();
        return response;
    };

    useEffect(() => {
        if (!rate?.buy) {
            fetchRates().then((rates) => {
                const { humanReadableBuyRate, humanReadableSaleRate } =
                    rates?.data;

                return setRate({
                    buy: humanReadableBuyRate?.toFixed(),
                    sell: humanReadableSaleRate?.toFixed(),
                    rate: humanReadableBuyRate?.toFixed(),
                });
            });
        }
        return null;
    }, []);
    return {
        rate,
    };
};

export default useExchangeRateData;
