import React from "react";
import PropTypes from "prop-types";
import { VStack, Text } from "@chakra-ui/layout";
import { Box, HStack, Image, Button } from "@chakra-ui/react";
import PEACE_HEAD from "../../assets/svgs/modal-peace-sign.svg";
import { useHistory } from "react-router-dom";
import CheckMark from "../../assets/svgs/checkmark-emoji.svg";
import MoneyBundle from "../../assets/svgs/dollar-bills.svg";

const checkpoints = ["when you spend", "when you earn", "while you sleep"];

const PostOnboarding = ({ setDrawer }) => {
    const router = useHistory();
    return (
        <>
            <VStack
                spacing={{ base: "1", md: "2" }}
                pb={{ base: "50", md: "5" }}
            >
                <Box
                    borderBottomLeftRadius="37px"
                    borderBottomRightRadius="37px"
                    h="11rem"
                    pt={{ base: "10", md: "0" }}
                    w="100%"
                    bg={{ base: "brand.lightPrimaryButton", md: "brand.white" }}
                    mb="-7rem"
                >
                    <HStack justifyContent="center" w="50%" m="auto">
                        <Box
                            borderRadius="100%"
                            w="10px"
                            h="10px"
                            bg="brand.lightGrey"
                        />
                        <Box
                            borderRadius="100%"
                            w="10px"
                            h="10px"
                            bg="brand.primary"
                        />
                        <Box
                            borderRadius="100%"
                            w="10px"
                            h="10px"
                            bg="brand.lightGrey"
                        />
                    </HStack>

                    <Image
                        m="auto"
                        mt={{ base: "1rem", md: "1rem" }}
                        d="block"
                        h={{ base: "7rem", md: "8rem" }}
                        src={PEACE_HEAD}
                    />
                </Box>

                <VStack spacing={{ base: "5", md: "4" }}>
                    <VStack spacing="5" mt={{ base: "7rem", md: "1rem" }}>
                        <Box w="full" m="auto" textAlign="center">
                            <Image
                                m="auto"
                                mb="5"
                                h={{ base: "2rem", md: "3rem" }}
                                src={MoneyBundle}
                            />
                            <Text
                                m="auto"
                                w="80%"
                                fontSize={{ base: "1.4rem", md: "1.5rem" }}
                            >
                                {" "}
                                Save & Make money{" "}
                            </Text>
                        </Box>
                        <VStack textAlign="left" w="full">
                            {checkpoints.map((cp) => (
                                <HStack
                                    m="auto"
                                    w={{ base: "80%", md: "80%" }}
                                    key={cp}
                                >
                                    <Image h="1rem" src={CheckMark} />{" "}
                                    <Text
                                        fontSize={{
                                            base: "1rem",
                                            md: "1.1rem",
                                        }}
                                    >
                                        {" "}
                                        {cp}{" "}
                                    </Text>
                                </HStack>
                            ))}
                        </VStack>
                        <Text
                            mt="1rem"
                            color="brand.primary"
                            fontSize="0.95rem"
                            textAlign="center"
                        >
                            Stage 2/3
                        </Text>
                    </VStack>

                    <Button
                        onClick={() => {
                            setDrawer({
                                isOpen: false,
                            });
                            sessionStorage.setItem("stageMandate", "STAGE_3");
                            return router.push("/mandates/create");
                        }}
                        w="80%"
                        m="auto"
                        // color="brand.primary"
                        // bg="brand.white"
                        variant="solid"
                        _hover={{
                            shadow: "md",
                        }}
                        shadow="sm"
                        _active={{
                            shadow: "md",
                            transform: "translateY(.5rem)",
                        }}
                    >
                        <Text> Make a money move </Text>
                    </Button>

                    <Box
                        cursor="pointer"
                        onClick={() => router.replace("/dashboard")}
                    >
                        <Text color="brand.primary" fontWeight="700">
                            {" "}
                            Skip{" "}
                        </Text>
                    </Box>
                </VStack>

                <Box marginBottom="15" w="50%" mx="auto" />
            </VStack>
        </>
    );
};

export default PostOnboarding;

PostOnboarding.propTypes = {
    setDrawer: PropTypes.func,
};
