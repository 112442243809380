import React from 'react'
import { Flex, Img } from '@chakra-ui/react'
import MyStash from '../../../assets/svgs/logo.svg'

const NavigationLogo = () => (
  <Flex
    ml={{md: 2, lg:20}}
    cursor="pointer"
    flexDir="column"
    alignItems="center"
    justifyContent="center"
  >
    <Img alt="MyStash logo" src={MyStash} />
  </Flex>
);


export default NavigationLogo