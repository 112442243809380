import {
    initiateMandateActivation,
} from "./operations/mandates";
import {
    fundCashReserve,
} from "./operations/cashReserve";
import { initializePaystack } from "./paystack";

export const createPayment = async ({ type, amountInNaira, mandateId, cardId, accountId, reference, setDrawer, setIsLoading }) => {
    let response;
    switch (type) {
        case "ACTIVATE_MANDATE":
            response = initiateMandateActivation({ 
                mandateId,
                accountId,
                reference,
                amount: parseInt(amountInNaira), // server receives amount in naira
            });
            break;
        case "FUND_CASH_RESERVE":
            response = fundCashReserve({
                reference,
                cardId,
                amount: parseInt(amountInNaira),
            });
            break;
        default:
            break;
    }
    
    return response && response.catch((err) => {
        setIsLoading(false);
        
        return setDrawer({
            isOpen: true,
            modalId: "CUSTOM_ERROR",
            isDark: false,
            showBitMoji: false,
            data: {
                message: err.response?.message,
            },
        });
    })
};

export const initialize = ({
    setDrawer,
    user,
    data,
    type,
    setIsLoading,
    router,
}) => {
    const channels = type === "FUND_CASH_RESERVE" ? ['card', 'bank', 'ussd', 'bank_transfer'] : ["card"];
    return initializePaystack({
        user,
        type,
        amountInNaira: data?.amountInNaira,
        channels,
        data,
        verifyPayment: (response) => {
            verifyPayment({ reference: response?.response?.reference, data, type, setDrawer, router, setIsLoading });
        },
    });

};

export const verifyPayment = async ({
    reference,
    data,
    type,
    setDrawer,
    router,
    setIsLoading,
}) => {
    setIsLoading(true);
    const {mandateId, accountId, cardId, amountInNaira} = data;
    const responseData = await createPayment({
        type,
        mandateId, 
        accountId, 
        cardId,
        setDrawer, 
        setIsLoading,
        amountInNaira,
        reference,
    })

    console.log(responseData);
    if (!responseData?.status) {
        return setDrawer({
            isOpen: true,
            modalId: "CUSTOM_ERROR",
            isDark: false,
            showBitMoji: false,
            data: {
                message: responseData?.message,
            },
        });
    }

    setIsLoading(false);
    router?.push("/dashboard");
    return setDrawer({
        isOpen: true,
        modalId: "SUCCESS_COMPONENT",
        isDark: false,
        showBitMoji: false,
        data: {
            amount: amountInNaira,
            type: type === "ACTIVATE_MANDATE" ? "MANDATE" : "CASH_RESERVE",

        },
    });
};
