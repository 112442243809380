import React, { useContext, useEffect } from "react";
import PageNavigator from "../../components/PageNavigator";
import { Context as NotificationsContext } from "../../contexts/notificationsContext";
import Notification from "../../components/Notification";
import TopNavigation from "../../components/TopNavigation";
import {
  VStack,
  Text,
  useToast,
  Box,
  Image,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { requireAuth, useAuth } from "../../util/auth";
import PeaceIcon from "../../assets/svgs/modal-peace-sign.svg";
import { handleMarkAsRead } from "./helper";

const NotificationsPage = () => {
  const {
    state: { notifications, notificationsLoading },
    getUserNotifications,
    readUserNotification,
  } = useContext(NotificationsContext);
  const toast = useToast();
  const { user } = useAuth();

  useEffect(() => {
    getUserNotifications(notifications, toast);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box w={["100%", "600px", "500px"]} position="relative" paddingTop={{ md: "0" }}>
        <TopNavigation pageHeader={{ title: "Notifications" }} hasPageNavigator userName={user?.name} />
        <PageNavigator support pageHeader={{ title: "Notifications" }}>
          {notificationsLoading ? (
            <Center py="20vh">
              <Spinner color="brand.primary" />
            </Center>
          ) : (
            <>
              {notifications?.unread?.length > 0 ? (
                <VStack w="100%" py="1">
                  {notifications?.unread.map((notification) => (
                    <Notification
                      notification={notification}
                      handleMarkAsRead={handleMarkAsRead}
                      key={notification._id}
                      notifications={notifications}
                      readUserNotification={readUserNotification}
                    />
                  ))}
                </VStack>
              ) : (
                <Box h="full" w="80%" m="auto" py="20">
                  <Image w="140px" m="auto" src={PeaceIcon} />
                  <Text textAlign="center"> {"Hehe, we're clear here"} </Text>
                </Box>
              )}
            </>
          )}
        </PageNavigator>
      </Box>
    </>
  );
};

export default requireAuth(NotificationsPage);
