import createDataContext from "./createContext";
import { fetchCashReserveBalance, fetchCashReserveHistory } from "../util/operations/cashReserve";
import { toastDefaults } from "../util/constants";

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_STASH_HISTORY_SUCCESS":
      return {
        ...state,
        history: action.payload,
        stashHistoryLoading: false,
      };
    case "FETCH_STASH_BALANCE_SUCCESS":
      return {
        ...state,
        stashBalance: action.payload,
      };
    case "FETCH_STASH_HISTORY_LOADING":
      return {
        ...state,
        stashHistoryLoading: true,
      };
    default:
      return state;
  }
};

const getUserStashHistory = (dispatch) => async (history, toast) => {
  if (history && history.length > 0) {
    return true;
  }

  dispatch({
    type: "FETCH_STASH_HISTORY_LOADING",
  });
  const { status, data, message } = await fetchCashReserveHistory();

  !status &&
    (await toast({
      ...toastDefaults,
      status: "error",
      title: "Error",
      description: message,
    }));

  data &&
    (await dispatch({
      type: "FETCH_STASH_HISTORY_SUCCESS",
      payload: data,
    }));
};

const getUserStashBalance = (dispatch) => async (balance, toast) => {
  if (balance) {
    return true;
  }
  const { status, data, message } = await fetchCashReserveBalance();

  !status &&
    (await toast({
      ...toastDefaults,
      status: "error",
      title: "Error",
      description: message,
    }));

  data &&
    (await dispatch({
      type: "FETCH_STASH_BALANCE_SUCCESS",
      payload: data.total_stashed_balance,
    }));
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    getUserStashHistory,
    getUserStashBalance,
  },
  {
    history: [],
  }
);
