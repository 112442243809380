import React from "react";
import PropTypes from "prop-types";
import { Provider as AccountsProvider } from "./accountsContext";
import { Provider as NotificationsProvider } from "./notificationsContext";
import { Provider as StashProvider } from "./stashContext";
import { Provider as MandatesProvider } from "./mandatesContext";
import { Provider as StatsProvider } from "./statisticsContext";
import { Provider as CardsProvider } from "./cardsContext";

const ContextProvider = ({ children }) => {
    return (
        <NotificationsProvider>
            <MandatesProvider>
                <StashProvider>
                    <AccountsProvider>
                        <CardsProvider>
                            <StatsProvider>{children}</StatsProvider>
                        </CardsProvider>
                    </AccountsProvider>
                </StashProvider>
            </MandatesProvider>
        </NotificationsProvider>
    );
};

ContextProvider.propTypes = {
    children: PropTypes.node,
};

export default ContextProvider;
