import { VStack, Image, Text, useToast } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SadYeni from "../../assets/images/sad-yeni.png";
import { useAuth } from "../../util/auth";
import { toastDefaults } from "../../util/constants";
import { checkUserVerified } from "../../util/helper-functions";
import { resendVerificationEmail } from "../../util/operations/user";

const AlertVerifyEmail = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [resent, setResent] = useState(false);
    const [timer, setTimer] = useState(120);
    const toast = useToast();
    const { user } = useAuth();
    const history = useHistory();

    useEffect(() => {
        if (user) {
            const userVerified = checkUserVerified(user);
            if (userVerified) {
                history.push("/onboarding");
            }
        }
        if (user === false) {
            history.push("/user/login");
        }
    }, [user]);

    useEffect(() => {
        if (resent) {
            setTimeout(() => {
                if (timer === 0) {
                    setTimer(120);
                    return setResent(false);
                }
                setTimer(timer - 1);
            }, 1000);
        }
    }, [resent, timer]);

    const handleResendEmail = async () => {
        setIsLoading(true);
        const { status } = await resendVerificationEmail(user?.email);
        toast({
            ...toastDefaults,
            status: status ? "success" : "error",
            desciption: status ? "Email sent" : "Something went wrong",
            title: status ? "Success" : "Error",
        });
        setResent(true);
        return setIsLoading(false);
    };
    return (
        <VStack spacing={5} py="20">
            <Image w="10rem" src={SadYeni} alt="sad" />
            <Text w="80%" mx="auto" textAlign={"center"} fontSize={"lg"}>
                We have sent a verification code to your email, please use it to
                verify your email to continue using myStash.
            </Text>
            <Text>{"Didn't"} get an email? </Text>
            <Text
                onClick={() => (resent ? null : handleResendEmail())}
                cursor={"pointer"}
                color="brand.primary"
            >
                {isLoading || resent
                    ? `Please wait ${timer} seconds before retrying again`
                    : "Resend"}
            </Text>
        </VStack>
    );
};

export default AlertVerifyEmail;
