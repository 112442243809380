import React from "react";
import PropTypes from "prop-types";

import { Box, Text, Image } from "@chakra-ui/react";

const UserDescription = ({
    description,
    selectedUserTypes,
    setSelectedUserType,
    // eslint-disable-next-line no-unused-vars
    i,
}) => {
    const { title, name, iconWBG } = description;
    const handleClick = (type) => {
        if (selectedUserTypes.includes(type)) {
            const filtered = selectedUserTypes.filter((t) => t !== type);
            return setSelectedUserType(filtered);
        }
        return setSelectedUserType([...selectedUserTypes, type]);
    };

    return (
        <>
            <Box textAlign="center" onClick={() => handleClick(title)} flex="1">
                <Box
                    borderColor="brand.primary"
                    cursor="pointer"
                    border={selectedUserTypes.includes(title) ? "2px" : "0px"}
                    p="auto"
                    borderRadius="3px"
                    marginBottom="3"
                    transition="ease-in-out"
                    transitionDuration="0.1s"
                >
                    <Image
                        display={{ md: "none", base: "flex" }}
                        src={iconWBG}
                    />

                    <Box
                        display={{ base: "none", md: "flex" }}
                        h={{ base: "100", md: "70" }}
                        w={{ base: "100", md: "70" }}
                        backgroundSize="cover"
                        backgroundRepeat="no-repeat"
                        backgroundPosition="center"
                        bgImage={iconWBG}
                    />
                </Box>
                <Text fontSize="0.8rem" textTransform="capitalize">
                    {name}
                </Text>
                {/* <Text fontSize="0.6rem" textTransform="capitalize">
          {savingLimit} saving limit
        </Text> */}
            </Box>
        </>
    );
};

UserDescription.propTypes = {
    description: PropTypes.shape({
        title: PropTypes.string,
        icon: PropTypes.node,
        id: PropTypes.number,
        savingLimit: PropTypes.string,
        name: PropTypes.string,
        iconWBG: PropTypes.node,
    }),
    setSelectedUserType: PropTypes.func,
    selectedUserTypes: PropTypes.array,
    i: PropTypes.number,
};

export default UserDescription;
