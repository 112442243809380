import { apiRequest } from "../apiRequest";

export const fetchNotifications = () => {
    return apiRequest(
        `/notification/all?sortBy=createdAt&sortType=asc`,
        "GET"
    );
};
export const markAsRead = ({ id }) =>
    apiRequest(`/notification/read/${id}`, "PATCH");

export const deleteNotification = ({ id }) =>
    apiRequest(`/notification/delete/${id}`, "DELETE");

export const markNotificationAsRead = (notificationId) => {
    return apiRequest(`/notification/read/${notificationId}`, "PATCH");
};

export const fetchUnreadNotifications = () => {
    return apiRequest(
        `/notification/all?sortBy=createdAt&sortType=asc`,
        "GET"
    );
};
