import createDataContext from "./createContext";
import {
    fetchMandateDetails,
    fetchUserMandates,
    getSingleMandateReserveHistory,
} from "../util/operations/mandates";
import { toastDefaults } from "../util/constants";

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_USER_MANDATES_SUCCESS":
            return {
                ...state,
                userMandatesLoading: false,
                mandates: action.payload,
            };
        case "ADD_NEW_MANDATE_TO_STATE":
            return {
                ...state,
                mandates: [action.payload, ...state.mandates],
            };
        case "FETCH_MANDATE_DETAILS_SUCCESS":
            return {
                ...state,
                mandateDetails: {
                    ...state.mandateDetails,
                    [action.payload?.mandateId]: {
                        ...action.payload,
                        stashHistory:
                            state.mandateDetails[action.payload?.mandateId]
                                ?.stashHistory,
                    },
                },
                userMandateDetailsLoading: false,
            };
        case "FETCH_MANDATE_STASH_HISTORY_SUCCESS":
            return {
                ...state,
                mandateDetails: {
                    ...state.mandateDetails,
                    [action.payload.mandateId]: {
                        ...state.mandateDetails[action.payload.mandateId],
                        stashHistory: action.payload.data,
                    },
                },
                userMandateHistoryLoading: false,
            };
        case "USER_MANDATES_LOADING":
            return {
                ...state,
                userMandatesLoading: true,
            };
        case "USER_MANDATE_DETAILS_LOADING":
            return {
                ...state,
                userMandateDetailsLoading: true,
            };
        case "USER_MANDATE_HISTORY_LOADING":
            return {
                ...state,
                userMandateHistoryLoading: true,
            };
        case "EDIT_MANDATE_SUCCESS":
            return {
                ...state,
                mandates: [...action.payload.mandates],
                mandateDetails: {
                    ...state.mandateDetails,
                    [action.payload.singleMandate.mandateId]:
                        action.payload.singleMandate,
                },
            };
        case "EDIT_MANDATE_DETAIL_SUCCESS":
            return {
                ...state,
                mandateDetails: {
                    ...state.mandateDetails,
                    [action.payload.mandateId]: {
                        ...state.mandateDetails[action.payload.mandateId],
                        ...action.payload.data,
                    },
                },
            };
        default:
            return state;
    }
};

const addMandateToState = (dispatch) => (newMandate) => {
    return dispatch({
        type: "ADD_NEW_MANDATE_TO_STATE",
        payload: newMandate,
    });
};

const editMandateStatus =
    (dispatch) => (mandateId, mandates, newMandateDetails) => {
        const mandateIndex = mandates.findIndex(
            (mandate) => mandate.mandateId === mandateId
        );

        mandates[mandateIndex] = {
            ...mandates[mandateIndex],
            ...newMandateDetails,
        };

        dispatch({
            type: "FETCH_USER_MANDATES_SUCCESS",
            payload: mandates,
        });

        return dispatch({
            type: "FETCH_MANDATE_DETAILS_SUCCESS",
            payload: {
                ...mandates[mandateIndex],
            },
        });
    };

const editMandateInState = (dispatch) => (newMandateData, mandates) => {
    const mandateIndex = mandates.findIndex(
        (mandate) => mandate.mandateId === newMandateData.mandateId
    );

    mandates[mandateIndex] = { ...mandates[mandateIndex], ...newMandateData };

    dispatch({
        type: "EDIT_MANDATE_SUCCESS",
        payload: { mandates, singleMandate: mandates[mandateIndex] },
    });

    dispatch({
        type: "EDIT_MANDATE_DETAIL_SUCCESS",
        payload: {
            data: newMandateData,
            mandateId: newMandateData.id,
        },
    });
};

const getUserMandates = (dispatch) => async (mandates, toast) => {
    // if (mandates && mandates.length > 0) {
    //     return true;
    // }

    dispatch({
        type: "USER_MANDATES_LOADING",
    });

    const { status, data, message } = await fetchUserMandates();

    !status &&
        (await toast({
            ...toastDefaults,
            status: "error",
            title: "Error",
            description: message,
        }));

    const nairaNandates = data && data.filter(mandate => mandate?.currency !== "USD" || mandate?.type === 'employee-perk');

    data &&
        (await dispatch({
            type: "FETCH_USER_MANDATES_SUCCESS",
            payload: nairaNandates,
        }));
};

const getMandateDetails =
    (dispatch) => async (mandateId, mandateDetails, toast) => {
        if (mandateDetails && mandateDetails[mandateId]) {
            return true;
        }

        dispatch({
            type: "USER_MANDATE_DETAILS_LOADING",
        });

        const { status, data, message } = await fetchMandateDetails(mandateId);

        !status &&
            (await toast({
                ...toastDefaults,
                status: "error",
                title: "Error",
                description: message,
            }));

        data &&
            (await dispatch({
                type: "FETCH_MANDATE_DETAILS_SUCCESS",
                payload: data,
            }));
    };

const getMandateHistory =
    (dispatch) => async (mandateId, mandateDetails, toast) => {
        if (mandateDetails && mandateDetails[mandateId]?.stashHistory) {
            return true;
        }
        dispatch({
            type: "USER_MANDATE_HISTORY_LOADING",
        });

        const { status, data, message } = await getSingleMandateReserveHistory(
            mandateId
        );

        !status &&
            (await toast({
                ...toastDefaults,
                status: "error",
                title: "Error",
                description: message,
            }));

        data &&
            (await dispatch({
                type: "FETCH_MANDATE_STASH_HISTORY_SUCCESS",
                payload: {
                    mandateId: mandateId,
                    data,
                },
            }));
    };

export const { Context, Provider } = createDataContext(
    reducer,
    {
        getUserMandates,
        getMandateDetails,
        getMandateHistory,
        addMandateToState,
        editMandateStatus,
        editMandateInState,
    },
    {
        mandates: [],
        mandateDetails: {},
    }
);
