import React from "react";
import { Box, Image } from "@chakra-ui/react";
import LoaderGif from "../../assets/images/mystash.gif";

const PageLoader = () => {
  return (
    <Box 
      zIndex="80000" 
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Image src={LoaderGif} />
    </Box>
  );
};

export default PageLoader;
