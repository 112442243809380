/* eslint-disable  */
import React, { useContext, useEffect, useState } from "react";
import { HStack, VStack } from "@chakra-ui/layout";
import {
    Box,
    Text,
    Image,
    Badge,
    useToast,
    Spacer,
    Editable,
    EditableInput,
    EditablePreview,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import HandWMoney from "../../assets/svgs/hand-w-money.svg";
import { Context as NotificationsContext } from "../../contexts/notificationsContext";

import UserIcon from "../../assets/svgs/dashboard-user-icon.svg";
import NotificationsIcon from "../../assets/svgs/notification-bell 2.svg";
import SavingsIcon from "../../assets/svgs/savings-icon.svg";
import SavingsActiveIcon from "../../assets/svgs/savings-active-icon.svg";
import SettingsActiveIcon from "../../assets/svgs/active-settings-icon.svg";
import SettingsIcon from "../../assets/svgs/settings-icon.svg";
import { FaArrowLeft } from "react-icons/fa";
import { EditableControls } from "../PageNavigator/EditableControls";

const TopNavigation = ({ userName, hasPageNavigator, pageHeader }) => {
    const [activePath, setActivePath] = useState("home");
    const router = useHistory();
    const toast = useToast();

    const handleMenuClick = (menuItem) => {
        setActivePath(menuItem);
        if (menuItem === "home") {
            return router.push("/dashboard");
        } else return router.push("/settings");
    };

    const formatUserName = (name) => {
        const splitNames = name?.split(" ");
        return splitNames && splitNames[0];
    };

    const {
        state: { notifications },
        getUserNotifications,
    } = useContext(NotificationsContext);

    useEffect(() => {
        getUserNotifications(notifications, toast);
    }, []);

    useEffect(() => {
        if (router?.location?.pathname === "/settings") {
            return setActivePath("settings");
        } else if (router?.location?.pathname === "/dashboard") {
            return setActivePath("home");
        }
        return setActivePath("no");
    }, [router?.location?.pathname]);

    return (
        <>
            <HStack
                zIndex="100"
                boxShadow="sm"
                p="4"
                position="sticky"
                top="0%"
                // top={hasPageNavigator ? "2.5rem" : "1.2rem"}
                w={["100%", "600px", "500px"]}
                m="auto"
                display={{ base: "none", md: "flex", lg: "flex" }}
                bg="brand.white"
                borderTopRightRadius="10px"
                borderTopLeftRadius="10px"
            >
                <HStack flex="5">
                    <Box cursor="pointer" flex="1">
                        {router?.location?.pathname === "/dashboard" ? (
                            <Image
                                onClick={() =>
                                    router.push("/settings/edit-profile")
                                }
                                src={UserIcon}
                            />
                        ) : (
                            <FaArrowLeft onClick={() => router.goBack()} />
                        )}
                    </Box>
                    <Box
                        flex="3"
                        textTransform="capitalize"
                        textAlign={
                            router?.pathname === "/dashboard"
                                ? "center"
                                : "left"
                        }
                    >
                        {router?.pathname === "/dashboard" ? (
                            <Text textAlign="left">
                                Hello,
                                <br />
                                {formatUserName(userName)}
                            </Text>
                        ) : (
                            <>
                                {pageHeader?.isEditable ? (
                                    <Editable
                                        d="flex"
                                        isDisabled={!pageHeader?.isEditable}
                                        defaultValue={pageHeader?.title}
                                        fontSize="0.8rem"
                                    >
                                        <EditablePreview />
                                        <HStack spacing="5">
                                            <EditableInput
                                                _focus={{
                                                    outline: "none",
                                                    border: "none",
                                                    background:
                                                        "brand.lightYellow",
                                                    padding: "2px",
                                                }}
                                                onChange={(e) => {
                                                    sessionStorage.setItem(
                                                        "pageTitle",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <EditableControls smaller />
                                        </HStack>
                                    </Editable>
                                ) : (
                                    <HStack mt="2">
                                        {pageHeader?.hasIconLeft ? (
                                            <Image
                                                flex="1"
                                                height="1.5rem"
                                                src={HandWMoney}
                                            />
                                        ) : null}{" "}
                                        <Text
                                            fontSize={
                                                pageHeader?.hasIconLeft
                                                    ? "0.6rem"
                                                    : "0.75rem"
                                            }
                                            flex="13"
                                        >
                                            {" "}
                                            {pageHeader?.title}{" "}
                                        </Text>
                                    </HStack>
                                )}
                            </>
                        )}
                    </Box>
                </HStack>
                <Spacer />
                <HStack flex="1">
                    <VStack
                        textAlign="center"
                        cursor="pointer"
                        onClick={() => handleMenuClick("home")}
                    >
                        <Box>
                            <Image
                                h="1.2rem"
                                w="full"
                                src={
                                    activePath === "home"
                                        ? SavingsActiveIcon
                                        : SavingsIcon
                                }
                            />
                        </Box>
                        <Text
                            textAlign="center"
                            fontSize="0.8rem"
                            color={
                                activePath === "home" ? "brand.primary" : "#000"
                            }
                        >
                            Savings
                        </Text>
                    </VStack>
                    <Spacer />
                    <VStack textAlign="center" cursor="pointer">
                        <Box
                            position="relative"
                            onClick={() => router.push("/notifications")}
                            cursor="pointer"
                        >
                            <Badge
                                borderRadius="100%"
                                position="absolute"
                                right="0"
                                fontSize="0.5rem"
                                colorScheme="brandPrimaryTheme"
                                color="brand.white"
                            >
                                {notifications?.unread?.length}
                            </Badge>
                            <Image
                                h="1.2rem"
                                w="full"
                                src={NotificationsIcon}
                            />{" "}
                        </Box>
                        <Text textAlign="center" fontSize="0.8rem">
                            Notification
                        </Text>
                    </VStack>
                    <Spacer />
                    <VStack
                        textAlign="center"
                        cursor="pointer"
                        onClick={() => handleMenuClick("settings")}
                    >
                        <Box cursor="pointer">
                            <Image
                                h="1.2rem"
                                w="full"
                                src={
                                    activePath === "settings"
                                        ? SettingsActiveIcon
                                        : SettingsIcon
                                }
                            />
                        </Box>
                        <Text
                            textAlign="center"
                            fontSize="0.8rem"
                            color={
                                activePath === "settings" ? "brand.primary" : ""
                            }
                        >
                            Settings
                        </Text>
                    </VStack>
                </HStack>
            </HStack>
        </>
    );
};
TopNavigation.propTypes = {
    userName: PropTypes.string,
};

export default TopNavigation;
