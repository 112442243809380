import React, { lazy } from "react";
import SelectCard from "../../components/SelectCard";

const PostOnboardingComponent = lazy(() =>
  import("../../components/PostOnboarding/PostOnboardingComponent")
);
const AppMenu = lazy(() => import("../../components/AppMenu"));
const FlowCalculator = lazy(() => import("../../components/FlowCalculator"));
const PreCardLink = lazy(() => import("../../components/PreCardLink"));
const SuccessComponent = lazy(() =>
  import("../../components/SuccessComponent")
);
const CongratMigrant = lazy(() =>
  import("../../components/SuccessComponent/CongratMigrant")
);
const MatureMandateCongrats = lazy(() =>
  import("../../components/SuccessComponent/MatureMandateCongrats")
);

const SelectAccount = lazy(() => import("../../components/SelectAccount"));
const Error500 = lazy(() => import("../../components/Errors/error500"));
const Error404 = lazy(() => import("../../components/Errors/error404"));
const CustomError = lazy(() => import("../../components/Errors/customError"));
const ChangeSavingLimit = lazy(() =>
  import("../../components/ChangeSavingLimit")
);
const ConfirmBreakflow = lazy(() =>
  import("../../components/ConfirmBreakflow")
);
const FeedbackFormComponent = lazy(() => import("../../components/FeedbackForm/"))
const ManageMandateDrawer = lazy(() => import("../../components/ManageMandateDrawer"));
const VoucherComponent = lazy(() => import("../../components/VoucherComponent"));

export const renderDrawerChild = ({ modalId, data }, setDrawer) => {
  switch (modalId) {
    case "POST_ONBOARDING":
      return <PostOnboardingComponent setDrawer={setDrawer} />;
    case "APP_MENU":
      return <AppMenu setDrawer={setDrawer} />;
    case "FLOW_CALCULATOR":
      return <FlowCalculator setDrawer={setDrawer} />;
    case "PRE_CARDLINK":
      return <PreCardLink setDrawer={setDrawer} data={data} />;
    case "SUCCESS_COMPONENT":
      return <SuccessComponent setDrawer={setDrawer} data={data} />;
    case "SELECT_ACCOUNT":
      return <SelectAccount setDrawer={setDrawer} />;
    case "SELECT_CARD":
      return <SelectCard setDrawer={setDrawer} data={data} />;
    case "ERROR_500":
      return <Error500 setDrawer={setDrawer} />;
    case "ERROR_404":
      return <Error404 setDrawer={setDrawer} data={data} />;
    case "CUSTOM_ERROR":
      return <CustomError setDrawer={setDrawer} data={data} />;
    case "CHANGE_SAVE_LIMIT":
      return <ChangeSavingLimit setDrawer={setDrawer} data={data} />;
    case "CONFIRM_BREAK_FLOW":
      return <ConfirmBreakflow setDrawer={setDrawer} data={data} />;
    case "CONGRAT_MIGRANT":
      return <CongratMigrant setDrawer={setDrawer} data={data} />;
    case "FEEDBACK_FORM":
      return <FeedbackFormComponent data={data} setDrawer={setDrawer} />;
    case "MANAGE_MANDATE":
      return <ManageMandateDrawer setDrawer={setDrawer} data={data} />;
    case "VOUCHER_COMPONENT":
        return <VoucherComponent setDrawer={setDrawer} data={data} />;
    case "MATURE_MANDATE":
        return <MatureMandateCongrats setDrawer={setDrawer} data={data} />;
        
    default:
      return <Error404 setDrawer={setDrawer} />;
  }
};
