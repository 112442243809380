import createDataContext from "./createContext";
import { fetchUserAccounts } from "../util/operations/account";
import { toastDefaults } from "../util/constants";

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_ACCOUNTS_SUCCESS":
            return {
                ...state,
                accounts: action.payload,
            };
        case "ADD_NEW_ACCOUNTS_SUCCESS":
            return {
                ...state,
                accounts: [...state.accounts, action.payload],
            };

        default:
            return state;
    }
};

const addAccountToState = (dispatch) => (newAccount) => {
    return dispatch({
        type: "ADD_NEW_ACCOUNTS_SUCCESS",
        payload: newAccount,
    });
};

const getUserAccounts = (dispatch) => async (accounts, toast) => {
    if (accounts && accounts.length > 0) {
        return true;
    }
    const { status, data, message } = await fetchUserAccounts();

    !status &&
        (await toast({
            ...toastDefaults,
            status: "error",
            title: "Error",
            description: message,
        }));

    data &&
        (await dispatch({
            type: "FETCH_ACCOUNTS_SUCCESS",
            payload: data,
        }));
};

export const { Context, Provider } = createDataContext(
    reducer,
    {
        getUserAccounts,
        addAccountToState,
    },
    {
        accounts: [],
    }
);
