import { apiRequest } from "../apiRequest";

export const linkAccountWithMono = (code) => {
    return apiRequest(`/bankAccount/link/withMono`, "POST", { code });
};

export const linkAccountWithOkra = (okraData) => {
    return apiRequest(`/bankAccount/link/withOkra`, "POST", {
        ...okraData,
    });
};

export const linkAccountWithOkraV2 = (okraData) => {
    return apiRequest(`/bankAccount/link/withOkra`, "POST", okraData);
};

export const fetchUserAccounts = () => {
    return apiRequest(`/bankAccount/fetch`, "GET");
};

export const initiateReauth = (mono_id) => {
    return apiRequest(`/account/mono/${mono_id}/initiate_reauth`, "POST");
};

export const fetchExchangeRates = () => {
    return apiRequest(`/payment/fetch/exchange-rates`, "GET");
};
