import React from "react";
import { Box, VStack } from "@chakra-ui/react";
import FAQItem from "./FAQItem";
import { getFaqQuestionsOrder } from "./helper";
import PropTypes from "prop-types";
import PageLoader from "../PageLoader";

// eslint-disable-next-line react/prop-types
const FAQList = ({ topFaqCategory, faqQuestions }) => {
    if (!faqQuestions) {
        return <PageLoader />;
    }
    return (
        <>
            <Box w="100%">
                <VStack w="100%">
                    {getFaqQuestionsOrder(topFaqCategory, faqQuestions).map(
                        (faqItem) => (
                            <FAQItem faqItem={faqItem} key={faqItem.id} />
                        )
                    )}
                </VStack>
            </Box>
        </>
    );
};

FAQList.propTypes = {
    topFaqCategory: PropTypes.string,
};

export default FAQList;
