import { extendTheme } from "@chakra-ui/react";

const Button = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: "bold",
    borderRadius: "12px",
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: "sm",
      px: 4,
      py: 3,
    },
    md: {
      fontSize: "md",
      px: 6,
      py: 4,
    },
    lg: {
      fontSize: "lg",
      px: 16,
      py: 6,
    },
  },
  // Two variants: outline and solid
  variants: {
    outline: {
      border: "2px solid",
      borderColor: "purple.500",
      color: "purple.500",
    },
    light: {
      bg: "#FFF6FF",
      fontWeight: "normal",
      borderRadius: "8px",
      color: "#961BA9",
      boxShadow: "none",
    },
    solid: {
      bg: "#961BA9",
      color: "white",
      boxShadow: "0px 2px 4px rgba(15, 218, 137, 0.3)",
    },
    white: {
      bg: "white",
      color: "#961BA9",
      boxShadow: "none",
    },
    gold: {
      bg: "#FFE071",
      color: "#961BA9",
      boxShadow: "none",
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "lg",
    variant: "solid",
  },
};

const Switch = {
  variants: {
    primary: {
      color: "#961BA9",
      background: "#961BA9",
      colorTheme: "#961BA9",
    },
  },
};

export const theme = extendTheme({
  fonts: {
    heading: "Open Sans",
    body: "Open Sans",
  },
  colors: {
    brand: {
      primary: "#961BA9",
      secondary: "#FFE071",
      pink: "#FEDCFF",
      yellow: "#FFE071",
      lightYellow: "#FFF9ED",
      lightBlue: "#EDFCFF",
      lightGreen: "#EDFFF1",
      lightPrimary: "#FFEFFF",
      lightGrey: "#EDEDFF",
      lightGreyII: "#D1D1D6",
      lightPrimaryButton: "#FFF6FF",
      textLightGey: "#96A7AF",
      white: "#fff",
      dark: "#555555",
      black: "#000000",
      darkGrey: "#979797",
      success: "#4C9721",
      error: "#C32727",
      info: "#961BA9",
      flexBg: "#E5E5E5",
      blue: "#3B88C3",
      primaryGradient:
        "linear-gradient(155.54deg, #88039D -6.1%, #FFAC33 76.83%)",
      greyBorderBottom: " #97979745",
      textGrey: "#7B7B7B",
      purpleBg: "#FDEDFE",
      purpleText: "#F6BAFF",
    },
    brandPrimaryTheme: {
      100: "#961BA9",
      200: "#961BA9",
      300: "#961BA9",
      400: "#961BA9",
      500: "#961BA9",
    },
  },
  components: { Button, Switch },
});
