import { markNotificationAsRead } from "../../util/operations/notifications";

export const handleMarkAsRead = async (
  notificationId,
  setIsLoading,
  notifications,
  readUserNotification
) => {
  setIsLoading(true);
  const { status } = await markNotificationAsRead(notificationId);

  if (status) {
    readUserNotification(notificationId, notifications);
  }
  return setIsLoading(false);
};
