import { apiRequest } from "../apiRequest";

export const verifyUserEmail = (token) => {
    return apiRequest(`/users/email/verify`, "POST", { token });
};

export const setPassCode = (passcode) => {
    return apiRequest(`/users/set/passcode`, "POST", { passCode: passcode });
};

export const setUserLimit = (limit) => {
    return apiRequest(`/users/saving_limit`, "POST", { limit });
};

export const editUserData = (userData) => {
    return apiRequest(`/users/profile/edit`, "PATCH", userData);
};

export const authorizePwdReset = (token) => {
    return apiRequest(`/users/pwd/recovery/authorize`, "POST", { token });
};

export const finalResetPassword = (data) => {
    return apiRequest(`/users/pwd/recovery/finalize`, "POST", data);
};

export const initiateResetPassword = (data) => {
    return apiRequest(`/auth/password/reset/initiate`, "POST", data);
};

export const finalizeResetPassword = (data) => {
    return apiRequest(`/auth/password/reset/complete`, "POST", data);
};

export const resendVerificationEmail = (token) => {
    return apiRequest(
        `/auth/verification-email/send/auth0`,
        "POST",
        null,
        null,
        null,
        token
    );
};
