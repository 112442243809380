import React from "react";
import PropTypes from "prop-types";
import { VStack, Text, Box, HStack } from "@chakra-ui/layout";
import { Link } from "react-router-dom";

const AuthFooter = ({ authType }) => {
  if (authType === "login") {
    return (
      <>
        <VStack marginBottom="10" spacing="3">
          <Box cursor="pointer">
            <Text fontSize="0.8rem" color="brand.primary">
              <Link to="/user/forgot-password"> Forgot Password ?</Link>{" "}
            </Text>
          </Box>
          <Box cursor="pointer">
            <Text fontSize="0.8rem" color="brand.primary">
              <Link to="/user/register"> Sign Up</Link>{" "}
            </Text>
          </Box>
        </VStack>
      </>
    );
  }
  if (authType === "register") {
    return (
      <>
        {" "}
        <VStack pb="5" marginBottom="10" spacing="3">
          <HStack>
            <Text fontSize="0.8rem" color="brand.textLightGrey">
              {" "}
              Already have an account ?
            </Text>
            <Text fontSize="0.8rem" cursor="pointer" color="brand.primary">
              <Link to="/user/login"> Sign In </Link>{" "}
            </Text>
          </HStack>
        </VStack>
      </>
    );
  }
  if (authType === "forgot-password") {
    return (
      <>
        {" "}
        <VStack marginBottom="10" spacing="3">
          <Box cursor="pointer">
            <Text color="brand.primary">
              <Link to="/user/register"> Sign up </Link>{" "}
            </Text>
          </Box>
          <HStack>
            <Text color="brand.textLightGrey"> Already have an account ?</Text>
            <Text cursor="pointer" color="brand.primary">
              <Link to="/user/login"> Sign In </Link>{" "}
            </Text>
          </HStack>
        </VStack>
      </>
    );
  }
};

AuthFooter.propTypes = {
  authType: PropTypes.string,
};

export default AuthFooter;
