export const initializePaystack = ({
    user,
    data,
    amountInNaira,
    verifyPayment,
    channels,
}) => {
    const uid = user?.uid;
    const email = user?.email;
    const phoneNumber = user?.phoneNumber;
    const name = user?.name;

    const randomNumber = (Math.random() * 100000).toFixed(0);
    const dateTime = new Date().getTime().toString();
    const reference = `PSR${randomNumber}${uid}${dateTime}`;
    const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;

    const config = {
        reference,
        email,
        amount: amountInNaira * 100, // convert to kobo
        firstname: name,
        phone: phoneNumber,
        publicKey: publicKey?.toString(),
        channels,
    };

    const onSuccess = (response) => {
        return verifyPayment({response, data});
    };

    const onClose = (response) => {
        console.log(response);
    };

    return { config, onSuccess, onClose };
};
