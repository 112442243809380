import React from "react";
import { Box, Center, Spinner } from "@chakra-ui/react";
import AnnouncementSlider from "./AnnouncementSlider";
import PropTypes from 'prop-types'

const AnnoucementSection = ({
  announcementsLoading,
  announcements,
  readUserAnnouncement,
}) => {
  return (
    <>
      {announcementsLoading ? (
        <>
          <Center py="10">
            <Spinner color="brand.primary" />
          </Center>
        </>
      ) : (
        <>
          {announcements?.length > 0 ? (
            <Box position="relative" height={{ base: "10vh", }} w="90%" m="auto">
              <AnnouncementSlider
                readUserAnnouncement={readUserAnnouncement}
                announcements={announcements || []}
              />
            </Box>
          ) : null}
        </>
      )}
    </>
  );
};

AnnoucementSection.propTypes = {
  announcementsLoading: PropTypes.bool,
  announcements: PropTypes.array,
  readUserAnnouncement: PropTypes.func,
}

export default AnnoucementSection;
