import React, { useState } from "react";
import { useRouter } from "../../../util/router";
import { Box, HStack, Text, Image, Button, Center } from "@chakra-ui/react";
import HourGlass from "../../../assets/svgs/hour-glass.svg";
import MoneyMouth from "../../../assets/svgs/money-mouth-emoji.svg";
import {
    formatMoney,
    renderMandateTimeLeft,
    getSavingsType,
    renderNaira,
} from "../../../util/helper-functions";
import { initialize } from "../../../util/payment-helper";
import { usePaystackPayment } from "react-paystack";
import { useAuth } from "../../../util/auth";
import PropTypes from "prop-types";
import EMPLOYEE_PERKBG from "../../../assets/images/employee-perk-bg.png";

const employeeeUI = {
    bgImage: EMPLOYEE_PERKBG,
    bgRepeat: "no-repeat",
    backgroundSize: "cover",
};

const MandateItem = ({ mandate, setDrawer }) => {
    const router = useRouter();
    const isEmployeePerk = mandate?.type === "employee-perk";
    const {
        type,
        maturityDate,
        mandateId,
        account_id,
        amount,
        active,
        title,
        // broken,
        balance,
        interest,
        dailyInterestRate,
        frequency,
    } = mandate;
    const timeLeft = renderMandateTimeLeft(maturityDate);
    const [isLoading, setIsLoading] = useState(false);

    const { user } = useAuth();

    const { config, onSuccess, onClose } = initialize({
        setDrawer,
        user,
        type: "ACTIVATE_MANDATE",
        data: {
            mandateId,
            accountId: account_id,
            amountInNaira: 50,
        },
        setIsLoading,
        router,
    });

    const initializePayment = usePaystackPayment(config);

    const handleMandateActivation = async ({ onSuccess, onClose }) => {
        initializePayment(onSuccess, onClose);
    };

    return (
        <Box
            cursor={"pointer"}
            onClick={() => router.push(`/mandates/${mandateId}`)}
            borderRadius="10px"
            w="100%"
            py="5"
            px="2"
            shadow="md"
            {...(isEmployeePerk ? employeeeUI : { bg: "brand.white" })}

            // filter={broken ? "grayScale(1)" : ""}
        >
            <HStack justifyItems="center" w="100%" spacing="2">
                <Box flex="1">
                    <Text
                        color="brand.textLightGey"
                        display="block"
                        fontSize="0.6rem"
                        marginBottom="0.5rem"
                    >
                        {title}
                    </Text>
                    <Text>
                        {" "}
                        {isEmployeePerk ? "$" : "₦"}
                        {formatMoney(renderNaira(balance))}
                    </Text>
                </Box>
                <Box flex="1">
                    <Text
                        mt="1rem"
                        fontSize="0.8rem"
                        color="brand.textLightGey"
                    >
                        {type === "fixed-amount" ? "₦" : ""}
                        {formatMoney(amount)}
                        {type === "fixed-amount"
                            ? ` ${frequency || ""}`
                            : `%/ ${getSavingsType(type)}`}
                    </Text>
                    <Text
                        fontSize="0.6rem"
                        ml="3"
                        color={type === "says" ? "brand.primary" : "brand.blue"}
                    >
                        +₦{Math.floor((interest / 100) * 10) / 10 || 0}(
                        {Math.floor(dailyInterestRate * 10) / 10 || 0}%)
                    </Text>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    height="30px"
                    flex="1"
                    textAlign="center"
                >
                    {active ? (
                        <>
                            <Image
                                marginBottom="2"
                                height={"1.5rem"}
                                src={
                                    // broken
                                    //   ? BrokenGlass
                                    //   :
                                    timeLeft?.toLowerCase() === "cycle complete"
                                        ? MoneyMouth
                                        : HourGlass
                                }
                            />
                            <Text fontSize="0.6rem">
                                {/* {broken ? "Broken" :  */}
                                {isEmployeePerk
                                    ? `Till ${new Date(maturityDate)
                                          .toDateString()
                                          ?.slice(4)}`
                                    : timeLeft}
                            </Text>
                        </>
                    ) : (
                        <>
                            {
                                // broken
                                active ? (
                                    <>
                                        <Image
                                            marginBottom="2"
                                            height={"0.4rem"}
                                            src={
                                                // broken
                                                //   ? BrokenGlass
                                                //   :
                                                timeLeft?.toLowerCase() ===
                                                "cycle complete"
                                                    ? MoneyMouth
                                                    : HourGlass
                                            }
                                        />
                                        <Text fontSize="0.6rem">
                                            {
                                                // broken ? "Broken" :
                                                timeLeft
                                            }
                                        </Text>
                                    </>
                                ) : (
                                    <Center py="3" direction="vertical">
                                        <Button
                                            isLoading={isLoading}
                                            onClick={() =>
                                                handleMandateActivation({
                                                    onSuccess,
                                                    onClose,
                                                })
                                            }
                                            _hover={{
                                                background: "brand.primary",
                                                shadow: "md",
                                            }}
                                            _active={{
                                                shadow: "md",
                                                transform: "translateY(.1rem)",
                                            }}
                                            borderRadius="8px"
                                            px="5"
                                            size="xs"
                                        >
                                            {" "}
                                            Activate
                                        </Button>
                                    </Center>
                                )
                            }
                        </>
                    )}
                </Box>
            </HStack>
        </Box>
    );
};

MandateItem.propTypes = {
    setDrawer: PropTypes.func,
    mandate: PropTypes.shape({
        maturityDate: PropTypes.string,
        type: PropTypes.string.isRequired,
        mandateId: PropTypes.string.isRequired,
        account_id: PropTypes.string.isRequired,
        amount: PropTypes.number,
        title: PropTypes.string,
        interest: PropTypes.number,
        active: PropTypes.bool,
        balance: PropTypes.number,
        dailyInterestRate: PropTypes.number,
        frequency: PropTypes.string,
    }),
};

export default MandateItem;
