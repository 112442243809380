import { Box, Flex, Spacer } from "@chakra-ui/react";
// import MyStash from "../../assets/svgs/logo.svg";
import { useAuth } from "../../util/auth";
import { useHistory } from "react-router-dom";
import React from "react";
import NavigationLogo from "./NavigationLogo";
import NavigationText from "./NavigationText";

const NavigationBar = () => {
  const auth = useAuth();
  const router = useHistory();

  return (
    <Box
      display={{ base: "none", md: "block", lg: "block" }}
      py="8"
      bg="#FFEFFF"
      position="fixed"
      width="full"
      zIndex="100"
    >
      <Flex as="nav" alignItems="center" justifyContent="space-around">
        <NavigationLogo />
        <Spacer />
        <Box mr={20}>
          <Flex justifyContent="space-between">
            <NavigationText onClick={() => router.push("/help")}>
              FAQ
            </NavigationText>
            {auth?.user ? (
              <NavigationText onClick={() => auth?.logout()}>
                Logout
              </NavigationText>
            ) : (
              <NavigationText
                onClick={() => router.push("/user/login")}
              >
                Login{" "}
              </NavigationText>
            )}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default NavigationBar;
