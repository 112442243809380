import { apiRequest } from "../apiRequest";

export const fetchCashReserveBalance = () => {
    return apiRequest(`/stash/fetch`, "GET");
};

export const fetchCashReserveHistory = () => {
    return apiRequest(
        "/accounting/user/cash-reserve/history/?sortBy=createdAt&sortType=desc",
        "GET"
    );
};

export const fundCashReserve = (stashData) => {
    return apiRequest(`/cashReserve/fund`, "POST", stashData);
};

export const withdrawFromCashReserve = (stashData) => {
    return apiRequest(`/cashReserve/withdraw/initiate`, "POST", stashData);
};
