import {
    VStack,
    Text,
    useToast,
    Input,
    InputGroup,
    InputRightElement,
    Image,
    UnorderedList,
    ListItem,
    Box,
    HStack,
    Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import MonoConnect from "@mono.co/connect.js";
import { handleConnectWithMono, handleSearchBankList } from "./helper";
import { useHistory } from "react-router-dom";
import bankList from "../../util/bank-list";
import SEARCH_ICON from "../../assets/svgs/search-bank.svg";
import PropTypes from "prop-types";
const SearchBank = ({ setOnboardingStep, onboarding, setNoBank }) => {
    const toast = useToast();
    const router = useHistory();

    const [currentBankList, setCurrentBankList] = useState(bankList);
    const [searchText, setSearchText] = useState();
    const [bankIsLoading, setBankIsLoading] = useState(false);

    useEffect(() => {
        handleSearchBankList(searchText, bankList, setCurrentBankList);
    }, [searchText]);

    const monoConnect = React.useMemo(() => {
        const monoInstance = new MonoConnect({
            onSuccess: ({ code }) =>
                handleConnectWithMono(
                    code,
                    toast,
                    setOnboardingStep,
                    router,
                    onboarding,
                    setBankIsLoading
                ),
            key: process.env.REACT_APP_MONO_PUBLIC_KEY,
        });

        monoInstance.setup();

        return monoInstance;
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <VStack
                transform={{ base: "translateY(0)", md: "translateY(-3rem)" }}
                py={{ base: "10", md: "0" }}
                spacing={{ base: "7", md: "5" }}
                w="100%"
                m="auto"
            >
                <Box w="85%" m="auto">
                    <Text fontSize="1.3rem" color="brand.primary">
                        {" "}
                        Is your bank on the list
                    </Text>
                </Box>
                <Box borderRadius="10px" shadow="md" w="85%" m="auto">
                    <InputGroup>
                        <Input
                            border="none"
                            borderRadius="10px"
                            _focus={{
                                outline: "none",
                            }}
                            onKeyUp={({ target: { value } }) =>
                                setSearchText(value ? value : undefined)
                            }
                        />
                        {/*eslint-disable-next-line react/no-children-prop */}
                        <InputRightElement
                            children={<Image src={SEARCH_ICON} />}
                        />
                    </InputGroup>
                </Box>
                <Box
                    css={{
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                    h={{ base: "50vh", md: "55vh" }}
                    overflow="scroll"
                    w="100%"
                    pb="50"
                >
                    <UnorderedList
                        w="100%"
                        p="0"
                        m="0"
                        spacing="1"
                        styleType="none"
                        listStyle="none"
                    >
                        {currentBankList?.map((bank) => (
                            <ListItem
                                key={bank.id}
                                borderTop="1px solid"
                                borderBottom="1px solid"
                                borderLeft="5px solid"
                                borderTopColor="#cccccc42"
                                borderBottomColor="#cccccc42"
                                borderLeftColor={bank.color}
                                p="3"
                                color="brand.textGrey"
                            >
                                {bank.bank_name}
                            </ListItem>
                        ))}
                    </UnorderedList>
                </Box>

                <HStack w="85%" m="auto" bg="transparent">
                    <Button
                        py={{ md: "7", base: "1rem" }}
                        isLoading={bankIsLoading}
                        variant="outline"
                        onClick={() =>
                            onboarding ? setOnboardingStep(5) : setNoBank(true)
                        }
                    >
                        No
                    </Button>
                    <Button
                        py={{ md: "7", base: "1rem" }}
                        isLoading={bankIsLoading}
                        _hover={{
                            background: "brand.primary",
                            shadow: "md",
                        }}
                        _active={{
                            shadow: "md",
                            transform: "translateY(.1rem)",
                        }}
                        onClick={() => monoConnect.open()}
                    >
                        Yes
                    </Button>
                </HStack>
            </VStack>
        </>
    );
};

SearchBank.propTypes = {
    onboarding: PropTypes.bool,
    setOnboardingStep: PropTypes.func,
    setNoBank: PropTypes.func,
};

export default SearchBank;
