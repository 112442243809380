import { formatMoney, renderNaira } from "../../util/helper-functions";
import { verifyDebit } from "../../util/operations/debits";

export const handleStashVerification = async ( 
  ref,
  setDrawer,
  router,
  updateUserStat
) => {
  const { status, data, message } = await verifyDebit({
    ref,
  });

  if (status) {
    const {
      data: { amount, already_updated },
    } = data;

    if (!already_updated) {
      updateUserStat(amount, "totalFlexBalance");
    }

    router.push("/dashboard");

    return setDrawer({
      isOpen: true,
      modalId: "SUCCESS_COMPONENT",
      isDark: false,
      showBitMoji: false,
      data: {
        name: data?.data?.account_name,
        bankName: data?.data?.bank,
        accountNumber: data?.data?.account_number,
        amount: formatMoney(renderNaira(amount)),
      },
    });
  }
  router.push("/dashboard");

  return setDrawer({
    isOpen: true,
    modalId: "CUSTOM_ERROR",
    isDark: false,
    showBitMoji: false,
    data: {
      message: message,
    },
  });
};
