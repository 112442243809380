import React, { useEffect } from "react";
import PageLoader from "../../components/PageLoader";
import { useRouter } from "../../util/router";
import queryString from "query-string";
import { handleStashVerification } from "./helper";
import { useAuth } from "../../util/auth";
import PropTypes from 'prop-types'

const VerifyStash = (props) => {
  const router = useRouter();

  const { updateUserStat } = useAuth();

  const {
    location: { search },
  } = props;

  useEffect(() => {
    if (!search) {
      return router.replace("/dashboard");
    }
    const parsedQueryString = queryString.parse(search);
    const { reference } = parsedQueryString;

    return handleStashVerification(
      reference,
      props.setDrawer,
      router,
      updateUserStat
    );

    // eslint-disable-next-line
  }, [search]);
  return (
    <>
      <PageLoader />
    </>
  );
};

VerifyStash.propTypes = {
  location: PropTypes.object,
  setDrawer: PropTypes.func
}

export default VerifyStash;
