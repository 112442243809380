import React, { lazy, Suspense, useState } from "react";
import { Switch, Route } from "../../util/router";
import PageNavigator from "../../components/PageNavigator";
import TopNavigation from "../../components/TopNavigation";
import { requireAuth, useAuth } from "../../util/auth";
import PageLoader from "../../components/PageLoader";
import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";
import WithdrawPerk from "../../mandates-pages/withdraw-perk";

const CreateMandate = lazy(() => import("../../mandates-pages/create-mandate"));
const MandatesList = lazy(() => import("../../mandates-pages/mandates-list"));
const SingleMandate = lazy(() => import("../../mandates-pages/single-mandate"));
const NotFoundPage = lazy(() => import("../not-found"));
const VerifyMandateCreation = lazy(() => import("../../mandates-pages/verify"));
const MandatesRefund = lazy(() =>
    import("../../mandates-pages/mandates-refund")
);
const CreateExemptions = lazy(() =>
    import("../../mandates-pages/create-exemptions")
);

const MandatesPage = ({ setDrawer }) => {
    const [pageHeader, setPageHeader] = useState({
        title: "",
        isEditable: false,
    });
    const { user } = useAuth();

    return (
        <Box
            overflowY="scroll"
            w={["100%", "600px", "500px"]}
            paddingTop={{ md: "0" }}
            h="full"
            css={{
                "&::-webkit-scrollbar": {
                    display: "none",
                },
            }}
        >
            <TopNavigation
                pageHeader={pageHeader}
                hasPageNavigator
                userName={user?.name}
            />

            <PageNavigator pageHeader={pageHeader} support>
                <Box
                    css={{
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                    h="90vh"
                    overflowY="scroll"
                >
                    <Suspense fallback={<PageLoader />}>
                        <Switch>
                            <Route
                                exact
                                path={"/mandates/:mandateId/exemptions"}
                                render={(routeProps) => (
                                    <CreateExemptions
                                        setPageHeader={setPageHeader}
                                        {...routeProps}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={"/mandates/perk/move/:mandateId"}
                                render={(routeProps) => (
                                    <WithdrawPerk
                                        setDrawer={setDrawer}
                                        setPageHeader={setPageHeader}
                                        {...routeProps}
                                    />
                                )}
                            />

                            <Route
                                exact
                                path={"/mandates/create"}
                                render={(routeProps) => (
                                    <CreateMandate
                                        setDrawer={setDrawer}
                                        setPageHeader={setPageHeader}
                                        {...routeProps}
                                    />
                                )}
                            />

                            <Route
                                path={"/mandates/:mandateId/edit"}
                                exact
                                render={(routeProps) => (
                                    <CreateMandate
                                        setPageHeader={setPageHeader}
                                        setDrawer={setDrawer}
                                        edit
                                        {...routeProps}
                                    />
                                )}
                            />

                            <Route
                                exact
                                path={"/mandates/verify"}
                                render={(routeProps) => (
                                    <VerifyMandateCreation
                                        setDrawer={setDrawer}
                                        setPageHeader={setPageHeader}
                                        {...routeProps}
                                    />
                                )}
                            />

                            <Route
                                path={"/mandates/refund/:mandateId"}
                                exact
                                render={(routeProps) => (
                                    <MandatesRefund
                                        setDrawer={setDrawer}
                                        setPageHeader={setPageHeader}
                                        {...routeProps}
                                    />
                                )}
                            />

                            <Route
                                path={"/mandates/:mandateId"}
                                exact
                                render={(routeProps) => (
                                    <SingleMandate
                                        setDrawer={setDrawer}
                                        setPageHeader={setPageHeader}
                                        {...routeProps}
                                    />
                                )}
                            />

                            <Route
                                path={"/mandates"}
                                exact
                                render={(routeProps) => (
                                    <MandatesList
                                        setPageHeader={setPageHeader}
                                        {...routeProps}
                                    />
                                )}
                            />

                            <Route
                                path="*"
                                render={(rProps) => (
                                    <NotFoundPage
                                        {...rProps}
                                        setDrawer={setDrawer}
                                    />
                                )}
                            />
                        </Switch>
                    </Suspense>
                </Box>
            </PageNavigator>
        </Box>
    );
};

MandatesPage.propTypes = {
    setDrawer: PropTypes.func,
};

export default requireAuth(MandatesPage);
