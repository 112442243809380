import React from "react";
import SadPeople from "../../assets/svgs/sad-people.svg";
import PointingDownHand from "../../assets/svgs/down-pointing-hand.svg";
import { Box, VStack, Image, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types"

const NoMoney = ({ type, startSaving }) => {
  const router = useHistory();
  return (
    <>
      <Box
        borderRadius="5px"
        overflowY="scroll"
        p="3"
        w="100%"
        bg={startSaving ? "brand.lightGrey" : "brand.white"}
      >
        <VStack>
          <Box>
            <Image margin="auto" src={SadPeople} />
            <Text
              w={startSaving ? "100%" : "60%"}
              m="auto"
              textAlign="center"
              fontSize={startSaving ? "0.8rem" : "1.6rem"}
            >
              {startSaving ? (
                "  Not a single saving like this"
              ) : (
                <>
                  {" "}
                  {type === "stash"
                    ? "No savings so far. Check here to view all your transactions once you start saving"
                    : "Nothing saved yet. Check here to view all your money moves once you start saving"}
                </>
              )}
            </Text>
          </Box>
          {startSaving ? (
            <>
              <Text fontSize="0.8rem" color="brand.primary">
                Start making money moves{" "}
              </Text>
              <Image src={PointingDownHand} />
            </>
          ) : (
            <>
              {type === "mandates" ? (
                <Text
                  onClick={() => router.push("/mandates/create")}
                  cursor="pointer"
                  mt="3"
                  fontSize="0.8rem"
                  color="brand.primary"
                >
                  Make a money move
                </Text>
              ) : (
                <Text
                  onClick={() => router.push("/stash/add")}
                  cursor="pointer"
                  mt="3"
                  fontSize="0.8rem"
                  color="brand.primary"
                >
                  Fund cash reserve
                </Text>
              )}
            </>
          )}
        </VStack>
      </Box>
    </>
  );
};


NoMoney.propTypes = {
  type: PropTypes.string,
  startSaving: PropTypes.bool
}

export default NoMoney;
