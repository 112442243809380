import React from "react";
import NoMoney from "../NoMoney";
import UsersMandatesList from "../UsersMandatesList";
import { Box } from "@chakra-ui/react";
import PropTypes from "prop-types";

const MandatesSection = ({ usersMandates, setDrawer }) => {
  return (
    <>
      {usersMandates && usersMandates.length > 0 ? (
        <UsersMandatesList usersMandates={usersMandates} setDrawer={setDrawer} />
      ) : (
        <Box w="90%">
          <NoMoney startSaving type="mandates" />
        </Box>
      )}
    </>
  );
};

MandatesSection.propTypes = {
  usersMandates: PropTypes.array,
  setDrawer: PropTypes.func,
};

export default MandatesSection;
