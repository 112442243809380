import React, { useState } from "react";
import { GoogleLogin } from "react-google-login";
import { handleGoogleResponse } from "../helper";
import { Image, Skeleton, useToast } from "@chakra-ui/react";
import GoogleIcon from "../../../assets/svgs/google-icon.svg";
import { useAuth } from "../../../util/auth";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

const GoogleLoginComponent = ({ authType }) => {
    const [isLoading, setIsLoading] = useState(false);
    const auth = useAuth();
    const router = useHistory();
    const toast = useToast();

    return (
        <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            render={(renderProps) => {
                return (
                    <Skeleton isLoaded={!isLoading}>
                        <Image
                            _active={{
                                transform: "translateY(.2rem)",
                            }}
                            height="25px"
                            width="auto"
                            onClick={() => {
                                setIsLoading(true);
                                renderProps.onClick();
                            }}
                            cursor="pointer"
                            src={GoogleIcon}
                        />
                    </Skeleton>
                );
            }}
            buttonText="Login"
            onSuccess={(r) =>
                handleGoogleResponse(
                    r,
                    true,
                    authType,
                    auth,
                    toast,
                    setIsLoading,
                    router
                )
            }
            onFailure={(r) =>
                handleGoogleResponse(
                    r,
                    false,
                    authType,
                    auth,
                    toast,
                    setIsLoading,
                    router
                )
            }
            cookiePolicy={"single_host_origin"}
        />
    );
};

GoogleLoginComponent.propTypes = {
    authType: PropTypes.string,
};

export default GoogleLoginComponent;
