import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'

const NavigationText = ({ children, onClick }) => {
  return (
    <Box
      as="a"
      d={{ base: "none", md: "none", lg: "flex" }}
      mr={[0, 3]}
      textStyle="md"
      color="#961BA9"
      onClick={onClick}
      alignItems="center"
      textDecor="none"
      cursor="pointer"
      _hover={{ textDecor: "underline" }}
    >
      {children}
    </Box>
  );
};

NavigationText.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func
}

export default NavigationText