import React from "react";
import { Box, Image, Grid, GridItem, Container } from "@chakra-ui/react";
import { FaArrowLeft } from "react-icons/fa";
import {
    Editable,
    EditableInput,
    EditablePreview,
    Text,
    HStack,
} from "@chakra-ui/react";
import HelpIcon from "../../assets/svgs/faq-icon.svg";
import HandWMoney from "../../assets/svgs/hand-w-money.svg";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { EditableControls } from "./EditableControls";

//deleted setDrawer props
const PageNavigator = ({ support, children, pageHeader }) => {
    const router = useHistory();

    return (
        <Box w={["100%", "600px", "500px"]} overflow="hidden" py={5}>
            <Grid
                display={{ base: "grid", md: "none" }}
                templateColumns="repeat(7, 1fr)"
                gap={3}
                px="5"
                alignItems="center"
            >
                <GridItem w="5">
                    <Box cursor="pointer" marginTop="2">
                        <FaArrowLeft onClick={() => router.goBack()} />
                    </Box>
                </GridItem>
                <GridItem colSpan={5}>
                    {pageHeader?.isEditable ? (
                        <Editable
                            d="flex"
                            isDisabled={!pageHeader?.isEditable}
                            defaultValue={pageHeader?.title}
                        >
                            <EditablePreview />
                            <HStack spacing="5">
                                <EditableInput
                                    _focus={{
                                        outline: "none",
                                        border: "none",
                                        background: "brand.lightYellow",
                                        padding: "2px",
                                    }}
                                    onChange={(e) => {
                                        sessionStorage.setItem(
                                            "pageTitle",
                                            e.target.value
                                        );
                                    }}
                                />
                                <EditableControls />
                            </HStack>
                        </Editable>
                    ) : (
                        <HStack mt="2">
                            {pageHeader?.hasIconLeft ? (
                                <Image height="1.5rem" src={HandWMoney} />
                            ) : null}{" "}
                            <Text
                                fontSize={["0.75rem"]}
                                fontWeight="400"
                                color="#000000"
                            >
                                {" "}
                                {pageHeader?.title}{" "}
                            </Text>
                        </HStack>
                    )}
                </GridItem>
                {support && (
                    <GridItem
                        cursor="pointer"
                        onClick={() =>
                            router.push(
                                `/help?type=${pageHeader?.faqType || "all"}`
                            )
                        }
                        colEnd={10}
                    >
                        <Image src={HelpIcon} />
                    </GridItem>
                )}
            </Grid>
            <Container px={0} py={5}>
                {children}
            </Container>
        </Box>
    );
};

PageNavigator.propTypes = {
    support: PropTypes.bool,
    children: PropTypes.element,
    pageHeader: PropTypes.shape({
        isEditable: PropTypes.bool,
        title: PropTypes.string,
        faqType: PropTypes.string,
        hasIconLeft: PropTypes.string,
    }),
};

export default PageNavigator;
