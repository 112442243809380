export const getFaqQuestionsOrder = (topFaqCategory, faqQuestions) => {
    const topCatQuestions = [];
    const restOfTheWorld = [];
    for (let i = 0; i < faqQuestions?.length; i++) {
        if (faqQuestions[i]?.fields?.category.includes(topFaqCategory)) {
            topCatQuestions.push(faqQuestions[i]);
        } else {
            restOfTheWorld.push(faqQuestions[i]);
        }
    }

    return [...topCatQuestions, ...restOfTheWorld];
};
