import React from "react";
import { Box, VStack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import MandateItem from "./MandateItem";
import PropTypes from "prop-types";

const UsersMandatesList = ({ viewAll, usersMandates, setDrawer }) => {
    const endslice = viewAll ? usersMandates.length : 2;
    return (
        <>
            <Box
                height={viewAll ? "85vh" : "40vh"}
                css={{
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
                overflowY={viewAll ? "scroll" : "hidden"}
                m="auto"
                width="90%"
            >
                <VStack>
                    {usersMandates?.slice(0, endslice).map((item) => (
                        <MandateItem mandate={item} key={item?.mandateId} setDrawer={setDrawer} />
                    ))}
                    {!viewAll && usersMandates?.length > 2 && (
                        <Link to="/mandates">
                            <Text
                                marginTop="10"
                                fontSize="0.8rem"
                                color="brand.primary"
                            >
                                View More
                            </Text>
                        </Link>
                    )}
                </VStack>
            </Box>
        </>
    );
};

UsersMandatesList.propTypes = {
    viewAll: PropTypes.bool,
    usersMandates: PropTypes.array,
    setDrawer: PropTypes.func
};
export default UsersMandatesList;
