import React from "react";
import PageLoader from "../../components/PageLoader";
import SetPasscode from "../../components/SetPasscode";
import SetUserDescription from "../../components/SetUserDescription";
import AddAccount from "../../components/AddAccount";
import PostOnboarding from "../../components/PostOnboarding";
import SearchBank from "../../components/SearchBank";
import WeAreWorking from "../../components/WeAreWorking";

export const getOnboardingSection = (
    onboardingStep,
    setOnboardingStep,
    setDrawer
) => {
    switch (onboardingStep) {
        case 1:
            return <SetPasscode setOnboardingStep={setOnboardingStep} />;
        case 2:
            return <SetUserDescription setOnboardingStep={setOnboardingStep} />;
        case 3:
            return <AddAccount setOnboardingStep={setOnboardingStep} />;
        case 4:
            return (
                <SearchBank onboarding setOnboardingStep={setOnboardingStep} />
            );
        case 5:
            return (
                <WeAreWorking
                    onboarding
                    setOnboardingStep={setOnboardingStep}
                />
            );
        case 6:
            return (
                <PostOnboarding
                    setDrawer={setDrawer}
                    setOnboardingStep={setOnboardingStep}
                />
            );

        default:
            return <PageLoader />;
    }
};

export const setStepOrRedirect = (user, setOnBoardingStep, router) => {
    // if (!user.setPassCode) {
    //   return setOnBoardingStep(1);
    // }
    // user.setPassCode &&
    if (user.migrated) {
        return router.replace("/dashboard");
    }

    if (user.personas.length === 0) {
        return setOnBoardingStep(2);
    }
    // user.setPassCode &&
    if (user?.onboardingStage === 0) {
        return setOnBoardingStep(3);
    }
    // user.setPassCode &&
    if (user.personas.length > 0 && user.onboardingStage > 0) {
        return router.replace("/dashboard");
    }
};
