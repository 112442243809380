import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { requireAuth, useAuth } from "../../util/auth";
import { getOnboardingSection, setStepOrRedirect } from "./helper";
import { Box } from "@chakra-ui/react";
import { useRouter } from "../../util/router";

const OnboardingPage = ({ setDrawer }) => {
    const { user } = useAuth();
    const router = useRouter();

    const [onboardingStep, setOnBoardingStep] = useState();

    useEffect(() => {
        setStepOrRedirect(user, setOnBoardingStep, router);
        // eslint-disable-next-line
    }, []);

    return (
        <Box w="100%" pt={{ base: "0%", md: "6rem" }} position="relative">
            {getOnboardingSection(onboardingStep, setOnBoardingStep, setDrawer)}
        </Box>
    );
};

OnboardingPage.propTypes = {
    setDrawer: PropTypes.func,
};

export default requireAuth(OnboardingPage);
