import { toastDefaults } from "../../util/constants";
import { verifyUserEmail } from "../../util/operations/user";

export const handleVerifyEmail = async (
  token,
  toast,
  router,
  updateUserData
) => {
  const { status, message } = await verifyUserEmail(token);
  message &&
    toast({
      ...toastDefaults,
      status: status ? "success" : "error",
      description: message,
      title: status ? "Success" : "Error",
    });

  if (status) {
    updateUserData(true, "emailVerified");
  }

  return router.replace("/dashboard");
};
