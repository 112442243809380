import { editUserData } from "../../util/operations/user";
import { toastDefaults, userDescriptions } from "../../util/constants";

export const handleSubmission = async (
    selectedUserTypes,
    setOnboardingStep,
    toast,
    user,
    router
) => {
    setOnboardingStep("Loading");

    const { status, message } = await editUserData({
        personas: selectedUserTypes,
    });

    sessionStorage.setItem("userDescription", JSON.stringify(userDescriptions));

    toast({
        ...toastDefaults,
        status: status ? "success" : "error",
        desciption: message,
        title: status ? "Success" : "Error",
    });

    if (status) {
        if (user?.linkedAccount) {
            return router.replace("/dashboard");
        }
        return setOnboardingStep(3);
    }
    return setOnboardingStep(2);
};
