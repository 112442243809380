/* eslint-disable react/prop-types */
import React from "react";
import { Box, Text, useDisclosure, Collapse } from "@chakra-ui/react";
import PropTypes from "prop-types";

const FAQItem = ({ faqItem }) => {
    const { isOpen, onToggle } = useDisclosure();
    const {
        // eslint-disable-next-line react/prop-types
        fields: { question, answer },
    } = faqItem;

    return (
        <Box
            bg={isOpen ? "brand.lightYellow" : "brand.white"}
            p={{ base: "3", md: "5" }}
            w="100%"
        >
            <Text
                onClick={onToggle}
                display="block"
                color="brand.primary"
                mb="5"
                cursor="pointer"
            >
                {question}{" "}
            </Text>

            <Collapse in={isOpen} animateOpacity>
                {answer?.split("\n").map((paragraph) => (
                    <Text lineHeight="1.8rem" mb="2" key={paragraph}>
                        {" "}
                        {paragraph}{" "}
                    </Text>
                ))}
            </Collapse>
        </Box>
    );
};

FAQItem.propTypes = {
    faqItem: PropTypes.shape({
        question: PropTypes.string,
        answer: PropTypes.string,
    }),
};

export default FAQItem;
