import React from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Box,
  VStack,
  Center,
  Image,
} from "@chakra-ui/react";
import ModalPeaceSign from "../../assets/svgs/modal-peace-sign.svg";
import { FaTimes } from "react-icons/fa";
import { useHistory } from "react-router-dom";

const DrawerComponent = ({
  children,
  isOpen,
  setIsOpen,
  isDark,
  redirectPath,
  showBitMoji,
}) => {
  const router = useHistory();

  const handleCloseDrawer = () => {
    setIsOpen({ isOpen: false });
    if (redirectPath) {
      return router.push(redirectPath);
    }
  };

  return (
    <>
      <Drawer
        onClose={() => handleCloseDrawer()}
        isOpen={isOpen}
        placement="bottom"
      >
        <DrawerOverlay
          bg={isDark ? "#DCDCDC" : "brand.primaryGradient"}
          opacity="0.9"
          mixBlendMode="multiply"
        />
        <DrawerContent
          isCentered={true}
          w={["100%", "600px", "500px"]}
          margin="auto"
          borderTopRightRadius="31px"
          borderTopLeftRadius="31px"
          py="5"
          // px="1"
          bg={isDark ? "brand.primary" : "brand.white"}
          boxShadow={isDark ? "" : "0px -7px 24px 0px rgba(0, 0, 0, 0.25)"}
        >
          <DrawerBody>
            {isDark ? (
              <Box
                position="absolute"
                top={showBitMoji ? "-14rem" : "-5rem"}
                left="50%"
                transform="translateX(-50%)"
              >
                <VStack>
                  {showBitMoji ? (
                    <Image h="150px" w="auto" src={ModalPeaceSign} />
                  ) : null}
                  <Center
                    onClick={() => handleCloseDrawer()}
                    borderRadius="full"
                    borderWidth="2px"
                    borderColor="brand.yellow"
                    bg="brand.yellow"
                    p="0.1rem"
                    margin="auto"
                    w="60px"
                    h="60px"
                    cursor="pointer"
                    textAlign="center"
                  >
                    <FaTimes height="15px" color="#961BA9" />
                  </Center>
                </VStack>
              </Box>
            ) : (
              <Box
                position="absolute"
                top="-5vh"
                left="50%"
                transform="translateX(-50%)"
              >
                <Center
                  onClick={() => handleCloseDrawer()}
                  borderRadius="full"
                  borderWidth="1px"
                  borderColor="brand.white"
                  bg="transparent"
                  p="0.1rem"
                  margin="auto"
                  w="20px"
                  height="20px"
                  cursor="pointer"
                  textAlign="center"
                >
                  <FaTimes height="5px" fontSize="10px" color="white" />
                </Center>
              </Box>
            )}
            {children}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

DrawerComponent.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  isDark: PropTypes.bool,
  redirectPath: PropTypes.string,
  showBitMoji: PropTypes.bool
};

export default DrawerComponent;
