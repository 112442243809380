import React, { Suspense, useState } from "react";
import { Switch, Route, Router } from "./../../util/router";
import { Redirect } from "react-router";
import { Auth0Provider } from "@auth0/auth0-react";
import AuthPage from "../auth";
import DashboardPage from "../dashboard";
// import NotFoundPage from "../not-found";
import OnboardingPage from "../onboarding";
import VerifyEmailPage from "../verify";
import SettingsPage from "../settings";
import Mandates from "../mandates";
import Stash from "../stash";
import WithdrawPage from "../withdraw";
import NotificationsPage from "../notifications";
import FAQPage from "../faq";
import RecoverPassword from "../recover-password";
import NavigationBar from "../../components/NavigationBar";
import AlertVerifyEmail from "../AlertVerifyEmail";
// import Footer from "../../components/Footer";
import DrawerComponent from "../../components/Drawer";
import { ChakraProvider, Container, Box } from "@chakra-ui/react";
import { theme } from "../../styles/theme";
import { ProvideAuth } from "../../util/auth";
import ContextProvider from "../../contexts/ContextProvider";
import ErrorBoundary from "../error-boundary";
import PageLoader from "../../components/PageLoader";
import { renderDrawerChild } from "./helper";

import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/montserrat";
import "@fontsource/mulish";

const AUTH0_CONFIG = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: window.location.origin,
    // audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
};

const App = () => {
    const [drawer, setDrawer] = useState({
        modalId: "",
        isOpen: false,
        data: {},
        isDark: false,
        redirectPath: null,
        showBitMoji: false,
    });

    return (
        <>
            <Auth0Provider {...AUTH0_CONFIG}>
                <ChakraProvider theme={theme}>
                    <ContextProvider>
                        <ErrorBoundary setDrawer={setDrawer}>
                            <Router>
                                <ProvideAuth>
                                    <Box
                                        bg={[
                                            "brand.primary",
                                            "brand.primary",
                                            "brand.flexBg",
                                        ]}
                                        w="full"
                                        zIndex="-400"
                                    >
                                        <NavigationBar />
                                        <Container
                                            position={{
                                                base: "unset",
                                                md: "fixed",
                                                lg: "fixed",
                                            }}
                                            zIndex={["0", "0", "400"]}
                                            shadow={["none", "none", "lg"]}
                                            left={["0", "0", "50%"]}
                                            w={["100%", "600px", "500px"]}
                                            transform={{
                                                md: "translateX(-50%)",
                                            }}
                                            h={{ md: "90vh", base: "100vh" }}
                                            p="0"
                                            px={{ base: "0" }}
                                            bg={"brand.white"}
                                            mt={{ base: "0rem", md: "2.5rem" }}
                                            borderRadius={{ md: "10px" }}
                                            // borderTopLeftRadius="10px"
                                            display={[
                                                "block",
                                                "block",
                                                "block",
                                            ]}
                                            overflowY="scroll"
                                            css={{
                                                "&::-webkit-scrollbar": {
                                                    display: "none",
                                                },
                                            }}
                                        >
                                            <DrawerComponent
                                                isDark={drawer.isDark}
                                                isOpen={drawer.isOpen}
                                                setIsOpen={setDrawer}
                                                showBitMoji={
                                                    drawer?.showBitMoji
                                                }
                                                redirectPath={
                                                    drawer.redirectPath
                                                }
                                            >
                                                <Suspense
                                                    fallback={<PageLoader />}
                                                >
                                                    {renderDrawerChild(
                                                        drawer,
                                                        setDrawer
                                                    )}
                                                </Suspense>
                                            </DrawerComponent>
                                            <Switch>
                                                <Route
                                                    path="/verify/email"
                                                    component={VerifyEmailPage}
                                                />

                                                <Route
                                                    path="/verify-email"
                                                    component={AlertVerifyEmail}
                                                />
                                                <Route
                                                    path="/withdraw"
                                                    render={(rProps) => (
                                                        <WithdrawPage
                                                            {...rProps}
                                                            setDrawer={
                                                                setDrawer
                                                            }
                                                        />
                                                    )}
                                                />

                                                <Route
                                                    path="/onboarding"
                                                    exact
                                                    render={(rProps) => (
                                                        <OnboardingPage
                                                            {...rProps}
                                                            setDrawer={
                                                                setDrawer
                                                            }
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    path="/dashboard"
                                                    exact
                                                    render={(rProps) => (
                                                        <DashboardPage
                                                            {...rProps}
                                                            setDrawer={
                                                                setDrawer
                                                            }
                                                            drawer={drawer}
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    path="/mandates"
                                                    render={(rProps) => (
                                                        <Mandates
                                                            {...rProps}
                                                            setDrawer={
                                                                setDrawer
                                                            }
                                                        />
                                                    )}
                                                />

                                                <Route
                                                    path="/stash"
                                                    render={(rProps) => (
                                                        <Stash
                                                            {...rProps}
                                                            setDrawer={
                                                                setDrawer
                                                            }
                                                        />
                                                    )}
                                                />

                                                <Route
                                                    path="/settings"
                                                    render={(rProps) => (
                                                        <SettingsPage
                                                            {...rProps}
                                                            setDrawer={
                                                                setDrawer
                                                            }
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    path="/notifications"
                                                    render={(rProps) => (
                                                        <NotificationsPage
                                                            {...rProps}
                                                            setDrawer={
                                                                setDrawer
                                                            }
                                                        />
                                                    )}
                                                />

                                                <Route
                                                    path="/help"
                                                    render={(rProps) => (
                                                        <FAQPage
                                                            {...rProps}
                                                            setDrawer={
                                                                setDrawer
                                                            }
                                                        />
                                                    )}
                                                />

                                                <Route
                                                    path="/recover"
                                                    exact
                                                    render={(rProps) => ( 
                                                        <RecoverPassword
                                                            {...rProps}
                                                            setDrawer={
                                                                setDrawer
                                                            }
                                                        />
                                                    )}
                                                />
                                                <Route
                                                    path="/user/:authType"
                                                    exact
                                                    render={(rProps) => (
                                                        <AuthPage
                                                            {...rProps}
                                                            setDrawer={
                                                                setDrawer
                                                            }
                                                        />
                                                    )}
                                                />
                                                {/* <Route
                        path="/*"
                        render={(rProps) => (
                          <NotFoundPage {...rProps} setDrawer={setDrawer} />
                        )}
                      /> */}
                                                <Redirect
                                                    strict
                                                    from="/"
                                                    to="/user/login"
                                                />
                                            </Switch>
                                        </Container>
                                    </Box>
                                </ProvideAuth>
                            </Router>
                        </ErrorBoundary>
                    </ContextProvider>
                    {/* <Footer /> */}
                </ChakraProvider>
            </Auth0Provider>
        </>
    );
};

export default App;
