import React, { useState } from "react";
import MonoConnect from "@mono.co/connect.js";
import { VStack, Text } from "@chakra-ui/layout";
import { Box, HStack, Image, Stack } from "@chakra-ui/react";
import { Button } from "@chakra-ui/button";
import PropTypes from "prop-types";
import { handleConnectWithMono, handleConnectWithOkra } from "./helper";
import { useToast } from "@chakra-ui/toast";
import { useHistory } from "react-router-dom";
import bankSVG from "../../assets/svgs/add-bank.svg";
import sushSVG from "../../assets/svgs/Shushing face.svg";
import Okra from "okra-js";
import TryAnotherOBP from "../TryAnotherOBP";
import { toastDefaults } from "../../util/constants";

const AddAccount = ({ setOnboardingStep }) => {
    const toast = useToast();
    const router = useHistory();
    const [showBackup, setShowBackup] = useState(false);

    // eslint-disable-next-line
    const monoConnect = React.useMemo(() => {
        const monoInstance = new MonoConnect({
            onSuccess: ({ code }) =>
                handleConnectWithMono(
                    code,
                    toast,
                    setOnboardingStep,
                    setShowBackup
                ),
            key: process.env.REACT_APP_MONO_PUBLIC_KEY,
        });

        monoInstance.setup();

        return monoInstance;
        // eslint-disable-next-line
    }, []);

    const okraConnect = () => {
        Okra.buildWithShortUrl({
            short_url:
                process.env.NODE_ENV === "development"
                    ? process.env.REACT_APP_OKRA_SHORT_DEVURL
                    : process.env.REACT_APP_OKRA_SHORT_PRODURL,
            onSuccess: (data) => {
                const { auth, balance, accounts } = data;
                const okraData = {
                    account_number: accounts[0]?.nuban,
                    account_name: balance?.data?.formatted[0]?.name,
                    bank_name: auth?.bank_details?.name,
                    bank_code: auth?.bank_details?.slug, //due to change later
                    currency: balance?.data?.formatted[0]?.currency || "NGN",
                    okra_customer_id: data?.customer_id,
                    okra_account_id:
                        balance?.data?.formatted[0]?.account || data?.record_id, //not sure it's meant to be record id, but let's see
                    okra_bank_id: auth?.bank_id,
                };
                return handleConnectWithOkra(okraData, toast, "ONBOARDING", {
                    setOnboardingStep,
                });
            },
            onError: (json, error) => {
                console.log(json, error);
                toast({
                    ...toastDefaults,
                    status: "error",
                    desciption: "Bank account linking failed",
                    title: "Error",
                });
                setOnboardingStep(3);
            },
            onClose: () => {
                setOnboardingStep(3);
            },
        });
    };

    return (
        <>
            {showBackup ? (
                <Box px="3">
                    <TryAnotherOBP data={{ okraConnect }} />
                </Box>
            ) : (
                <VStack spacing={{ base: "3", md: "7" }} pb="50">
                    <Box
                        borderBottomLeftRadius="37px"
                        borderBottomRightRadius="37px"
                        h={{ base: "11rem", md: "13rem" }}
                        pt={{ base: "10", md: "0" }}
                        w="100%"
                        bg={{
                            base: "brand.lightPrimaryButton",
                            md: "brand.white",
                        }}
                        mb={{ base: "-3.6rem", md: "-8rem" }}
                    >
                        <HStack justifyContent="center" w="50%" m="auto">
                            <Box
                                borderRadius="100%"
                                w="10px"
                                h="10px"
                                bg="brand.primary"
                            />
                            <Box
                                borderRadius="100%"
                                w="10px"
                                h="10px"
                                bg="brand.lightGrey"
                            />
                            <Box
                                borderRadius="100%"
                                w="10px"
                                h="10px"
                                bg="brand.lightGrey"
                            />
                        </HStack>

                        <Text
                            m="auto"
                            fontSize="1.5rem"
                            fontWeight="600"
                            color="brand.primary"
                            textAlign="center"
                            mt="0.9rem"
                        >
                            Connect your bank account
                        </Text>
                    </Box>

                    <Box
                        marginBottom="15"
                        w={{ base: "80%", md: "50%" }}
                        mx="auto"
                    >
                        <Box marginBottom="5">
                            {" "}
                            <Image
                                width={{ base: "50%", md: "56%" }}
                                display="block"
                                margin="auto"
                                src={bankSVG}
                                transform="scale(1.1)"
                            />{" "}
                        </Box>
                        <Image display="block" margin="auto" src={sushSVG} />{" "}
                        <Text fontSize="0.95rem" textAlign="center">
                            We do not share or keep your bank details
                        </Text>
                        <Text
                            mt="1rem"
                            color="brand.primary"
                            fontSize="0.95rem"
                            textAlign="center"
                        >
                            Stage 1/3
                        </Text>
                    </Box>
                    <Stack
                        direction={{ base: "column", md: "row" }}
                        w={{ base: "full", md: "80%" }}
                        m="auto"
                        px={{ base: "10%", md: "0%" }}
                    >
                        <Button
                            flex={{ base: "1", md: "3" }}
                            h={{ base: "0", md: "3.2rem" }}
                            py={{ base: "0.8rem", md: "auto" }}
                            m="auto"
                            onClick={() => monoConnect.open()}
                            color="brand.white"
                            bg="brand.primary"
                            variant="solid"
                            width="full"
                            _hover={{
                                background: "brand.primary",
                                shadow: "md",
                            }}
                            shadow="sm"
                            _active={{
                                shadow: "md",
                                transform: "translateY(.5rem)",
                            }}
                        >
                            <Text> Get Started</Text>
                        </Button>

                        <Button
                            flex={{ base: "1", md: "1" }}
                            h="3.2rem"
                            m="auto"
                            py={{ base: "0.8rem", md: "auto" }}
                            onClick={() => router.replace("/dashboard")}
                            color="brand.primary"
                            border="1px solid"
                            borderColor="brand.primary"
                            bg="brand.white"
                            width="full"
                            _hover={{
                                background: "brand.lightYellow",
                                shadow: "md",
                            }}
                            shadow="sm"
                            _active={{
                                shadow: "md",
                                transform: "translateY(.5rem)",
                            }}
                        >
                            <Text> Skip </Text>
                        </Button>
                    </Stack>

                    <VStack
                        spacing="2"
                        w={{ md: "60%", base: "80%" }}
                        m="auto"
                        fontSize="0.9rem"
                        textAlign="center"
                    >
                        <Text>
                            {" "}
                            myStash is compatible with over 15 banks in Nigeria.
                        </Text>{" "}
                        <Text textDecoration="underline" color="brand.primary">
                            <span
                                id="checkBanksBtn"
                                onClick={() => setOnboardingStep(4)}
                                style={{ color: "#961BA9" }}
                            >
                                Check banks
                            </span>
                        </Text>
                    </VStack>
                </VStack>
            )}{" "}
        </>
    );
};

AddAccount.propTypes = {
    setOnboardingStep: PropTypes.func,
};

export default AddAccount;
