import { toastDefaults } from "../../util/constants";
import { withdraw, breakFlow } from "../../util/operations/mandates";

export const handleWithdraw = async ({
    formData,
    setIsLoading,
    router,
    toast,
    mandate,
    editMandateInState,
    mandates,
}) => {

    if (formData?.amount > mandate?.balance) {
        return toast({
            ...toastDefaults,
            status: "error",
            title: "Error",
            description: "You do not have up to that amount",
        });
    } else {


        setIsLoading(true);

        const { status, message } = mandate?.matured ? await withdraw({
            amount: formData?.amount,
            mandateId: mandate?.mandateId,
        }) : await breakFlow({
            amount: formData?.amount,
            mandateId: mandate?.mandateId,
        })

        setIsLoading(false);
        toast({
            ...toastDefaults,
            status: status ? "success" : "error",
            desciption: message,
            title: status ? "Success" : "Error",
        });

        if (status) {
            editMandateInState(
                {
                    ...mandate,
                    balance: mandate.balance - formData.amount,
                },
                mandates
            );
            return router.goBack();
        }
    }
};
