import { toastDefaults } from "../../util/constants";

export const handleGoogleResponse = async (
    response,
    googleStatus,
    authType,
    auth,
    toast,
    setIsLoading,
    router
) => {
    if (response && !googleStatus) {
        setIsLoading(false);

        if (response.error === "idpiframe_initialization_failed") {
            return;
        }
        await toast.closeAll();
        return toast({
            ...toastDefaults,
            status: "error",
            description: "There has been an error",
            title: `${authType === "login" ? "Signin" : "Signup"} error`,
        });
    }
    const {
        profileObj: {
            email,
            //  familyName, givenName, imageUrl,
            googleId,
            name,
        },
    } = response;

    const googleAuthData = {
        email,
        // password: `${email}${googleId}`,
        name,
        googleId,
    };

    const { status, message } = await auth.googleAuth(googleAuthData, authType);

    await toast({
        ...toastDefaults,
        status: status ? "success" : "error",
        description: message,
        title: status ? "Great!" : "Error",
    });

    setIsLoading(false);

    if (status) {
        router.push("/onboarding");
    }
    return null;
};
