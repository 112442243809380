import createDataContext from "./createContext";
import {
    fetchUserStastistics,
    getUserReserve,
} from "../util/operations/statistics";

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_STATS_SUCCESS":
            return {
                ...state,
                stats: action.payload,
                statLoading: false,
            };
        case "FETCH_STATS_LOADING":
            return {
                ...state,
                statLoading: true,
            };

        case "FETCH_RESERVE_SUCCESS":
            return {
                ...state,
                userReserve: action.payload,
            };
        default:
            return state;
    }
};

const fetchUserStats = (dispatch) => async (currentStats) => {
    const userStatistics = await fetchUserStastistics(currentStats);

    dispatch({
        type: "FETCH_STATS_SUCCESS",
        payload: userStatistics?.data?.data,
    });
};

const fetchUserReserve = (dispatch) => async (currentReserve) => {
    // if (currentReserve?._id) return null;
    const userReserve = await getUserReserve(currentReserve); 

    dispatch({
        type: "FETCH_RESERVE_SUCCESS",
        payload: userReserve?.data,
    });
};

export const { Context, Provider } = createDataContext(
    reducer,
    {
        fetchUserStats,
        fetchUserReserve,
    },
    {
        stats: {},
        userReserve: {},
    }
);
