import { promisify } from "es6-promisify";
import Auth0 from "auth0-js";

// Initialize Auth0
// const auth0Realm = "Username-Password-Authentication";
const auth0 = new Auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
    responseType: "token id_token",
    scope: "openid profile email",
});

// First let's create promisified versions of the Auth0 methods we need
// so that we can use then().catch() instead of dealing with callback hell.
// We use bind so that internally "this" still has the correct scope.
const login = promisify(auth0.client.login.bind(auth0.client));
const userInfo = promisify(auth0.client.userInfo.bind(auth0.client));
const changePassword = promisify(auth0.changePassword.bind(auth0));

// Now lets wrap our methods with extra logic, such as including a "connection" value
// and ensuring human readable errors are thrown for our UI to catch and display.
// We make these custom methods available within an auth0.extended object.

let onChangeCallback = () => null;

auth0.extended = {
    getCurrentUser: () => {
        const accessToken = getAccessToken();
        return userInfo(accessToken).catch(handleError);
    },
    login: (options) => {
        return login({
            realm: process.env.REACT_APP_AUTH0_CONNECTION,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            ...options,
        });
        // .then(handleAuth)
        // .catch(handleError);
    },
    // Send email so user can reset password
    changePassword: (options) => {
        return changePassword({
            connection: process.env.REACT_APP_AUTH0_CONNECTION, // auth0Realm,
            ...options,
        });
    },
    logout: () => {
        handleLogout();
    },
};

const handleLogout = () => {
    removeAccessToken();
    onChangeCallback(false);
};

const handleError = (error, autoLogout = false) => {
    // If error code indicates user is unauthorized then log them out.
    // We only do this if autoLogout is enabled so we can skip in instances
    // where it's not possible its due to token expiration (such as right after login)
    // and we'd rather throw an error that can be displayed by the UI.
    if (error.code === 401 && autoLogout) {
        handleLogout();
    }

    // Find a human readable error message in an Auth0 error object and throw.
    // Unfortunately, it's not always in the same location :/
    let message;
    if (error.code === "invalid_password") {
        message = `Your password must be: ${error.policy}`;
    } else if (typeof error.message === "string") {
        message = error.message;
    } else if (typeof error.description === "string") {
        message = error.description;
    } else if (typeof error.original === "string") {
        message = error.original;
    } else if (error.original && typeof error.original.message === "string") {
        message = error.original.message;
    } else {
        message = error.code; // Use error.code if no better option
    }

    console.log(message, error, error.code);
    // throw new Error(error.code, message);
};

// Local Storage methods
const getAccessToken = () => sessionStorage.getItem("token");
const removeAccessToken = () => sessionStorage.removeItem("token");

export default auth0;
