import React from "react";
import {
    Box,
    Text,
    Heading,
    HStack,
    Center,
    VStack,
    Image,
} from "@chakra-ui/react";
import MyStashAuthLogo from "../../assets/svgs/AuthHeaderLogo.svg";
import PropTypes from "prop-types";
import GoogleLoginComponent from "../AuthFooter/GoogleLogin";
import { motion } from "framer-motion";

const AuthFormHeader = ({ authType }) => {
    return (
        <VStack pb="5" spacing={{ base: "3", md: "5" }} w="100%">
            <Box
                borderBottomLeftRadius="37px"
                borderBottomRightRadius="37px"
                h={{ base: "27vh", md: "30vh" }}
                w="100%"
                display={{ md: "none" }}
                bg="brand.lightPrimaryButton"
            >
                <motion.div
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5 }}
                >
                    <Center py="20">
                        <Image
                            transform="scale(1.2)"
                            w="auto"
                            src={MyStashAuthLogo}
                        />
                    </Center>
                </motion.div>
            </Box>

            <Box px={{ base: "0", md: "5" }} pt={{ md: "3rem" }} w="100%">
                <HStack w="100%" justifyContent="space-between">
                    <Box px="5" flex="1" textAlign="left">
                        <Heading textAlign="left">
                            {authType === "login"
                                ? "Welcome!"
                                : authType === "register"
                                ? "Sign up"
                                : "Password Reset "}{" "}
                        </Heading>
                        {authType === "login" ? (
                            <Text color="brand.textLightGey">
                                Sign in to continue
                            </Text>
                        ) : null}
                    </Box>
                    {authType !== "forgot-password" ? (
                        <HStack
                            px="5"
                            spacing="5"
                            justifyContent="flex-end"
                            flex="1"
                        >
                            <GoogleLoginComponent authType={authType} />
                        </HStack>
                    ) : null}
                </HStack>
            </Box>
        </VStack>
    );
};

AuthFormHeader.propTypes = {
    authType: PropTypes.string,
};

export default AuthFormHeader;
