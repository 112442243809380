import { toastDefaults } from "../../util/constants";
import {
    authorizePwdReset,
    finalizeResetPassword,
} from "../../util/operations/user";

export const handleVerifyToken = async (
    token,
    setVerifiedToken,
    router,
    toast
) => {
    const { status, message, data } = await authorizePwdReset(token);

    if (!status) {
        toast({
            ...toastDefaults,
            description: message,
            title: status ? "Great" : "Error",
            status: status ? "success" : "error",
        });

        return router.replace("/");
    }

    return setVerifiedToken(data.data.stage2Token);
};

export const handleFinalReset = async (
    resetData,
    setIsLoading,
    toast,
    router
) => {
    setIsLoading(true);
    const { status, message } = await finalizeResetPassword(resetData);

    toast({
        ...toastDefaults,
        description: message,
        title: status ? "Great" : "Error",
        status: status ? "success" : "error",
    });

    if (status) {
        return router.replace("/recover?complete=true");
    }

    return setIsLoading(false);
};
