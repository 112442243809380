const bankList = [
  {
    bank_name: "GTBank",
    color: "#FFA979",
    id: 1,
  },
  {
    bank_name: "Access Bank",
    color: "red",
    id: 2,
    register_with: "internet_banking",
  },
  {
    bank_name: "Kuda Bank",
    color: "red",
    id: 3,
    register_with: "internet_banking",
  },
  {
    bank_name: "Polaris Bank",
    color: "#C184CB",
    id: 4,
    register_with: "internet_banking",
  },
  {
    bank_name: "Union Bank",
    color: "red",
    id: 5,
    register_with: "internet_banking",
  },
  {
    bank_name: "FCMB Bank",
    color: "red",
    id: 6,
  },
  {
    bank_name: "First Bank",
    color: "#949BE0",
    id: 7,
    register_with: "internet_banking",
  },
  {
    bank_name: "Barter ",
    color: "red",
    id: 8,
    register_with: "internet_banking",
  },
  {
    bank_name: "Alat Bank",
    color: "red",
    id: 9,
    register_with: "internet_banking",
  },
  // {
  //   bank_name: "UBA",
  //   color: "red",
  //   id: 10,
  // },
  {
    bank_name: "Sterling Bank",
    color: "red",
    id: 11,
  },
  {
    bank_name: "Zenith Bank",
    color: "red",
    id: 12,
  },
  {
    bank_name: "Wallets Africa ",
    color: "red",
    id: 13,
    register_with: "internet_banking",
  },
  {
    bank_name: "Jaiz Bank",
    color: "red",
    id: 14,
    register_with: "internet_banking",
  },
  {
    bank_name: "Eco Bank",
    color: "red",
    id: 15,
    register_with: "internet_banking",
  },
  {
    bank_name: "Unity Bank",
    color: "red",
    id: 16,
    register_with: "internet_banking",
  },
  {
    bank_name: "Piggyvest",
    color: "red",
    id: 17,
  },
  {
    bank_name: "Cowrywise Bank",
    color: "red",
    id: 18,
  },
];

export default bankList;

// GTBank
// Access Bank*
// Kuda Bank*
// Polaris Bank*
// Union Bank*
// FCMB Bank
// First Bank
// Barter*
// Alat Bank*
// UBA Bank
// Sterling Bank
// Zenith Bank
// Wallets Africa*
// Jaiz Bank*
// Eco Bank*
// Unity Bank*
// Piggyvest
// Cowrywise
