/* eslint-disable  */
import React from "react";
import {
    Box,
    Text,
    HStack,
    Center,
    VStack,
    Image,
    Button,
} from "@chakra-ui/react";
import SupportIcon from "../../assets/svgs/support-icon.svg";
import ErrorEmoji from "../../assets/svgs/something-went-wrong-emoji.svg";
import { SUPPORT_WHATSAPP_CONTACT } from "../../util/constants";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        console.log(
            "🚀 ~ file: index.js ~ line 23 ~ ErrorBoundary ~ componentDidCatch ~ errorInfo",
            errorInfo
        );
        console.log(
            "🚀 ~ file: index.js ~ line 23 ~ ErrorBoundary ~ componentDidCatch ~ error",
            error
        );
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
    }

    render() {
        if (this.state.error) {
            return (
                <VStack pt="20">
                    <Image src={ErrorEmoji} />
                    <Box>
                        <Text fontSize="lg">Something went wrong</Text>
                    </Box>
                    <HStack>
                        <Center px="5" py="8">
                            <Image
                                onClick={() =>
                                    window.open(
                                        `https://wa.me/${SUPPORT_WHATSAPP_CONTACT}`
                                    )
                                }
                                src={SupportIcon}
                            />
                        </Center>
                    </HStack>
                    <Button variant="solid" size="lg">
                        Try Again
                    </Button>
                </VStack>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary;
