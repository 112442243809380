import { useState, useEffect } from "react";
import axios from "axios";

export const useFaq = () => {
    const [data, setData] = useState();

    const getQuestions = async () => {
        const response = await axios({
            url: `https://api.airtable.com/v0/appuacO4jFXsNsC2N/FAQ?sort%5B0%5D%5Bfield%5D=position&sort%5B0%5D%5Bdirection%5D=asc`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_APIKEY}`,
            },
        });

        return response.data;
    };

    useEffect(() => {
        return getQuestions()
            .then((response) => {
                setData(response);
            })
            .catch(setData(null));
    }, []);
    return data?.records;
};
