export const authButtonTexts = {
    LOGIN: "Sign In",
    REGISTER: "Sign Up",
    "FORGOT-PASSWORD": "Send Reset Email",
};

export const afterAuthAction = {
    LOGIN: "/onboarding",
    REGISTER: "/user/login",
    "FORGOT-PASSWORD": "/user/forgot-password",
};

export function validateEmail(email) {
    const re =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.trim());
}

export const COUNTRY_CODE = "234";

export const verifyPhoneNumber = (phone) => {
    return /^([0]{1}|\+?234)([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g.test(
        String(phone)
    );
};

export const sanitizePhoneNumber = (phone) => {
    phone = String(phone);

    if (phone.startsWith("0") || phone.startsWith("+")) {
        phone = phone.substring(1);
    }
    if (phone.startsWith(COUNTRY_CODE)) {
        return phone;
    }
    return `${COUNTRY_CODE}${phone}`;
};

export function validatePhoneNumber(data) {
    return verifyPhoneNumber(data);
}

export function validatePassword(password) {
    const passwordRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*+])(?=.{8,})"
    );
    // RegEx Description
    // ^ - The password string will start this way
    // (?=.*[a-z]) - The string must contain at least 1 lowercase alphabetical character
    // (?=.*[A-Z]) - The string must contain at least 1 uppercase alphabetical character
    // (?=.*[0-9]) - The string must contain at least 1 numeric character
    // (?=.*[!@#$%^&*]) - The string must contain at least one special
    // character, but we are escaping reserved RegEx characters to avoid conflict
    //
    // (?=.{8,}) - The string must be eight characters or longer
    if (passwordRegex.test(password)) {
        return true;
    }

    return `Password must contain at least one uppercase and one lower case character, one numeric character and one special character (!@#$%^&*+)
    `;
}
