import React from "react";

import { Text, VStack, Image, Button, Box } from "@chakra-ui/react";
import ErrorEmoji from "../../assets/svgs/something-went-wrong-emoji.svg";
import PropTypes from "prop-types";

const TryAnotherOBP = ({ setDrawer, data: { okraConnect } }) => {
    const handleOkraCall = () => {
        setDrawer &&
            setDrawer({
                isOpen: false,
            });
        return okraConnect();
    };

    return (
        <div>
            <VStack pt="5" pb="5">
                <Image src={ErrorEmoji} />

                <Box>
                    <Text mb="2" textAlign="center" fontSize="md">
                        It seems we are having trouble connecting, try another
                        way
                    </Text>
                </Box>

                <Button
                    variant="solid"
                    size="lg"
                    onClick={() => handleOkraCall()}
                >
                    Link with okra
                </Button>
            </VStack>
        </div>
    );
};

TryAnotherOBP.propTypes = {
    setDrawer: PropTypes.func,
    data: PropTypes.shape({
        okraConnect: PropTypes.func,
    }),
};

export default TryAnotherOBP;
