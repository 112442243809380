import { apiRequest } from "../apiRequest";

export const linkCard = (data) => apiRequest("/card/add", "POST", data);

export const deleteCard = (data) =>
    apiRequest(`/card/remove/${data.cardId}`, "DELETE", data);

export const fetchUserCards = () => apiRequest("/card/fetch", "GET");

export const verifyPayment = (data) =>
    apiRequest(`/payment/verify/${data?.reference}`, "POST", data);
