import React, { useState, useContext, useMemo } from "react";
import MonoConnect from "@mono.co/connect.js";
import Okra from "okra-js";
import { useToast } from "@chakra-ui/react";
import { Context as AccountsContext } from "../../contexts/accountsContext";
import { handleConnectWithMono } from "./helper";
import DrawerComponent from "../Drawer";
import { renderDrawerChild, handleConnectWithOkra } from "./helper";
import { toastDefaults } from "../../util/constants";

// HOC that wraps components that need to handle linking accounts with Mono or Okra
const linkAccountWithProviders = (Component) => {
    // eslint-disable-next-line react/display-name
    return (props) => {
        const [bankIsLoading, setBankIsLoading] = useState(false);
        const { addAccountToState } = useContext(AccountsContext);
        const toast = useToast();
        const [drawer, setDrawer] = useState({
            modalId: "",
            isOpen: false,
            data: {},
            isDark: false,
            redirectPath: null,
            showBitMoji: false,
        });

        const okraConnect = () => {
            Okra.buildWithShortUrl({
                short_url:
                    process.env.NODE_ENV === "development"
                        ? process.env.REACT_APP_OKRA_SHORT_DEVURL
                        : process.env.REACT_APP_OKRA_SHORT_PRODURL,
                onSuccess: (data) => {
                    const { auth, balance, accounts } = data;
                    const okraData = {
                        account_number: accounts[0]?.nuban,
                        account_name: balance?.data?.formatted[0]?.name,
                        bank_name: auth?.bank_details?.name,
                        bank_code: auth?.bank_details?.slug, //due to change later
                        currency:
                            balance?.data?.formatted[0]?.currency || "NGN",
                        okra_customer_id: data?.customer_id,
                        okra_account_id:
                            balance?.data?.formatted[0]?.account ||
                            data?.record_id, //not sure it's meant to be record id, but let's see
                        okra_bank_id: auth?.bank_id,
                    };
                    return handleConnectWithOkra(
                        okraData,
                        toast,
                        "SELECT_BANK",
                        { setBankIsLoading }
                    );
                },
                onError: (json, error) => {
                    console.log(json, error);
                    toast({
                        ...toastDefaults,
                        status: "error",
                        desciption: "Bank account linking failed",
                        title: "Error",
                    });
                },
                onClose: () => {
                    setBankIsLoading(false);
                },
            });
        };

        const monoConnect = useMemo(() => {
            const monoInstance = new MonoConnect({
                onSuccess: ({ code }) =>
                    handleConnectWithMono(
                        code,
                        toast,
                        setBankIsLoading,
                        addAccountToState,
                        setDrawer,
                        okraConnect
                    ),
                key: process.env.REACT_APP_MONO_PUBLIC_KEY,

                // onClose: () => {
                //   const reOpenDrawerTitle = sessionStorage.getItem(
                //     "reOpenDrawerTitle",
                //     "SELECT_ACCOUNT"
                //   );
                //   if (reOpenDrawerTitle) {
                //     sessionStorage.setItem("reOpenDrawer", true);
                //   }
                // },
            });

            monoInstance.setup();

            return monoInstance;
            // eslint-disable-next-line
        }, []);

        return (
            <>
                <DrawerComponent
                    isDark={drawer.isDark}
                    isOpen={drawer.isOpen}
                    setIsOpen={setDrawer}
                    showBitMoji={drawer?.showBitMoji}
                    redirectPath={drawer.redirectPath}
                >
                    {renderDrawerChild(drawer, setDrawer)}
                </DrawerComponent>
                <Component
                    {...props}
                    monoConnect={monoConnect}
                    okraConnect={okraConnect}
                    bankIsLoading={bankIsLoading}
                />
            </>
        );
    };
};

export default linkAccountWithProviders;
