import { VStack, Text, Button, Box } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

const WeAreWorking = ({ setOnboardingStep, onboarding, setNoBank }) => {
    const router = useHistory();

    return (
        <>
            <VStack pt="20vh" spacing="10">
                <Text
                    fontSize={{ base: "1.3rem", md: "1.5rem" }}
                    textAlign="center"
                    color="brand.primary"
                    w="80%"
                    m="auto"
                >
                    {" "}
                    We are working on supporting more banks
                </Text>
                <Box>
                    <Button
                        onClick={() =>
                            onboarding ? setOnboardingStep(4) : setNoBank(false)
                        }
                        _hover={{
                            background: "brand.primary",
                            shadow: "md",
                        }}
                        _active={{
                            shadow: "md",
                            transform: "translateY(.1rem)",
                        }}
                    >
                        {" "}
                        Check Again
                    </Button>
                </Box>
                <Text
                    color="brand.primary"
                    fontWeight="600"
                    mt="5"
                    onClick={() =>
                        onboarding
                            ? router.replace("/dashboard")
                            : router.history.goBack()
                    }
                >
                    {onboarding ? "Skip" : "Go Back"}
                </Text>
            </VStack>
        </>
    );
};

WeAreWorking.propTypes = {
    setOnboardingStep: PropTypes.func,
    onboarding: PropTypes.bool,
    setNoBank: PropTypes.func,
};

export default WeAreWorking;
