import React, { useState } from "react";
import { useRouter } from "../../util/router";
import { handleFinalReset } from "./helper";
import queryString from "query-string";
import {
    useToast,
    Box,
    FormControl,
    Input,
    Heading,
    HStack,
    Center,
    VStack,
    Text,
    Button,
    Stack,
    Image,
} from "@chakra-ui/react";
import MyStashAuthLogo from "../../assets/svgs/AuthHeaderLogo.svg";
import PasswordIcon from "../../assets/svgs/input-password-icon.svg";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { toastDefaults } from "../../util/constants";
import PropTypes from "prop-types";
import AppStoreIcon from "../../assets/svgs/app-store.svg";
import PlayStoreIcon from "../../assets/svgs/play-store.svg";

const RecoverPassword = (props) => {
    const router = useRouter();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const {
        location: { search },
    } = props;

    const parsedQueryString = queryString.parse(search);
    const { token, complete } = parsedQueryString;

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const submit = async (formData) => {
        if (!search) {
            toast({
                ...toastDefaults,
                description: "Cannot verify link",
                title: "Error",
                status: "error",
            });
            return router.replace("/");
        }

        return handleFinalReset(
            { password: formData?.password, encryptedData: token },
            setIsLoading,
            toast,
            router
        );
    };

    if (complete) {
        return (
            <VStack pb="5" spacing="5" w="100%">
                <Box w="100%">
                    <HStack w="100%" justifyContent="space-between">
                        <HStack px="5" textAlign="center" py="20">
                            <Heading textAlign="center">
                                Password Reset Successful!
                            </Heading>
                        </HStack>
                    </HStack>
                </Box>
                <Text color={"purple"}>Proceed to your mobile app to login</Text>
                <HStack>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.mystashinc"
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        <Image width="40vw" height="100%" src={PlayStoreIcon} />
                    </a>
                    <a
                        href="https://apps.apple.com/ng/app/mystash/id1606916991"
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        <Image width="40vw" height="100%" src={AppStoreIcon} />
                    </a>
                </HStack>
            </VStack>
        );
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <>
                <VStack pb="5" spacing="5" w="100%">
                    <Box
                        borderBottomLeftRadius="37px"
                        borderBottomRightRadius="37px"
                        w="100%"
                        h="30vh"
                        bg="brand.lightPrimaryButton"
                    >
                        <motion.div
                            animate={{ scale: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            <Center py="20">
                                <Image
                                    transform="scale(1.2)"
                                    src={MyStashAuthLogo}
                                />
                            </Center>
                        </motion.div>
                    </Box>

                    <Box w="100%">
                        <HStack w="100%" justifyContent="space-between">
                            <Box px="5" flex="1" textAlign="left">
                                <Heading textAlign="left">Set Password</Heading>
                            </Box>
                        </HStack>
                    </Box>

                    <Box px="5" w="100%">
                        <HStack height="70px">
                            <Image fleex="1" src={PasswordIcon} />
                            <FormControl marginBottom="4">
                                <Input
                                    {...register("password", {
                                        required: "This field is required",
                                    })}
                                    placeholder="New Password"
                                    border="none"
                                    borderRadius="0px"
                                    borderBottom="1px"
                                    borderBottomColor={
                                        errors?.password
                                            ? "brand.error"
                                            : "brand.darkGrey"
                                    }
                                    name="password"
                                    type="password"
                                    _focus={{
                                        outline: "none",
                                        borderColor: "brand.darkGrey",
                                    }}
                                />
                            </FormControl>
                        </HStack>
                        <Stack py="5" spacing={6}>
                            <Button
                                h="3.2rem"
                                m="auto"
                                color="brand.white"
                                bg="brand.primary"
                                variant="solid"
                                width="full"
                                _hover={{
                                    background: "brand.primary",
                                    shadow: "md",
                                }}
                                _active={{
                                    shadow: "md",
                                    transform: "translateY(.5rem)",
                                }}
                                borderRadius="12px"
                                shadow="sm"
                                isLoading={isLoading}
                                loadingText="Hang on.."
                                type="submit"
                            >
                                <Text> Set Password </Text>
                            </Button>
                        </Stack>
                    </Box>
                </VStack>
            </>
        </form>
    );
};

RecoverPassword.propTypes = {
    location: PropTypes.object,
};

export default RecoverPassword;
