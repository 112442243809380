/* eslint-disable  */
import React, { useContext, useEffect } from "react";
import { HStack } from "@chakra-ui/layout";
import { Box, Text, Image, Badge, useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Context as NotificationsContext } from "../../contexts/notificationsContext";

import UserIcon from "../../assets/svgs/dashboard-user-icon.svg";
import NotificationsIcon from "../../assets/svgs/notification-bell.svg";

const TopNav = ({ userName }) => {
  const history = useHistory();
  const toast = useToast();

  const formatUserName = (name) => {
    const splitNames = name?.split(" ");
    return splitNames && splitNames[0];
  };

  const {
    state: { notifications },
    getUserNotifications,
  } = useContext(NotificationsContext);

  useEffect(() => {
    getUserNotifications(notifications, toast);
  }, []);

  return (
    <>
      <HStack
        bg="brand.white"
        zIndex="100"
        height={{ base: "4rem", md: "5.4rem" }}
        boxShadow="sm"
        p="4"
        top="0%"
        position="fixed"
        w={["100%", "600px", "500px"]}
        m="auto"
        display={{ base: "block", md: "none" }}
      >
        <HStack w="full" px={{ base: "0", md: "4" }}>
          <Box
            onClick={() => history.push("/settings/edit-profile")}
            cursor="pointer"
          >
            {" "}
            <Image src={UserIcon} />{" "}
          </Box>
          <Box textTransform="capitalize" flex="1" textAlign="center">
            <Text> Hello, {formatUserName(userName)}</Text>
          </Box>
          <Box
            position="relative"
            onClick={() => history.push("/notifications")}
            cursor="pointer"
          >
            <Badge
              borderRadius="100%"
              position="absolute"
              right="0"
              fontSize="0.5rem"
              colorScheme="brandPrimaryTheme"
              color="brand.white"
            >
              {notifications?.unread?.length}
            </Badge>
            <Image src={NotificationsIcon} />{" "}
          </Box>
        </HStack>
      </HStack>
    </>
  );
};

TopNav.propTypes = {
  userName: PropTypes.string,
};

export default TopNav;
