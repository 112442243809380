import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useToast, Box } from "@chakra-ui/react";
import PageLoader from "../../components/PageLoader";
import { handleVerifyEmail } from "./helper";
import queryString from "query-string";
import { useAuth } from "../../util/auth";

const VerifyEmail = (props) => {
    const history = useHistory();
    const toast = useToast();
    const { updateUserData } = useAuth();

    const {
        location: { search },
    } = props;

    useEffect(() => {
        if (!search) {
            return history.replace("/dashboard");
        }
        const parsedQueryString = queryString.parse(search);
        const { token } = parsedQueryString;
        return handleVerifyEmail(token, toast, history, updateUserData);
        // eslint-disable-next-line
    }, [search]);

    return (
        <>
            <Box h="100%">
                <PageLoader />
            </Box>
        </>
    );
};

VerifyEmail.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string,
    }),
};

export default VerifyEmail;
