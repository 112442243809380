import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    // Checkbox,
    // Flex,
    Center,
    HStack,
    Text,
    Spacer,
    Divider,
    Button,
    Spinner,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { usePaystackPayment } from "react-paystack";
import { Context as CardsContext } from "../../contexts/cardsContext";
import CardComponent from "./CardComponent";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../util/auth";
import { initialize, verifyPayment } from "../../util/payment-helper";

const SelectCard = ({ setDrawer, cardIsLoading, data }) => {
    const [selectedCard, setSelectedCard] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
    const router = useHistory();
    const { user } = useAuth();

    const { config, onSuccess, onClose } = initialize({
        setDrawer,
        type: "FUND_CASH_RESERVE",
        data: {
            amountInNaira: data?.amount,
        },
        amountInNaira: data?.amount,
        setIsLoading,
        router,
        user,
    });

    const initializePayment = usePaystackPayment(config);

    const handleCashReserveFunding = async ({ onSuccess, onClose }) => {
        setDrawer({ isOpen: false, modalId: "SELECT_CARD" });
        
        if (selectedCard?.cardId) {
            return verifyPayment({
            data: {
                amountInNaira: data?.amount,
                cardId: selectedCard?.cardId,
            }, 
            type: "FUND_CASH_RESERVE",
            setDrawer, 
            router, 
            setIsLoading });
        } 
        initializePayment(onSuccess, onClose);
    };

    const {
        state: { cards },
        getUserCards,
    } = useContext(CardsContext);

    useEffect(() => {
        sessionStorage.setItem("selectedCard", "{}");
        getUserCards();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Box pb="50">
                <HStack px="5">
                    <Text fontSize="md" align="left">
                        Select Payment Option
                    </Text>
                    <Spacer />
                    {cardIsLoading ? (
                        <Spinner size="sm" color="brand.primary" />
                    ) : (
                        <Text
                            p="1"
                            px="2"
                            border="1px solid"
                            borderColor="brand.primary"
                            shadow="sm"
                            borderRadius="10px"
                            onClick={() =>
                                handleCashReserveFunding({ onSuccess, onClose })
                            }
                            fontSize="xs"
                            color="brand.primary"
                            pt={2}
                            pl={2}
                            size="sm"
                            cursor="pointer"
                        >
                            Use another option
                        </Text>
                    )}
                </HStack>
                <Divider mt={6} mb={6} colorScheme="gray" />
                <Box
                    h={cards && cards.length > 0 ? "50vh" : "10vh"}
                    css={{
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                    overflowY="scroll"
                >
                    {cards && cards.length === 0 ? (
                        <>
                            <Center>
                                <Text>You have not added your card oo.</Text>
                            </Center>
                        </>
                    ) : (
                        <>
                            {cards?.map((card, i) => (
                                <Box w="full" key={card.cardId}>
                                    <CardComponent
                                        setSelectedCard={setSelectedCard}
                                        selectedCard={selectedCard}
                                        card={card}
                                    />
                                    {i === card.length ? null : (
                                        <Divider
                                            mt={6}
                                            mb={6}
                                            colorScheme="gray"
                                        />
                                    )}
                                </Box>
                            ))}
                        </>
                    )}
                </Box>
            </Box>

            <Center
                m="auto"
                py="3"
                bg="brand.white"
                w="100%"
                position="fixed"
                bottom="0"
                mt={12}
                left="0"
            >
                <Button
                    _hover={{
                        background: "brand.primary",
                        shadow: "md",
                    }}
                    shadow="sm"
                    _active={{
                        shadow: "md",
                        transform: "translateY(.5rem)",
                    }}
                    isLoading={isLoading}
                    onClick={() => {
                        if (cards && cards.length > 0) {
                            sessionStorage.setItem(
                                "selectedCard",
                                JSON.stringify(selectedCard)
                            );
                            return setDrawer({
                                isOpen: false,
                            });
                        }

                        setDrawer({
                            isOpen: false,
                            modalId: "SELECT_CARD",
                        });
                        sessionStorage.setItem(
                            "reOpenDrawerTitle",
                            "SELECT_CARD"
                        );
                    }}
                    variant="solid"
                    sizes="lg"
                >
                    {cards && cards.length === 0 ? "Add Card" : "Continue"}
                </Button>
            </Center>
        </>
    );
};

SelectCard.propTypes = {
    cardIsLoading: PropTypes.bool,
    setDrawer: PropTypes.func,
    data: PropTypes.shape({
        amount: PropTypes.number,
    }),
};

export default SelectCard;
