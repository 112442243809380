// import Okra from "okra-js";
import { linkAccountWithMono } from "../../util/operations/account";
import { toastDefaults } from "../../util/constants";

export const handleConnectWithMono = async (
    code,
    toast,
    setOnboardingStep,
    router,
    onboarding,
    setBankIsLoading
) => {
  onboarding && setOnboardingStep("LOADING");
  setBankIsLoading(true);
  const { status, message } = await linkAccountWithMono(code);

    toast({
        ...toastDefaults,
        status: status ? "success" : "error",
        desciption: message,
        title: status ? "Success" : "Error",
    });

    if (status) {
        if (onboarding) {
            return setOnboardingStep(6);
        }
        setBankIsLoading(false);
        await router.history.goBack();
        // return router.replace("/dashboard");
    }
    setBankIsLoading(false);
    return onboarding && setOnboardingStep(4);
};

export const handleSearchBankList = (
    searchText,
    bankList,
    setCurrentBankList
) => {
    if (searchText !== undefined) {
        return setCurrentBankList(
            bankList.filter((bank) =>
                bank.bank_name.toLowerCase().includes(searchText.toLowerCase())
            )
        );
    }

    return setCurrentBankList(bankList);
};
